import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import DropdownInput from 'components/input/dropdown_input';
import TextInput from 'components/input/text_input';
import SimulationGraph from 'components/product/simulation_graph';
import DropdownSheet from 'components/sheet/dropdown_sheet';
import Toast from 'components/toast/toast';
import { arrowBackDark, arrowBackLight, exchangeLight } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { returnExchange } from 'utils/common/functions';

type Params = {
  applyFromSimulation: () => void;
  productCode: string;
  taskId?: string;
  req: Function;
  setToast: Function;
};

const ProductSimulation:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop, push } = useFlow();

  const {USD} = useSelector((state: RootState) => state.exchange);

  const [minForYear, setMinForYear] = useState<number>(4800);
  const [minForMonth, setMinForMonth] = useState<number>(400);
  const getPaymentTypeItem = () => {
    if (minForYear !== 0 && minForMonth !== 0) {
      return [t('연납'), t('월납')];
    } else if (minForYear !== 0 && minForMonth === 0) {
      return [t('연납')]
    } else if (minForYear === 0 && minForMonth !== 0) {
      return [t('월납')]
    }
  };
  const [paymentTypeItem, setPaymentTypeItem] = useState(getPaymentTypeItem());
  const [paymentType, setPaymentType] = useState(0);

  // 프로덕트 내부 값
  const [periodItem, setPeriodItem] = useState([5, 10]);
  // 보여줄 string
  // 선택된 값
  const [period, setPeriod] = useState<number>(0);

  const [payment, setPayment] = useState<string>('');
  const paymentChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPayment(e.target.value);
  };
  const [paymentValid, setPaymentValid] = useState<string>('');

  const [periodDropdown, setPeriodDropdown] = useState(false);
  const [paymentTypeDropdown, setPaymentTypeDropdown] = useState(false);
  const dropdownOpen = (type: string) => {
    if (type === 'period') {
      setPeriodDropdown(true);
    } else if (type === 'paymentType') {
      setPaymentTypeDropdown(true);
    }
  };
  const dropdownClose = () => {
    setPeriodDropdown(false);
    setPaymentTypeDropdown(false);
  };

  const [calcComp, setCalcComp] = useState(false);

  // 계산 결과 고정용 데이터
  const [graphData, setGraphData] = useState<{paymentType: string, period: number, payment: number}>({paymentType: '', period: 0, payment: 0});

  const returnPrincipal = () => {
    let principal;
    if (graphData.paymentType === '연납') {
      principal = graphData.payment * graphData.period;
    } else if (graphData.paymentType === '월납') {
      principal = graphData.payment * graphData.period * 12;
    }
    return principal;
  };

  const product = products.filter((p: any) => p.code === params.productCode)[0];

  const calc = () => {
    const numbPattern = /^\d+$/;
    if (numbPattern.test(payment)) {
      if (paymentTypeItem && paymentTypeItem[paymentType] === '연납') {
        if (parseInt(payment) >= product.minForYear) {
          // setCalcComp(true);
          // setToast('codeSend')
          setPaymentValid('');
          setGraphData({paymentType: paymentTypeItem[paymentType], period: periodItem[period], payment: parseInt(payment)});
        } else {
          setPaymentValid('minError');
          return;
        }
      }
      if (paymentTypeItem && paymentTypeItem[paymentType] === '월납') {
        if (parseInt(payment) >= product.minForMonth) {
          // setCalcComp(true);
          setToast('codeSend')
          setPaymentValid('');
          setGraphData({paymentType: paymentTypeItem[paymentType], period: periodItem[period], payment: parseInt(payment)});
        } else {
          setPaymentValid('minError');
          return;
        }
      }

      if (params.productCode === 'ZURICH_01') {
        push('ZurichSnpInput', {
          onNext: goSimulation,
          productCode: params.productCode,
          paidYear: product.simDurations[period],
          payment: paymentType === 0 ? Number(payment) : Number(payment) * 12,
        });
      } else {
        goSimulation(params.productCode, product.simDurations[period], Number(payment));
      }
    } else {
      setPaymentValid('error');
    }
  };

  const goSimulation = (productCode: string, paidYear: number, payment: number, snpRatio?: number) => {
    push('ProductAISimulationResult', {
      req: params.req,
      setToast: params.setToast,
      taskId: params.taskId,
      productCode, paidYear, payment, snpRatio: snpRatio ?? 0,
    });
  };

  const [toast, setToast] = useState('');

  return (
    <AppScreen>
      <Toast
        theme={type}
        text={t('곧 서비스 재개 예정입니다.')}
        start={toast}
        setStart={setToast}
        toastName='codeSend'
      />
      {
        periodDropdown ? 
        <DropdownSheet
          theme={type}
          items={product.durations.map((v) => `${v}${t('년')}`)}
          onClose={() => dropdownClose()}
          setSelectedItem={setPeriod}
        /> : 
        paymentTypeDropdown ?
        <DropdownSheet
          theme={type}
          items={paymentTypeItem}
          onClose={() => dropdownClose()}
          setSelectedItem={setPaymentType}
        /> : null
      }
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('AI 시뮬레이션')}
          color='bgBG2'
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
          borderBottom={false}
        />
        <div className={`frame ${type} t72 pd20 bgBG2`}>
          <div className={`product-simul-top col-stretch gap36 ${type} ${calcComp ? 'borderBottomC14' : ''}`}>
            <div className='col-stretch gap24'>
              <div className={`product-info-head col-start gap8 bgBG2 ${type} radius16`} style={{border: 'solid 1px #e9e9e9'}}>
                <div className='row-center gap6'>
                  <img src={product.logo} alt='product logo' />
                  <div className='m13'>{product.company}</div>
                </div>
                <div className={`c1 b20 ${type}`}>{product.name}</div>
                <div className='col-start gap12'>
                  <div className={`m13 c1 light`}><span className={`b17 light`}>{t('최소')} {returnExchange(product.minForYear, 'USD')?.toLocaleString()} <span className={`r15 c7 light`}>{'USD'} / year</span></span></div>
                </div>
                <div className='row-center gap8'>
                  <div className={`home-product-item-period c1 m13 bg16 radius4 light`}>{product.durations.map((d: any) => `${d}${t('년')}`).join(' / ')}</div>
                  {
                    (product.allowMonthlyPaid ? [t('월납가능')] : []).map((chip: any, i: number) => (
                      <div key={String(i)} className={`home-product-item-period c1 m13 bg15 radius4 light`}>
                        {chip}
                      </div>
                    ))
                  }
                </div>
              </div>
              <DropdownInput
                theme={type}
                item={product.durations.map((v) => `${v}${t('년')}`)}
                selectedItem={period}
                onClick={() => dropdownOpen('period')}
                placeholder={t('납입 기간 선택...')}
                label={t('납입 기간')}
              />
              {
                !product.allowMonthlyPaid ? null : (
                  <DropdownInput
                    theme={type}
                    item={paymentTypeItem}
                    selectedItem={paymentType}
                    onClick={() => dropdownOpen('paymentType')}
                    placeholder={t('납입 주기 선택...')}
                    label={t('납입 주기')}
                  />
                )
              }
              <TextInput
                theme={type}
                label={t(`${paymentType === 0 ? t('연') : t('월')} ${t('납입금')}`)}
                subLabel={`: ${t('단위 $(USD) / 최소')} ${(paymentType === 0 ? product.minForYear : product.minForMonth).toLocaleString()} USD`}
                inputValue={payment}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => paymentChange(e)}
                placeholder={t('숫자만 입력')}
                validation={paymentValid === 'error' || paymentValid === 'minError' ? false : true}
                validMessage={paymentValid === 'error' ? t('납입금을 확인해 주세요.') : paymentValid === 'minError' ? t('최소금액을 확인해주세요.') : ''}
                inputType='tel'
                icon={false}
              />
              <div className='border light row-center radius8 text-input-wrap bg16'>
                <div className='m15' style={{flex: 1}}>{`${Number(payment).toLocaleString()} USD`}</div>
                <img
                  className='exchange-banner-icon'
                  src={exchangeLight}
                  alt='equal'
                />
                <div className='m15' style={{flex: 1, textAlign: 'end'}}>{`${(Number(payment) * Number(typeof USD === 'string' ? (USD as any).replace(',', '') : USD)).toLocaleString()} KRW`}</div>
              </div>
            </div>
              <BigBtn
                theme={type}
                type='primary'
                text={params.productCode === 'ZURICH_01' ? t('다음') : t('시뮬레이션')}
                onClick={() => calc()}
              />
          </div>
          {
            calcComp ?
            <div className={`product-simul-bottom col-stretch ${type}`}>
              <div id='product-simul-result' className={`product-simul-bottom-title ${type} c1 b17 col-center`}>{t('계산 결과')}</div>
              <div className='col-stretch gap36'>
                <div className='col-stretch gap20'>
                  <SimulationGraph
                    theme={type}
                    items={[
                      {year: 5, refund: 90620},
                      {year: 10, refund: 121431},
                      {year: 15, refund: 162518},
                      {year: 20, refund: 217598},
                      {year: 25, refund: 291438},
                      {year: 30, refund: 390427},
                    ]}
                    graphData={graphData}
                  />
                  {/* <div className='col-stretch gap16 product-simul-bottom-padding'>
                    <div className={`product-simul-box ${type} radius8 borderC14 col-center gap4 `}>
                      <div className={`${type} m15 c1`}>원금 확보 예상 년도 : 10년 차</div>
                      <div className={`${type} m13 c7`}>원금 $ {returnPrincipal()?.toLocaleString()} 기준</div>
                    </div>
                    <div className={`product-simul-box ${type} radius8 borderC14 col-center`}>인출 예상 : 10년 차 부터 최대 $50,000</div>
                  </div> */}
                  <div className={`${type} c7 m13 text-center product-simul-bottom-padding`}>{t('빅데이터에 기반해 인슐린이 예측한 결과입니다. 실제 설계서의 값과는 차이날 수 있으며 본인에 맞는 정확한 값을 알기 위해서는 설계서를 받아보시길 바랍니다.')}</div>
                </div>
                <div className='product-simul-bottom-padding'>
                  <BigBtn
                    theme={type}
                    type='primary'
                    text='설계서 신청'
                    onClick={() => params.applyFromSimulation()}
                  />
              </div>
              </div>
            </div> : null
          }
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductSimulation;