import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import SectionTitle from 'components/common/section_title';
import AccountValueTable from 'components/table/account_value_table';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { returnAmountUnit, returnExchange } from 'utils/common/functions';

type Params = {
  data: {year: string, value: number}[];
  pessiData: {year: string, value: number}[];
  optiData: {year: string, value: number}[];
  baseValue: number;
}

const ProductPlanDetailPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { unit } = useSelector((state:RootState) => state.exchange);
  const { pop } = useFlow();
  const { t } = useTranslation();

  const [switchA, setSwitchA] = useState(true);
  const switchToggleA = () => {
    setSwitchA(!switchA);
  };

  return (
    <AppScreen>
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('연차별 환급금')}
          color='bgBG2'
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame hasAppbar t58 ${type}`}>
          <div className={`col-stertch bgBG2 ${type}`}>
            <SectionTitle
              theme={type}
              title={<><span className='tSLG b15'>{t('인출 시')}</span> {t('예상 환급금')}</>}
              switch={true}
              switchActive={switchA}
              switchToggle={switchToggleA}
            />
            <div className='product-plan-detail-table-wrap col-stretch gap12'>
              <AccountValueTable
                theme={type}
                data={
                  params.data.map((item: {year: string, value: number}) => {
                    return (
                      {
                        year: item.year,
                        value: item.value,
                        more: (<div className='m13'><span className={`b13 tSLG ${type}`}>{Math.round(item.value * 10 / params.baseValue) / 10}</span>{t('배')}</div>)
                      }
                    )
                  })
                }
                type='normal'
                unit={switchA ? 'USD' : 'KRW'}
              />
              <div className={`supplementary-summary borderC14 radius8 m15 flex-center gap4 ${type}`}>
                {t('총 인출 금액')} : <span className='tSLG b15'>{returnAmountUnit(Math.floor(returnExchange(531215, switchA ? 'USD' : 'KRW')), switchA ? 'USD' : 'KRW')} {switchA ? 'USD' : 'KRW'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductPlanDetailPage;