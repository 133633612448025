const irrData = {
  'ZURICH_01': {
    '5y': [],
    '10y': [],
  },
  'AXA_ULTIMATE': {
    '5y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 3, irr: {'B': -0.3558, 'P': -0.3677, 'O': -0.3444}},
      {year: 4, irr: {'B': -0.2506, 'P': -0.2645, 'O': -0.2382}},
      {year: 5, irr: {'B': -0.1762, 'P': -0.1941, 'O': -0.1592}},
      {year: 10, irr: {'B': 0.0287, 'P': 0.0061, 'O': 0.0497}},
      {year: 15, irr: {'B': 0.0433, 'P': 0.0202, 'O': 0.0652}},
      {year: 20, irr: {'B': 0.0529, 'P': 0.0293, 'O': 0.0753}},
      {year: 25, irr: {'B': 0.0562, 'P': 0.0321, 'O': 0.0790}},
      {year: 30, irr: {'B': 0.0590, 'P': 0.0342, 'O': 0.0821}},
      {year: 65, irr: {'B': 0.0660, 'P': 0.0411, 'O': 0.0898}},
      {year: 70, irr: {'B': 0.0666, 'P': 0.0417, 'O': 0.0905}},
      {year: 75, irr: {'B': 0.0673, 'P': 0.0425, 'O': 0.0912}},
      {year: 80, irr: {'B': 0.0680, 'P': 0.0432, 'O': 0.0917}},
      {year: 85, irr: {'B': 0.0686, 'P': 0.0440, 'O': 0.0917}},
      {year: 90, irr: {'B': 0.0689, 'P': 0.0443, 'O': 0.0918}},
      {year: 95, irr: {'B': 0.0691, 'P': 0.0449, 'O': 0.0921}},
      {year: 100, irr: {'B': 0.0693, 'P': 0.0451, 'O': 0.0926}},
    ],
    '10y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 3, irr: {'B': -0.4399, 'P': -0.4510, 'O': -0.4297}},
      {year: 4, irr: {'B': -0.3135, 'P': -0.3283, 'O': -0.3004}},
      {year: 5, irr: {'B': -0.2149, 'P': -0.2307, 'O': -0.2014}},
      {year: 10, irr: {'B': 0.0179, 'P': -0.0041, 'O': 0.0383}},
      {year: 15, irr: {'B': 0.0428, 'P': 0.0202, 'O': 0.0641}},
      {year: 20, irr: {'B': 0.0510, 'P': 0.0277, 'O': 0.0731}},
      {year: 25, irr: {'B': 0.0556, 'P': 0.0318, 'O': 0.0783}},
      {year: 30, irr: {'B': 0.0583, 'P': 0.0331, 'O': 0.0814}},
      {year: 65, irr: {'B': 0.0648, 'P': 0.0396, 'O': 0.0888}},
      {year: 70, irr: {'B': 0.0658, 'P': 0.0405, 'O': 0.0898}},
      {year: 75, irr: {'B': 0.0667, 'P': 0.0415, 'O': 0.0907}},
      {year: 80, irr: {'B': 0.0675, 'P': 0.0424, 'O': 0.0911}},
      {year: 85, irr: {'B': 0.0683, 'P': 0.0432, 'O': 0.0912}},
      {year: 90, irr: {'B': 0.0684, 'P': 0.0435, 'O': 0.0912}},
      {year: 95, irr: {'B': 0.0688, 'P': 0.0443, 'O': 0.0919}},
      {year: 100, irr: {'B': 0.0692, 'P': 0.0449, 'O': 0.0928}},
    ],
  },
  'CHUBB_MYLEGACY': {
    '2y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': -0.9715, 'P': -0.9715, 'O': -0.9715}},
      {year: 3, irr: {'B': -0.5376, 'P': -0.5568, 'O': -0.5196}},
      {year: 4, irr: {'B': -0.2445, 'P': -0.2885, 'O': -0.2063}},
      {year: 5, irr: {'B': -0.0767, 'P': -0.1347, 'O': -0.0286}},
      {year: 6, irr: {'B': -0.0094, 'P': -0.0631, 'O': 0.0359}},
      {year: 7, irr: {'B': 0.0101, 'P': -0.0401, 'O': 0.0528}},
      {year: 8, irr: {'B': 0.0169, 'P': -0.0277, 'O': 0.0558}},
      {year: 9, irr: {'B': 0.0219, 'P': -0.0183, 'O': 0.0578}},
      {year: 10, irr: {'B': 0.0248, 'P': -0.0122, 'O': 0.0585}},
      {year: 11, irr: {'B': 0.0277, 'P': -0.0065, 'O': 0.0593}},
      {year: 12, irr: {'B': 0.0296, 'P': -0.0020, 'O': 0.0594}},
      {year: 13, irr: {'B': 0.0330, 'P': 0.0030, 'O': 0.0617}},
      {year: 14, irr: {'B': 0.0360, 'P': 0.0073, 'O': 0.0637}},
      {year: 15, irr: {'B': 0.0386, 'P': 0.0110, 'O': 0.0654}},
      {year: 16, irr: {'B': 0.0410, 'P': 0.0144, 'O': 0.0670}},
      {year: 17, irr: {'B': 0.0429, 'P': 0.0172, 'O': 0.0682}},
      {year: 18, irr: {'B': 0.0448, 'P': 0.0198, 'O': 0.0695}},
      {year: 19, irr: {'B': 0.0471, 'P': 0.0227, 'O': 0.0714}},
      {year: 20, irr: {'B': 0.0498, 'P': 0.0257, 'O': 0.0738}},
      {year: 21, irr: {'B': 0.0504, 'P': 0.0263, 'O': 0.0743}},
      {year: 22, irr: {'B': 0.0512, 'P': 0.0271, 'O': 0.0750}},
      {year: 23, irr: {'B': 0.0520, 'P': 0.0279, 'O': 0.0757}},
      {year: 24, irr: {'B': 0.0527, 'P': 0.0286, 'O': 0.0763}},
      {year: 25, irr: {'B': 0.0534, 'P': 0.0294, 'O': 0.0769}},
      {year: 26, irr: {'B': 0.0541, 'P': 0.0301, 'O': 0.0775}},
      {year: 27, irr: {'B': 0.0546, 'P': 0.0307, 'O': 0.0778}},
      {year: 28, irr: {'B': 0.0551, 'P': 0.0313, 'O': 0.0781}},
      {year: 29, irr: {'B': 0.0556, 'P': 0.0319, 'O': 0.0785}},
      {year: 30, irr: {'B': 0.0565, 'P': 0.0329, 'O': 0.0793}},
      {year: 31, irr: {'B': 0.0573, 'P': 0.0337, 'O': 0.0800}},
      {year: 32, irr: {'B': 0.0578, 'P': 0.0344, 'O': 0.0803}},
      {year: 33, irr: {'B': 0.0582, 'P': 0.0350, 'O': 0.0806}},
      {year: 34, irr: {'B': 0.0586, 'P': 0.0355, 'O': 0.0808}},
      {year: 35, irr: {'B': 0.0590, 'P': 0.0361, 'O': 0.0811}},
      {year: 36, irr: {'B': 0.0592, 'P': 0.0365, 'O': 0.0811}},
      {year: 37, irr: {'B': 0.0595, 'P': 0.0369, 'O': 0.0812}},
      {year: 38, irr: {'B': 0.0597, 'P': 0.0373, 'O': 0.0813}},
      {year: 39, irr: {'B': 0.0599, 'P': 0.0377, 'O': 0.0814}},
      {year: 40, irr: {'B': 0.0601, 'P': 0.0381, 'O': 0.0814}},
      {year: 41, irr: {'B': 0.0604, 'P': 0.0386, 'O': 0.0816}},
      {year: 42, irr: {'B': 0.0607, 'P': 0.0391, 'O': 0.0818}},
      {year: 43, irr: {'B': 0.0610, 'P': 0.0395, 'O': 0.0819}},
      {year: 44, irr: {'B': 0.0613, 'P': 0.0400, 'O': 0.0821}},
      {year: 45, irr: {'B': 0.0616, 'P': 0.0404, 'O': 0.0823}},
      {year: 46, irr: {'B': 0.0618, 'P': 0.0408, 'O': 0.0824}},
      {year: 47, irr: {'B': 0.0620, 'P': 0.0412, 'O': 0.0825}},
      {year: 48, irr: {'B': 0.0622, 'P': 0.0415, 'O': 0.0826}},
      {year: 49, irr: {'B': 0.0624, 'P': 0.0419, 'O': 0.0827}},
      {year: 50, irr: {'B': 0.0626, 'P': 0.0423, 'O': 0.0828}},
      {year: 51, irr: {'B': 0.0627, 'P': 0.0425, 'O': 0.0827}},
      {year: 52, irr: {'B': 0.0627, 'P': 0.0427, 'O': 0.0827}},
      {year: 53, irr: {'B': 0.0628, 'P': 0.0430, 'O': 0.0827}},
      {year: 54, irr: {'B': 0.0629, 'P': 0.0432, 'O': 0.0827}},
      {year: 55, irr: {'B': 0.0630, 'P': 0.0435, 'O': 0.0827}},
      {year: 56, irr: {'B': 0.0631, 'P': 0.0437, 'O': 0.0827}},
      {year: 57, irr: {'B': 0.0632, 'P': 0.0440, 'O': 0.0827}},
      {year: 58, irr: {'B': 0.0634, 'P': 0.0443, 'O': 0.0828}},
      {year: 59, irr: {'B': 0.0635, 'P': 0.0446, 'O': 0.0828}},
      {year: 60, irr: {'B': 0.0637, 'P': 0.0448, 'O': 0.0829}},
      {year: 61, irr: {'B': 0.0638, 'P': 0.0451, 'O': 0.0829}},
      {year: 62, irr: {'B': 0.0639, 'P': 0.0454, 'O': 0.0830}},
      {year: 63, irr: {'B': 0.0640, 'P': 0.0456, 'O': 0.0830}},
      {year: 64, irr: {'B': 0.0642, 'P': 0.0459, 'O': 0.0831}},
      {year: 65, irr: {'B': 0.0643, 'P': 0.0461, 'O': 0.0831}},
      {year: 66, irr: {'B': 0.0644, 'P': 0.0464, 'O': 0.0832}},
      {year: 67, irr: {'B': 0.0645, 'P': 0.0466, 'O': 0.0832}},
      {year: 68, irr: {'B': 0.0646, 'P': 0.0468, 'O': 0.0833}},
      {year: 69, irr: {'B': 0.0647, 'P': 0.0471, 'O': 0.0833}},
      {year: 70, irr: {'B': 0.0649, 'P': 0.0473, 'O': 0.0834}},
      {year: 71, irr: {'B': 0.0650, 'P': 0.0475, 'O': 0.0834}},
      {year: 72, irr: {'B': 0.0651, 'P': 0.0477, 'O': 0.0835}},
      {year: 73, irr: {'B': 0.0652, 'P': 0.0480, 'O': 0.0836}},
      {year: 74, irr: {'B': 0.0654, 'P': 0.0482, 'O': 0.0836}},
      {year: 75, irr: {'B': 0.0655, 'P': 0.0484, 'O': 0.0837}},
      {year: 76, irr: {'B': 0.0656, 'P': 0.0487, 'O': 0.0838}},
      {year: 77, irr: {'B': 0.0657, 'P': 0.0489, 'O': 0.0839}},
      {year: 78, irr: {'B': 0.0659, 'P': 0.0491, 'O': 0.0839}},
      {year: 79, irr: {'B': 0.0660, 'P': 0.0494, 'O': 0.0840}},
      {year: 80, irr: {'B': 0.0661, 'P': 0.0496, 'O': 0.0841}},
      {year: 81, irr: {'B': 0.0662, 'P': 0.0498, 'O': 0.0841}},
      {year: 82, irr: {'B': 0.0663, 'P': 0.0500, 'O': 0.0842}},
      {year: 83, irr: {'B': 0.0664, 'P': 0.0501, 'O': 0.0842}},
      {year: 84, irr: {'B': 0.0665, 'P': 0.0503, 'O': 0.0843}},
      {year: 85, irr: {'B': 0.0666, 'P': 0.0505, 'O': 0.0843}},
      {year: 86, irr: {'B': 0.0668, 'P': 0.0507, 'O': 0.0844}},
      {year: 87, irr: {'B': 0.0669, 'P': 0.0510, 'O': 0.0845}},
      {year: 88, irr: {'B': 0.0670, 'P': 0.0512, 'O': 0.0846}},
      {year: 89, irr: {'B': 0.0672, 'P': 0.0514, 'O': 0.0847}},
      {year: 90, irr: {'B': 0.0673, 'P': 0.0516, 'O': 0.0848}},
      {year: 91, irr: {'B': 0.0676, 'P': 0.0520, 'O': 0.0851}},
      {year: 92, irr: {'B': 0.0678, 'P': 0.0523, 'O': 0.0853}},
      {year: 93, irr: {'B': 0.0681, 'P': 0.0526, 'O': 0.0854}},
      {year: 94, irr: {'B': 0.0683, 'P': 0.0529, 'O': 0.0856}},
      {year: 95, irr: {'B': 0.0685, 'P': 0.0531, 'O': 0.0858}},
      {year: 96, irr: {'B': 0.0686, 'P': 0.0534, 'O': 0.0859}},
      {year: 97, irr: {'B': 0.0688, 'P': 0.0536, 'O': 0.0861}},
      {year: 98, irr: {'B': 0.0690, 'P': 0.0538, 'O': 0.0862}},
      {year: 99, irr: {'B': 0.0691, 'P': 0.0541, 'O': 0.0863}},
      {year: 100, irr: {'B': 0.0693, 'P': 0.0543, 'O': 0.0864}},
      {year: 101, irr: {'B': 0.0693, 'P': 0.0543, 'O': 0.0864}},
      {year: 102, irr: {'B': 0.0692, 'P': 0.0544, 'O': 0.0863}},
      {year: 103, irr: {'B': 0.0692, 'P': 0.0544, 'O': 0.0863}},
      {year: 104, irr: {'B': 0.0692, 'P': 0.0545, 'O': 0.0862}},
      {year: 105, irr: {'B': 0.0694, 'P': 0.0547, 'O': 0.0864}},
      {year: 106, irr: {'B': 0.0694, 'P': 0.0548, 'O': 0.0864}},
      {year: 107, irr: {'B': 0.0695, 'P': 0.0549, 'O': 0.0864}},
      {year: 108, irr: {'B': 0.0695, 'P': 0.0550, 'O': 0.0864}},
      {year: 109, irr: {'B': 0.0696, 'P': 0.0551, 'O': 0.0864}},
      {year: 110, irr: {'B': 0.0696, 'P': 0.0552, 'O': 0.0864}},
      {year: 111, irr: {'B': 0.0697, 'P': 0.0553, 'O': 0.0865}},
      {year: 112, irr: {'B': 0.0697, 'P': 0.0554, 'O': 0.0865}},
      {year: 113, irr: {'B': 0.0698, 'P': 0.0555, 'O': 0.0865}},
      {year: 114, irr: {'B': 0.0698, 'P': 0.0556, 'O': 0.0865}},
      {year: 115, irr: {'B': 0.0699, 'P': 0.0557, 'O': 0.0866}},
      {year: 116, irr: {'B': 0.0699, 'P': 0.0558, 'O': 0.0866}},
      {year: 117, irr: {'B': 0.0700, 'P': 0.0559, 'O': 0.0866}},
      {year: 118, irr: {'B': 0.0700, 'P': 0.0561, 'O': 0.0866}},
      {year: 119, irr: {'B': 0.0701, 'P': 0.0562, 'O': 0.0867}},
      {year: 120, irr: {'B': 0.0701, 'P': 0.0563, 'O': 0.0867}},
    ],
    '5y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 3, irr: {'B': -0.7754, 'P': -0.7816, 'O': -0.7656}},
      {year: 4, irr: {'B': -0.5826, 'P': -0.5957, 'O': -0.5624}},
      {year: 5, irr: {'B': -0.4222, 'P': -0.4389, 'O': -0.3966}},
      {year: 6, irr: {'B': -0.0946, 'P': -0.1697, 'O': -0.0328}},
      {year: 7, irr: {'B': -0.0357, 'P': -0.0971, 'O': 0.0166}},
      {year: 8, irr: {'B': -0.0005, 'P': -0.0526, 'O': 0.0449}},
      {year: 9, irr: {'B': 0.0153, 'P': -0.0278, 'O': 0.0544}},
      {year: 10, irr: {'B': 0.0265, 'P': -0.0102, 'O': 0.0608}},
      {year: 11, irr: {'B': 0.0290, 'P': -0.0044, 'O': 0.0609}},
      {year: 12, irr: {'B': 0.0305, 'P': -0.0003, 'O': 0.0604}},
      {year: 13, irr: {'B': 0.0319, 'P': 0.0033, 'O': 0.0602}},
      {year: 14, irr: {'B': 0.0355, 'P': 0.0079, 'O': 0.0629}},
      {year: 15, irr: {'B': 0.0386, 'P': 0.0120, 'O': 0.0652}},
      {year: 16, irr: {'B': 0.0415, 'P': 0.0156, 'O': 0.0674}},
      {year: 17, irr: {'B': 0.0438, 'P': 0.0187, 'O': 0.0690}},
      {year: 18, irr: {'B': 0.0459, 'P': 0.0214, 'O': 0.0705}},
      {year: 19, irr: {'B': 0.0485, 'P': 0.0244, 'O': 0.0728}},
      {year: 20, irr: {'B': 0.0515, 'P': 0.0275, 'O': 0.0755}},
      {year: 21, irr: {'B': 0.0521, 'P': 0.0280, 'O': 0.0759}},
      {year: 22, irr: {'B': 0.0528, 'P': 0.0287, 'O': 0.0765}},
      {year: 23, irr: {'B': 0.0535, 'P': 0.0295, 'O': 0.0771}},
      {year: 24, irr: {'B': 0.0542, 'P': 0.0301, 'O': 0.0777}},
      {year: 25, irr: {'B': 0.0548, 'P': 0.0308, 'O': 0.0782}},
      {year: 26, irr: {'B': 0.0555, 'P': 0.0315, 'O': 0.0788}},
      {year: 27, irr: {'B': 0.0559, 'P': 0.0321, 'O': 0.0790}},
      {year: 28, irr: {'B': 0.0563, 'P': 0.0326, 'O': 0.0793}},
      {year: 29, irr: {'B': 0.0568, 'P': 0.0332, 'O': 0.0796}},
      {year: 30, irr: {'B': 0.0577, 'P': 0.0341, 'O': 0.0804}},
      {year: 31, irr: {'B': 0.0586, 'P': 0.0350, 'O': 0.0811}},
      {year: 32, irr: {'B': 0.0590, 'P': 0.0356, 'O': 0.0814}},
      {year: 33, irr: {'B': 0.0595, 'P': 0.0362, 'O': 0.0817}},
      {year: 34, irr: {'B': 0.0599, 'P': 0.0368, 'O': 0.0819}},
      {year: 35, irr: {'B': 0.0602, 'P': 0.0373, 'O': 0.0821}},
      {year: 36, irr: {'B': 0.0605, 'P': 0.0377, 'O': 0.0822}},
      {year: 37, irr: {'B': 0.0607, 'P': 0.0381, 'O': 0.0822}},
      {year: 38, irr: {'B': 0.0609, 'P': 0.0385, 'O': 0.0823}},
      {year: 39, irr: {'B': 0.0611, 'P': 0.0389, 'O': 0.0824}},
      {year: 40, irr: {'B': 0.0613, 'P': 0.0393, 'O': 0.0824}},
      {year: 41, irr: {'B': 0.0616, 'P': 0.0397, 'O': 0.0825}},
      {year: 42, irr: {'B': 0.0618, 'P': 0.0402, 'O': 0.0827}},
      {year: 43, irr: {'B': 0.0621, 'P': 0.0406, 'O': 0.0828}},
      {year: 44, irr: {'B': 0.0623, 'P': 0.0410, 'O': 0.0829}},
      {year: 45, irr: {'B': 0.0626, 'P': 0.0415, 'O': 0.0831}},
      {year: 46, irr: {'B': 0.0628, 'P': 0.0418, 'O': 0.0831}},
      {year: 47, irr: {'B': 0.0630, 'P': 0.0422, 'O': 0.0832}},
      {year: 48, irr: {'B': 0.0632, 'P': 0.0425, 'O': 0.0833}},
      {year: 49, irr: {'B': 0.0633, 'P': 0.0429, 'O': 0.0833}},
      {year: 50, irr: {'B': 0.0635, 'P': 0.0432, 'O': 0.0834}},
      {year: 51, irr: {'B': 0.0636, 'P': 0.0434, 'O': 0.0833}},
      {year: 52, irr: {'B': 0.0636, 'P': 0.0436, 'O': 0.0833}},
      {year: 53, irr: {'B': 0.0637, 'P': 0.0439, 'O': 0.0832}},
      {year: 54, irr: {'B': 0.0637, 'P': 0.0441, 'O': 0.0832}},
      {year: 55, irr: {'B': 0.0638, 'P': 0.0443, 'O': 0.0832}},
      {year: 56, irr: {'B': 0.0639, 'P': 0.0446, 'O': 0.0832}},
      {year: 57, irr: {'B': 0.0640, 'P': 0.0448, 'O': 0.0832}},
      {year: 58, irr: {'B': 0.0642, 'P': 0.0451, 'O': 0.0832}},
      {year: 59, irr: {'B': 0.0643, 'P': 0.0454, 'O': 0.0833}},
      {year: 60, irr: {'B': 0.0644, 'P': 0.0456, 'O': 0.0833}},
      {year: 61, irr: {'B': 0.0645, 'P': 0.0459, 'O': 0.0833}},
      {year: 62, irr: {'B': 0.0646, 'P': 0.0461, 'O': 0.0834}},
      {year: 63, irr: {'B': 0.0648, 'P': 0.0464, 'O': 0.0834}},
      {year: 64, irr: {'B': 0.0649, 'P': 0.0466, 'O': 0.0834}},
      {year: 65, irr: {'B': 0.0650, 'P': 0.0468, 'O': 0.0835}},
      {year: 66, irr: {'B': 0.0651, 'P': 0.0471, 'O': 0.0835}},
      {year: 67, irr: {'B': 0.0652, 'P': 0.0473, 'O': 0.0835}},
      {year: 68, irr: {'B': 0.0653, 'P': 0.0475, 'O': 0.0836}},
      {year: 69, irr: {'B': 0.0654, 'P': 0.0477, 'O': 0.0836}},
      {year: 70, irr: {'B': 0.0655, 'P': 0.0480, 'O': 0.0836}},
      {year: 71, irr: {'B': 0.0656, 'P': 0.0482, 'O': 0.0837}},
      {year: 72, irr: {'B': 0.0657, 'P': 0.0484, 'O': 0.0837}},
      {year: 73, irr: {'B': 0.0658, 'P': 0.0486, 'O': 0.0838}},
      {year: 74, irr: {'B': 0.0659, 'P': 0.0488, 'O': 0.0838}},
      {year: 75, irr: {'B': 0.0661, 'P': 0.0491, 'O': 0.0839}},
      {year: 76, irr: {'B': 0.0662, 'P': 0.0493, 'O': 0.0840}},
      {year: 77, irr: {'B': 0.0663, 'P': 0.0495, 'O': 0.0840}},
      {year: 78, irr: {'B': 0.0664, 'P': 0.0497, 'O': 0.0841}},
      {year: 79, irr: {'B': 0.0666, 'P': 0.0500, 'O': 0.0842}},
      {year: 80, irr: {'B': 0.0667, 'P': 0.0502, 'O': 0.0842}},
      {year: 81, irr: {'B': 0.0666, 'P': 0.0502, 'O': 0.0841}},
      {year: 82, irr: {'B': 0.0667, 'P': 0.0504, 'O': 0.0842}},
      {year: 83, irr: {'B': 0.0668, 'P': 0.0506, 'O': 0.0842}},
      {year: 84, irr: {'B': 0.0669, 'P': 0.0508, 'O': 0.0843}},
      {year: 85, irr: {'B': 0.0670, 'P': 0.0509, 'O': 0.0843}},
      {year: 86, irr: {'B': 0.0671, 'P': 0.0512, 'O': 0.0844}},
      {year: 87, irr: {'B': 0.0673, 'P': 0.0514, 'O': 0.0845}},
      {year: 88, irr: {'B': 0.0674, 'P': 0.0516, 'O': 0.0846}},
      {year: 89, irr: {'B': 0.0676, 'P': 0.0518, 'O': 0.0847}},
      {year: 90, irr: {'B': 0.0677, 'P': 0.0520, 'O': 0.0848}},
      {year: 91, irr: {'B': 0.0679, 'P': 0.0524, 'O': 0.0850}},
      {year: 92, irr: {'B': 0.0682, 'P': 0.0527, 'O': 0.0852}},
      {year: 93, irr: {'B': 0.0684, 'P': 0.0530, 'O': 0.0854}},
      {year: 94, irr: {'B': 0.0686, 'P': 0.0532, 'O': 0.0855}},
      {year: 95, irr: {'B': 0.0688, 'P': 0.0535, 'O': 0.0857}},
      {year: 96, irr: {'B': 0.0690, 'P': 0.0538, 'O': 0.0858}},
      {year: 97, irr: {'B': 0.0691, 'P': 0.0540, 'O': 0.0859}},
      {year: 98, irr: {'B': 0.0693, 'P': 0.0542, 'O': 0.0861}},
      {year: 99, irr: {'B': 0.0694, 'P': 0.0544, 'O': 0.0862}},
      {year: 100, irr: {'B': 0.0696, 'P': 0.0546, 'O': 0.0863}},
      {year: 101, irr: {'B': 0.0696, 'P': 0.0547, 'O': 0.0862}},
      {year: 102, irr: {'B': 0.0695, 'P': 0.0547, 'O': 0.0862}},
      {year: 103, irr: {'B': 0.0695, 'P': 0.0548, 'O': 0.0861}},
      {year: 104, irr: {'B': 0.0695, 'P': 0.0548, 'O': 0.0861}},
      {year: 105, irr: {'B': 0.0697, 'P': 0.0550, 'O': 0.0862}},
      {year: 106, irr: {'B': 0.0697, 'P': 0.0551, 'O': 0.0862}},
      {year: 107, irr: {'B': 0.0698, 'P': 0.0552, 'O': 0.0862}},
      {year: 108, irr: {'B': 0.0698, 'P': 0.0553, 'O': 0.0862}},
      {year: 109, irr: {'B': 0.0698, 'P': 0.0554, 'O': 0.0862}},
      {year: 110, irr: {'B': 0.0699, 'P': 0.0555, 'O': 0.0862}},
      {year: 111, irr: {'B': 0.0699, 'P': 0.0556, 'O': 0.0863}},
      {year: 112, irr: {'B': 0.0700, 'P': 0.0557, 'O': 0.0863}},
      {year: 113, irr: {'B': 0.0700, 'P': 0.0558, 'O': 0.0863}},
      {year: 114, irr: {'B': 0.0701, 'P': 0.0559, 'O': 0.0863}},
      {year: 115, irr: {'B': 0.0701, 'P': 0.0560, 'O': 0.0863}},
      {year: 116, irr: {'B': 0.0702, 'P': 0.0561, 'O': 0.0864}},
      {year: 117, irr: {'B': 0.0702, 'P': 0.0562, 'O': 0.0864}},
      {year: 118, irr: {'B': 0.0703, 'P': 0.0563, 'O': 0.0864}},
      {year: 119, irr: {'B': 0.0703, 'P': 0.0564, 'O': 0.0864}},
      {year: 120, irr: {'B': 0.0704, 'P': 0.0565, 'O': 0.0865}},
    ],
    '8y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 3, irr: {'B': -0.7754, 'P': -0.7816, 'O': -0.7656}},
      {year: 4, irr: {'B': -0.5826, 'P': -0.5957, 'O': -0.5624}},
      {year: 5, irr: {'B': -0.4222, 'P': -0.4389, 'O': -0.3966}},
      {year: 6, irr: {'B': -0.0946, 'P': -0.1697, 'O': -0.0328}},
      {year: 7, irr: {'B': -0.0357, 'P': -0.0971, 'O': 0.0166}},
      {year: 8, irr: {'B': -0.0005, 'P': -0.0526, 'O': 0.0449}},
      {year: 9, irr: {'B': 0.0153, 'P': -0.0278, 'O': 0.0544}},
      {year: 10, irr: {'B': 0.0265, 'P': -0.0102, 'O': 0.0608}},
      {year: 11, irr: {'B': 0.0290, 'P': -0.0044, 'O': 0.0609}},
      {year: 12, irr: {'B': 0.0305, 'P': -0.0003, 'O': 0.0604}},
      {year: 13, irr: {'B': 0.0319, 'P': 0.0033, 'O': 0.0602}},
      {year: 14, irr: {'B': 0.0355, 'P': 0.0079, 'O': 0.0629}},
      {year: 15, irr: {'B': 0.0386, 'P': 0.0120, 'O': 0.0652}},
      {year: 16, irr: {'B': 0.0415, 'P': 0.0156, 'O': 0.0674}},
      {year: 17, irr: {'B': 0.0438, 'P': 0.0187, 'O': 0.0690}},
      {year: 18, irr: {'B': 0.0459, 'P': 0.0214, 'O': 0.0705}},
      {year: 19, irr: {'B': 0.0485, 'P': 0.0244, 'O': 0.0728}},
      {year: 20, irr: {'B': 0.0515, 'P': 0.0275, 'O': 0.0755}},
      {year: 21, irr: {'B': 0.0521, 'P': 0.0280, 'O': 0.0759}},
      {year: 22, irr: {'B': 0.0528, 'P': 0.0287, 'O': 0.0765}},
      {year: 23, irr: {'B': 0.0535, 'P': 0.0295, 'O': 0.0771}},
      {year: 24, irr: {'B': 0.0542, 'P': 0.0301, 'O': 0.0777}},
      {year: 25, irr: {'B': 0.0548, 'P': 0.0308, 'O': 0.0782}},
      {year: 26, irr: {'B': 0.0555, 'P': 0.0315, 'O': 0.0788}},
      {year: 27, irr: {'B': 0.0559, 'P': 0.0321, 'O': 0.0790}},
      {year: 28, irr: {'B': 0.0563, 'P': 0.0326, 'O': 0.0793}},
      {year: 29, irr: {'B': 0.0568, 'P': 0.0332, 'O': 0.0796}},
      {year: 30, irr: {'B': 0.0577, 'P': 0.0341, 'O': 0.0804}},
      {year: 31, irr: {'B': 0.0586, 'P': 0.0350, 'O': 0.0811}},
      {year: 32, irr: {'B': 0.0590, 'P': 0.0356, 'O': 0.0814}},
      {year: 33, irr: {'B': 0.0595, 'P': 0.0362, 'O': 0.0817}},
      {year: 34, irr: {'B': 0.0599, 'P': 0.0368, 'O': 0.0819}},
      {year: 35, irr: {'B': 0.0602, 'P': 0.0373, 'O': 0.0821}},
      {year: 36, irr: {'B': 0.0605, 'P': 0.0377, 'O': 0.0822}},
      {year: 37, irr: {'B': 0.0607, 'P': 0.0381, 'O': 0.0822}},
      {year: 38, irr: {'B': 0.0609, 'P': 0.0385, 'O': 0.0823}},
      {year: 39, irr: {'B': 0.0611, 'P': 0.0389, 'O': 0.0824}},
      {year: 40, irr: {'B': 0.0613, 'P': 0.0393, 'O': 0.0824}},
      {year: 41, irr: {'B': 0.0616, 'P': 0.0397, 'O': 0.0825}},
      {year: 42, irr: {'B': 0.0618, 'P': 0.0402, 'O': 0.0827}},
      {year: 43, irr: {'B': 0.0621, 'P': 0.0406, 'O': 0.0828}},
      {year: 44, irr: {'B': 0.0623, 'P': 0.0410, 'O': 0.0829}},
      {year: 45, irr: {'B': 0.0626, 'P': 0.0415, 'O': 0.0831}},
      {year: 46, irr: {'B': 0.0628, 'P': 0.0418, 'O': 0.0831}},
      {year: 47, irr: {'B': 0.0630, 'P': 0.0422, 'O': 0.0832}},
      {year: 48, irr: {'B': 0.0632, 'P': 0.0425, 'O': 0.0833}},
      {year: 49, irr: {'B': 0.0633, 'P': 0.0429, 'O': 0.0833}},
      {year: 50, irr: {'B': 0.0635, 'P': 0.0432, 'O': 0.0834}},
      {year: 51, irr: {'B': 0.0636, 'P': 0.0434, 'O': 0.0833}},
      {year: 52, irr: {'B': 0.0636, 'P': 0.0436, 'O': 0.0833}},
      {year: 53, irr: {'B': 0.0637, 'P': 0.0439, 'O': 0.0832}},
      {year: 54, irr: {'B': 0.0637, 'P': 0.0441, 'O': 0.0832}},
      {year: 55, irr: {'B': 0.0638, 'P': 0.0443, 'O': 0.0832}},
      {year: 56, irr: {'B': 0.0639, 'P': 0.0446, 'O': 0.0832}},
      {year: 57, irr: {'B': 0.0640, 'P': 0.0448, 'O': 0.0832}},
      {year: 58, irr: {'B': 0.0642, 'P': 0.0451, 'O': 0.0832}},
      {year: 59, irr: {'B': 0.0643, 'P': 0.0454, 'O': 0.0833}},
      {year: 60, irr: {'B': 0.0644, 'P': 0.0456, 'O': 0.0833}},
      {year: 61, irr: {'B': 0.0645, 'P': 0.0459, 'O': 0.0833}},
      {year: 62, irr: {'B': 0.0646, 'P': 0.0461, 'O': 0.0834}},
      {year: 63, irr: {'B': 0.0648, 'P': 0.0464, 'O': 0.0834}},
      {year: 64, irr: {'B': 0.0649, 'P': 0.0466, 'O': 0.0834}},
      {year: 65, irr: {'B': 0.0650, 'P': 0.0468, 'O': 0.0835}},
      {year: 66, irr: {'B': 0.0651, 'P': 0.0471, 'O': 0.0835}},
      {year: 67, irr: {'B': 0.0652, 'P': 0.0473, 'O': 0.0835}},
      {year: 68, irr: {'B': 0.0653, 'P': 0.0475, 'O': 0.0836}},
      {year: 69, irr: {'B': 0.0654, 'P': 0.0477, 'O': 0.0836}},
      {year: 70, irr: {'B': 0.0655, 'P': 0.0480, 'O': 0.0836}},
      {year: 71, irr: {'B': 0.0656, 'P': 0.0482, 'O': 0.0837}},
      {year: 72, irr: {'B': 0.0657, 'P': 0.0484, 'O': 0.0837}},
      {year: 73, irr: {'B': 0.0658, 'P': 0.0486, 'O': 0.0838}},
      {year: 74, irr: {'B': 0.0659, 'P': 0.0488, 'O': 0.0838}},
      {year: 75, irr: {'B': 0.0661, 'P': 0.0491, 'O': 0.0839}},
      {year: 76, irr: {'B': 0.0662, 'P': 0.0493, 'O': 0.0840}},
      {year: 77, irr: {'B': 0.0663, 'P': 0.0495, 'O': 0.0840}},
      {year: 78, irr: {'B': 0.0664, 'P': 0.0497, 'O': 0.0841}},
      {year: 79, irr: {'B': 0.0666, 'P': 0.0500, 'O': 0.0842}},
      {year: 80, irr: {'B': 0.0667, 'P': 0.0502, 'O': 0.0842}},
      {year: 81, irr: {'B': 0.0666, 'P': 0.0502, 'O': 0.0841}},
      {year: 82, irr: {'B': 0.0667, 'P': 0.0504, 'O': 0.0842}},
      {year: 83, irr: {'B': 0.0668, 'P': 0.0506, 'O': 0.0842}},
      {year: 84, irr: {'B': 0.0669, 'P': 0.0508, 'O': 0.0843}},
      {year: 85, irr: {'B': 0.0670, 'P': 0.0509, 'O': 0.0843}},
      {year: 86, irr: {'B': 0.0671, 'P': 0.0512, 'O': 0.0844}},
      {year: 87, irr: {'B': 0.0673, 'P': 0.0514, 'O': 0.0845}},
      {year: 88, irr: {'B': 0.0674, 'P': 0.0516, 'O': 0.0846}},
      {year: 89, irr: {'B': 0.0676, 'P': 0.0518, 'O': 0.0847}},
      {year: 90, irr: {'B': 0.0677, 'P': 0.0520, 'O': 0.0848}},
      {year: 91, irr: {'B': 0.0679, 'P': 0.0524, 'O': 0.0850}},
      {year: 92, irr: {'B': 0.0682, 'P': 0.0527, 'O': 0.0852}},
      {year: 93, irr: {'B': 0.0684, 'P': 0.0530, 'O': 0.0854}},
      {year: 94, irr: {'B': 0.0686, 'P': 0.0532, 'O': 0.0855}},
      {year: 95, irr: {'B': 0.0688, 'P': 0.0535, 'O': 0.0857}},
      {year: 96, irr: {'B': 0.0690, 'P': 0.0538, 'O': 0.0858}},
      {year: 97, irr: {'B': 0.0691, 'P': 0.0540, 'O': 0.0859}},
      {year: 98, irr: {'B': 0.0693, 'P': 0.0542, 'O': 0.0861}},
      {year: 99, irr: {'B': 0.0694, 'P': 0.0544, 'O': 0.0862}},
      {year: 100, irr: {'B': 0.0696, 'P': 0.0546, 'O': 0.0863}},
      {year: 101, irr: {'B': 0.0696, 'P': 0.0547, 'O': 0.0862}},
      {year: 102, irr: {'B': 0.0695, 'P': 0.0547, 'O': 0.0862}},
      {year: 103, irr: {'B': 0.0695, 'P': 0.0548, 'O': 0.0861}},
      {year: 104, irr: {'B': 0.0695, 'P': 0.0548, 'O': 0.0861}},
      {year: 105, irr: {'B': 0.0697, 'P': 0.0550, 'O': 0.0862}},
      {year: 106, irr: {'B': 0.0697, 'P': 0.0551, 'O': 0.0862}},
      {year: 107, irr: {'B': 0.0698, 'P': 0.0552, 'O': 0.0862}},
      {year: 108, irr: {'B': 0.0698, 'P': 0.0553, 'O': 0.0862}},
      {year: 109, irr: {'B': 0.0698, 'P': 0.0554, 'O': 0.0862}},
      {year: 110, irr: {'B': 0.0699, 'P': 0.0555, 'O': 0.0862}},
      {year: 111, irr: {'B': 0.0699, 'P': 0.0556, 'O': 0.0863}},
      {year: 112, irr: {'B': 0.0700, 'P': 0.0557, 'O': 0.0863}},
      {year: 113, irr: {'B': 0.0700, 'P': 0.0558, 'O': 0.0863}},
      {year: 114, irr: {'B': 0.0701, 'P': 0.0559, 'O': 0.0863}},
      {year: 115, irr: {'B': 0.0701, 'P': 0.0560, 'O': 0.0863}},
      {year: 116, irr: {'B': 0.0702, 'P': 0.0561, 'O': 0.0864}},
      {year: 117, irr: {'B': 0.0702, 'P': 0.0562, 'O': 0.0864}},
      {year: 118, irr: {'B': 0.0703, 'P': 0.0563, 'O': 0.0864}},
      {year: 119, irr: {'B': 0.0703, 'P': 0.0564, 'O': 0.0864}},
      {year: 120, irr: {'B': 0.0704, 'P': 0.0565, 'O': 0.0865}},
    ],
  },
  'AXA_ULTRA': {
    '8y': [
      {year: 1, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 2, irr: {'B': 0, 'P': 0, 'O': 0}},
      {year: 3, irr: {'B': -0.7738, 'P': -0.7865, 'O': -0.7650}},
      {year: 4, irr: {'B': -0.5423, 'P': -0.5613, 'O': -0.5242}},
      {year: 5, irr: {'B': -0.2947, 'P': -0.3177, 'O': -0.2737}},
      {year: 10, irr: {'B': 0.0140, 'P': -0.0109, 'O': 0.0378}},
      {year: 15, irr: {'B': 0.0398, 'P': 0.0158, 'O': 0.0637}},
      {year: 20, irr: {'B': 0.0514, 'P': 0.0270, 'O': 0.0754}},
      {year: 25, irr: {'B': 0.0569, 'P': 0.0324, 'O': 0.0808}},
      {year: 30, irr: {'B': 0.0599, 'P': 0.0353, 'O': 0.0839}},
      {year: 35, irr: {'B': 0.0599, 'P': 0.0353, 'O': 0.0839}},
      {year: 40, irr: {'B': 0.0599, 'P': 0.0353, 'O': 0.0839}},
      {year: 45, irr: {'B': 0.0616, 'P': 0.0365, 'O': 0.0856}},
      {year: 50, irr: {'B': 0.0631, 'P': 0.0378, 'O': 0.0871}},
      {year: 55, irr: {'B': 0.0643, 'P': 0.0389, 'O': 0.0883}},
      {year: 60, irr: {'B': 0.0653, 'P': 0.0398, 'O': 0.0893}},
      {year: 65, irr: {'B': 0.0662, 'P': 0.0406, 'O': 0.0902}},
      {year: 70, irr: {'B': 0.0670, 'P': 0.0413, 'O': 0.0910}},
      {year: 75, irr: {'B': 0.0678, 'P': 0.0419, 'O': 0.0917}},
      {year: 80, irr: {'B': 0.0684, 'P': 0.0422, 'O': 0.0924}},
      {year: 85, irr: {'B': 0.0690, 'P': 0.0422, 'O': 0.0925}},
      {year: 90, irr: {'B': 0.0695, 'P': 0.0422, 'O': 0.0925}},
      {year: 93, irr: {'B': 0.0698, 'P': 0.0422, 'O': 0.0925}},
    ],
  },
};

export default irrData;
