import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import TextInput from 'components/input/text_input';
import SNPTable from 'components/table/snp_table';
import { arrowBackLight } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import { t } from 'i18next';
import { useState } from 'react';
import { returnExchange } from 'utils/common/functions';

type Params = {
  onNext: any;
  productCode: string;
  paidYear: number;
  payment: number;
};

const ZurichSnpInput:ActivityComponentType<Params> = ({params}) => {
  const {pop} = useFlow();

  const [interest, setInterest] = useState<any>('11.95');

  const product = products.filter((p: any) => p.code === 'ZURICH_01')[0];

  // const [zurichSFData, setZurichSFData] = useState([]);
  // const [switchB, setSwitchB] = useState(true);
  // const switchToggleB = () => {
  //   setSwitchB(!switchB);
  // };
  

  // useEffect(() => {
  //   const arr: any = [];
  //   const totalValue = params.payment * params.paidYear;

  //   // const snpRatio = (1 + (params.snpRatio >= 11.7 ? 11.7 : params.snpRatio > 0 ? params.snpRatio : 0) / 100);
  //   const snpRatio = 1;

  //   zurichSFFeeData.forEach((feeRatio, i) => {
  //     if (i === 0) {
  //       arr.push({year: (i + 1).toString(), value: (params.payment * 1.08 - (totalValue * feeRatio))});
  //     } else if (i < 5) {
  //       arr.push({year: (i + 1).toString(), value: (arr[i - 1].value * snpRatio) + params.payment - (totalValue * feeRatio)});
  //     } else if (i < 10) {
  //       const av = (arr[i - 1].value * snpRatio) - (totalValue * feeRatio);

  //       arr.push({year: (i + 1).toString(), value: av, 
  //         more: (
  //           <div className='col-center'>
  //             <div className='m13 c2 light'>
  //               원금
  //               <span className='b13' style={{marginLeft: '2px', color: '#00B55A'}}>{
  //                 (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) < 1000 ?
  //                   (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) :
  //                   Math.round((Math.round((av / (params.payment * params.paidYear)) * 10) / 10)).toLocaleString()
  //               }</span>
  //               배
  //             </div>
  //           </div>
  //         )
  //       });
  //     } else {
  //       const av = arr[i - 1].value * (snpRatio - feeRatio);

  //       arr.push({year: (i + 1).toString(), value: av, 
  //         more: (
  //           <div className='col-center'>
  //             <div className='m13 c2 light'>
  //               원금
  //               <span className='b13' style={{marginLeft: '2px', color: '#00B55A'}}>{
  //                 (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) < 1000 ?
  //                   (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) :
  //                   Math.round((Math.round((av / (params.payment * params.paidYear)) * 10) / 10)).toLocaleString()
  //               }</span>
  //               배
  //             </div>
  //           </div>
  //         )
  //       });
  //     }
  //   });

  //   setZurichSFData(arr);
  // }, []);

  return (
    <AppScreen>
      <div className='frame-cover'>
        <Appbar
          theme='light'
          type='text'
          title={t('AI 시뮬레이션')}
          left={{
            icon: arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className='frame light t72 pd20 bgBG2'>
          <div className='product-simul-top'>
            <div className='col-stretch gap24'>
            <div className={`product-info-head col-start gap8 bgBG2 light radius16`} style={{border: 'solid 1px #e9e9e9'}}>
                <div className='row-center gap6'>
                  <img src={product.logo} alt='product logo' />
                  <div className='m13'>{product.company}</div>
                </div>
                <div className={`c1 b20 light`}>{product.name}</div>
                <div className='col-start gap12'>
                  <div className={`m13 c1 light`}><span className={`b17 light`}>{returnExchange(params.payment, 'USD')?.toLocaleString()} <span className={`r15 c7 light`}>{'USD'} / year</span></span></div>
                </div>
                <div className='row-center gap8'>
                  <div className={`home-product-item-period c1 m13 bg16 radius4 light`}>{params.paidYear + '년 납입'}</div>
                </div>
              </div>
              {/* <div className='b17'>1995 ~ 2024년 실제 시뮬레이션</div>
              <AccountValueTable
                  theme={'light'} data={
                    zurichSFData.filter((v: any) => v.year % 5 === 0)
                  }
                type={'normal'} unit={switchB ? 'USD' : 'KRW'} /> */}
              <SNPTable />
              <TextInput
                theme='light'
                label={t('예상 S&P500 평균 수익률')} // 차영광: 예상으로 바꿔주세요
                subLabel={': ' + t('단위 %')}
                inputValue={interest}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setInterest(e.target.value)}
                placeholder={t('숫자만 입력')}
                validation
                validMessage=''
                inputType='number'
                icon={false}
              />
              <BigBtn
                theme='light'
                type='primary'
                text={t('시뮬레이션')}
                onClick={() => {
                  const reg = /^[0-9]+(.[0-9]+)?$/;
                  if (reg.test(interest)) {
                    params.onNext(params.productCode, params.paidYear, params.payment, Number(interest));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ZurichSnpInput;
