import { AppScreen } from '@stackflow/plugin-basic-ui';
import React from 'react';
import Appbar from '../../../components/appbar/appbar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { useFlow } from '../../../configs/stackflow';
import { arrowBackDark, arrowBackLight, logoAxa } from '../../../configs/icon_route';
import { ActivityComponentType } from '@stackflow/react';
import MyProductItem from '../../../components/product/my_product_item';

const MyProductListPage: ActivityComponentType = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const {t} = useTranslation();
  const {pop, push} = useFlow();

  return (
    <AppScreen>
      <div className='frame-cover hasAppbar'>
        <Appbar
          theme={type}
          type='text'
          title={t('보유한 상품')}
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame ${type} gap8 t56`}>
          <MyProductItem
            theme={type}
            logo={logoAxa}
            name={'Wealth Advance Savings Series II - Ultimate'}
            // labelColor='yellow'
            // labelText={t('배당 저축')}
            productClick={() => push("ProductInfoPage", {userType: 'user', isSubscribed: true, productCode: ''})}
            payment={10000000}
          />
          <MyProductItem
            theme={type}
            logo={logoAxa}
            name={'Wealth Advance Savings Series II - Ultimate'}
            // labelColor='yellow'
            // labelText={t('배당 저축')}
            productClick={() => push("ProductInfoPage", {userType: 'user', isSubscribed: true, productCode: ''})}
            payment={10000000}
          />
        </div>
      </div>
    </AppScreen>
  );
};

export default MyProductListPage;