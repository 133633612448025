import React from 'react';
import { useTranslation } from 'react-i18next';
import { returnYearMonth } from 'utils/common/functions';

interface Props{
  theme: string;
  period: number; // month
  periodPaid: number; // month
}

const PaymentPeriod:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='payment-period col-stretch gap12 w100'>
      <div className={`m13 c1 ${props.theme}`}>{t('납입 기간')}</div>
      <div className={`payment-period-bar-wrap ${props.theme === 'light' ? 'bgSFE' : 'bgYellowA10'} ${props.theme} w100`}>
        <div className={`payment-period-bar ${props.theme === 'light' ? 'bgSF' : 'bgYellow'} ${props.theme}`} style={{width: `${(props.periodPaid * 100) / props.period}%`}} />
      </div>
      <div className='payment-period-text-wrap row-center'>
        <div className={`m13 c7 ${props.theme}`}>{returnYearMonth(props.periodPaid)}</div>
        <div className={`m13 c7 ${props.theme}`}>{returnYearMonth(props.period)}</div>
      </div>
    </div>
  );
};

export default PaymentPeriod;
