import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import AddressInput from 'components/input/address_input';
import RadioInput from 'components/input/radio_input';
import TextInput from 'components/input/text_input';
import ModalOk from 'components/modal/modal_ok';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  idSaveComp: (...arg: any) => void;
};

const IdCardPage: ActivityComponentType<Params> = ({ params }) => {
  const { type } = useSelector((state: RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState('');
  const [enName, setEnName] = useState('');
  const [enNameValid, setEnNameValid] = useState('');
  const [gender, setGender] = useState<number | boolean>(false);
  const [genderValid, setGenderValid] = useState('');
  const [birth, setBirth] = useState('');
  const [birthValid, setBirthValid] = useState('');
  const [smoking, setSmoking] = useState<number | boolean>(false);
  const [smokingValid, setSmokingValid] = useState('');
  const [postNumb, setPostNumb] = useState('');
  const [postNumbValid, setPostNumbValid] = useState('');
  const [address, setAddress] = useState('');
  const [addressValid, setAddressValid] = useState('');
  const [addressEng, setAddressEng] = useState('');
  const [addressEngValid, setAddressEngValid] = useState('');
  const [detailedAddress, setDetailedAddress] = useState('');
  const [detailedAddressValid, setDetailedAddressValid] = useState('');
  const [detailedAddressEng, setDetailedAddressEng] = useState('');
  const [detailedAddressEngValid, setDetailedAddressEngValid] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');

  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValid('');
  };
  const enNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnName(e.target.value);
    setEnNameValid('');
  };
  const genderChange = (idx: number | boolean) => {
    setGender(idx);
    setGenderValid('');
  };
  const birthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirth(e.target.value);
    setBirthValid('');
  };
  const smokingChange = (idx: number | boolean) => {
    setSmoking(idx);
    setSmokingValid('');
  };
  const detailedAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDetailedAddress(e.target.value);
    setDetailedAddressValid('');
  };
  const detailedAddressEngChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDetailedAddressEng(e.target.value);
    setDetailedAddressEngValid('');
  };
  const emailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailValid('');
  };

  const [modal, setModal] = useState('');
  const closeModal = () => {
    setModal('');
  };

  const saveData = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const birthPattern = /^\d{8}$/;

    if (
      name.length >= 2 && enName.length >= 2 &&
      typeof gender === 'number' &&
      birthPattern.test(birth) &&
      typeof smoking === 'number' &&
      address !== '' &&
      postNumb !== '' &&
      emailPattern.test(email)
    ) {
      pop();
      params.idSaveComp(name, enName, gender, birth, smoking, address, addressEng, detailedAddress, detailedAddressEng, postNumb, email);
    } else {
      if (name.length >= 2 || enName.length >= 2) {
        setNameValid('notEntered');
      }
      if (typeof gender !== 'number') {
        setGenderValid('notSelected');
      }
      if (!birthPattern.test(birth)) {
        setBirthValid('notEntered');
      }
      if (typeof smoking !== 'number') {
        setSmokingValid('notSelected');
      }
      if (address === '') {
        setAddressValid('notEntered');
      }
      if (addressEng === '') {
        setAddressEngValid('notEntered');
      }
      if (postNumb === '') {
        setPostNumbValid('notEntered');
      }
      if (!emailPattern.test(email)) {
        setEmailValid('notEntered');
      }
      setModal('validCheck');
    }
  };

  const { myData } = useSelector((state: RootState) => state.myData);

  useEffect(() => {
    const idCard = (myData as any).idCard;
    setName(idCard.name);
    setEnName(idCard.enName);
    setGender(idCard.gender);
    setBirth(idCard.birth);
    setSmoking(idCard.smoking);
    setAddress(idCard.address);
    setAddressEng(idCard.addressEng);
    setDetailedAddress(idCard.detailedAddress);
    setDetailedAddressEng(idCard.detailedAddressEng);
    setPostNumb(idCard.postNo);
    setEmail(idCard.email);
  }, []);

  return (
    <AppScreen>
      {modal === 'validCheck' ? (
        <ModalOk
          theme={type}
          text={t('입력한 정보를 확인해주세요.')}
          btn={{ name: t('확인'), onClick: () => closeModal() }}
        />
      ) : null}

      <div className="frame-cover">
        <div className={`frame ${type} gap36 t72 p20 bgBG2`}>
          <Appbar
            theme={type}
            type="text"
            title={t('ID카드 작성')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
            borderBottom={false}
          />
          <div className="col-stretch gap16">
            <div className={`id-card-info-wrap col-start ${type} borderC14 radius8 gap8`}>
              <div className={`${type} c1 b15`}>{t('ID Card 안내')}</div>
              <div className={`${type} c7 r15 col-start gap2`}>
                <div className="row-start gap8">
                  <div>-</div>{t('ID Card는 상품 설계서 발급 및 청약서 발급 시 필요한 정보입니다.')}
                </div>
                <div className="row-start gap8">
                  <div>-</div>{t('ID Card를 작성하면 설계서 발급 및 청약서 발급 시 해당 내용을 자동으로 기입합니다.')}
                </div>
                <div className="row-start gap8">
                  <div>-</div>{t('반복적인 정보 기입을 피하기 위한 목적이며, 해당 목적 외 다른 용도로 사용되지 않습니다.')}
                </div>
              </div>
            </div>
            <TextInput
              theme={type}
              label={t('국문 이름')}
              inputValue={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => nameChange(e)}
              placeholder={t('국문 이름 입력...')}
              icon={false}
              validation={nameValid !== '' ? false : true}
              validMessage={t('국문 이름을 확인해주세요.')}
            />
            <TextInput
              theme={type}
              label={t('영문 이름 (여권과 동일)')}
              inputValue={enName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => enNameChange(e)}
              placeholder={t('영문 이름 (여권과 동일) 입력...')}
              icon={false}
              validation={nameValid !== '' ? false : true}
              validMessage={t('영문 이름을 확인해주세요.')}
            />
            <div className={`col-start gap8 w100 ${type}`}>
              <div className={`radio-group-label m15 c1 ${type}`}>{t('성별')}</div>
              <div className={`col-stretch border w100 radius8 ${type}`}>
                <RadioInput
                  theme={type}
                  isActive={gender === 0}
                  onClick={() => genderChange(0)}
                  text={t('남성')}
                  padding={true}
                />
                <RadioInput
                  theme={type}
                  isActive={gender === 1}
                  onClick={() => genderChange(1)}
                  text={t('여성')}
                  padding={true}
                />
              </div>
              {genderValid !== '' ? (
                <div className={`radio-group-valid-wrap m13 colorRed ${type}`}>{t('성별을 선택해주세요.')}</div>
              ) : null}
            </div>
            <TextInput
              theme={type}
              label={t('생년월일')}
              inputValue={birth}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => birthChange(e)}
              placeholder={t('생년월일 8자리 입력...')}
              icon={false}
              validation={birthValid !== '' ? false : true}
              validMessage={t('8자리 생년월일을 입력해주세요.')}
              inputType="tel"
              maxLength={8}
            />
            <div className={`col-start gap8 w100 ${type}`}>
              <div className={`radio-group-label m15 c1 ${type}`}>{t('흡연여부')}</div>
              <div className={`col-stretch border w100 radius8 ${type}`}>
                <RadioInput
                  theme={type}
                  isActive={smoking === 0}
                  onClick={() => smokingChange(0)}
                  text={t('흡연자')}
                  padding={true}
                />
                <RadioInput
                  theme={type}
                  isActive={smoking === 1}
                  onClick={() => smokingChange(1)}
                  text={t('비흡연자')}
                  padding={true}
                />
              </div>
              {smokingValid !== '' ? (
                <div className={`radio-group-valid-wrap m13 colorRed ${type}`}>{t('흡연 여부를 선택해주세요.')}</div>
              ) : null}
            </div>
            <AddressInput
              theme={type}
              postNumb={postNumb}
              setPostNumb={setPostNumb}
              postNumbValid={postNumbValid}
              setPostNumbValid={setPostNumbValid}
              address={address}
              setAddress={setAddress}
              addressValid={addressValid}
              setAddressValid={setAddressValid}
              addressEng={addressEng}
              setAddressEng={setAddressEng}
              addressEngValid={addressEngValid}
              setAddressEngValid={setAddressEngValid}
            />
            <TextInput
              theme={type}
              label={t('상세주소')}
              inputValue={detailedAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => detailedAddressChange(e)}
              placeholder={t('상세주소 입력...')}
              icon={false}
              validation={detailedAddressValid !== '' ? false : true}
              validMessage={t('상세주소를 확인해주세요.')}
              inputType="text"
            />
            <TextInput
              theme={type}
              label={t('영문 상세주소')}
              inputValue={detailedAddressEng}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => detailedAddressEngChange(e)}
              placeholder={t('영문 상세주소 입력...')}
              icon={false}
              validation={detailedAddressEngValid !== '' ? false : true}
              validMessage={t('영문 상세주소를 확인해주세요.')}
              inputType="text"
            />
            <TextInput
              theme={type}
              label={t('이메일')}
              inputValue={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => emailChange(e)}
              placeholder={t('이메일 입력...')}
              icon={false}
              validation={emailValid !== '' ? false : true}
              validMessage={t('이메일을 확인해주세요.')}
              inputType="email"
            />
          </div>
          <BigBtn theme={type} type="primary" text={t('저장')} onClick={() => saveData()} fill={true} />
        </div>
      </div>
    </AppScreen>
  );
};

export default IdCardPage;
