import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

export const createUserDefaultData = (userId: string, phoneNumber: string) => ({
  userId,
  phoneNumber,
  createdAt: new Date().getTime(),
  hasTaskNo: 0,
  idCard: {
    name: '',
    enName: '',
    gender: 0,
    birth: '',
    isSmoking: false,
    address: '',
    addressEng: '',
    detailedAddress: '',
    detailedAddressEng: '',
    postNo: '',
    email: '',
  },
});

const createUser = async (userId: string, phoneNumber: string) => {
  const app = getApp();
  const db = getFirestore(app);
  try {
    await setDoc(doc(db, 'kr_users', userId), createUserDefaultData(userId, phoneNumber));
  } catch (err) {
    console.log(err);
  }
};

export default createUser;
