import { AppScreen } from '@stackflow/plugin-basic-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import BigBtn from '../../../components/btn/big_btn';
import { useFlow } from '../../../configs/stackflow';
import { ActivityComponentType } from '@stackflow/react';

type Params = {
  popRoot: () => void;
}

const SignUpCompletePage:ActivityComponentType<Params> = ({ params }) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  const moveToLogin = () => {
    params.popRoot();
    pop();
  }

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} p36 t64`}>
          <div className='signup-complete-frame gap24'>
            <div className={`b20 c1 ${type}`}>{t('회원가입이 완료되었습니다')}</div>
            <BigBtn
              theme={type}
              type='primary'
              text={t('로그인하러 가기')}
              onClick={() => moveToLogin()}
              // fill={true}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SignUpCompletePage;