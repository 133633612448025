import taskStates from 'configs/task_states';
import { getApp } from 'firebase/app';
import { addDoc, collection, doc, getFirestore, increment, updateDoc } from 'firebase/firestore';

const createUserTask = async (productId: string, userId: string, phoneNumber: string) => {
  const app = getApp();
  const db = getFirestore(app);
  try {
    const d = await addDoc(collection(db, 'kr_user_tasks'), {
      createdAt: new Date().getTime(),
      productCode: productId,
      step: taskStates.ESTIMATE,
      subStep: 1,
      phoneNumber,
      userId,
    });

    await updateDoc(doc(db, 'kr_users', userId), {
      hasTaskNo: increment(1),
    });

    return d.id;
  } catch (err) {
    console.log(err);
  }
};

export default createUserTask;
