const CreditTable = ({items}: any) => {
  return (
    <div className='table-wrapper radius8 borderC14 light row-center'>
      {
        items.map((item: any, i: any) => {
          return (
            <div key={String(i)} className="table-credit-col-wrapper col-center">
              <div className="header m13">{item.c}</div>
              <div className="ratio m15">{item.g}</div>
            </div>
          );
        })
      }
    </div>
  );
};

export default CreditTable;
