import React from 'react';
import ReactLoading from 'react-loading';

interface Props{
  theme: string;
  type: string; // primary, secondary, other, warning, otherWarning
  text: string;
  onClick: () => void;
  fill?: boolean;
  withOtherBtn?: boolean;
  isLoading?: boolean;
  disabled?: boolean
}

const BigBtn:React.FC<Props> = (props) => {
  return (
    <div
      style={props.disabled ? {opacity: '0.5'} : {}}
      className={
        `big-btn b17 ${
          props.type === 'warning' ? 'bgRed colorWhite' :
          props.type === 'otherWarning' ? 'colorRed' :
          props.type === 'primary' ? 'bgSDG slg' :
          props.type === 'secondary' ? 'bg15 c1' :
          props.type === 'other' ? 'c5' : ''
        } ${props.theme} ${props.fill ? 'w100' : ''} ${props.withOtherBtn ? 'withOther' : ''}`
      }
      onClick={() => {
        if (props.disabled) {
          return;
        }

        if (!props.isLoading) {
          props.onClick();
        }
      }}   
    >
      {!props.isLoading ? props.text : (
        <ReactLoading type={'bubbles'} color={'#72FBA0'} width={40} height={40}/>
      )}
    </div>
  );
};

export default BigBtn;