import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  items: {year: number, refund: number}[];
  graphData: {paymentType: string, payment: number, period: number};
}

const SimulationGraph:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const returnHeight = (n: number) => {
    const height = (n / props.items[props.items.length - 1].refund) * 160;
    return height;
  };

  return (
    <div className={`simul-graph-container ${props.theme} bg16 row-stretch`}>
      <div className={`simul-graph-label col-stretch`}>
        <div className='simul-graph-area' />
        <div className={`simul-graph-label-text ${props.theme} bg14 c5 m13`}>{t('연차')}</div>
      </div>
      {
        props.items.map((item: {year: number, refund: number}) => {
          return (
            <div className='simul-graph-data'>
              <div className={`simul-graph-area col-end gap4`}>
                <div className={`c5 m13`}>$ {item.refund.toLocaleString()}</div>
                <div className={`simul-graph-bar`} style={{height: returnHeight(item.refund)}} />
              </div>
              <div className={`simul-graph-text ${props.theme} bg14 c5 m13`}>{item.year}</div>
            </div>
          );
        })
      }
      <div className={`simul-graph-label col-stretch`}>
        <div className='simul-graph-area' />
        <div className={`simul-graph-label-text ${props.theme} bg14 c5 m13`}></div>
      </div>
    </div>
  );
};

export default SimulationGraph; 