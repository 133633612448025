import React from 'react';
import SectionTitle from '../common/section_title';
import { useTranslation } from 'react-i18next';
import HomeProductItem from './product_item';
import products from 'configs/products';
import getTaskLabel from 'utils/product/get_task_label';

interface Props{
  theme: string;
  switch: boolean;
  switchActive: boolean;
  switchToggle: () => void;
  items: any;
  goProductList: () => void;
}

const HotProductItems:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className={`home-product col-stretch bgBG2 bg2Shadow ${props.theme}`}>
      <SectionTitle
        theme={props.theme}
        title={t('가장 유저들의 관심이 많은 상품')}
        switch={props.switch}
        switchActive={props.switchActive}
        switchToggle={props.switchToggle}
      />
      {
        props.items.map((item: any, idx: number) => {
          const product = products.filter((p: any) => p.code === item.productCode)[0];
          const state = item.isReject ? 'reject' : getTaskLabel(item.step, item.subStep, true);

          return (
            <HomeProductItem
              irrListLabel={product.irrListLabel}
              labels={product.labels}
              hideStatus
              productCode={product.code}
              key={idx.toString()}
              theme={props.theme}
              logo={product.logo}
              name={product.name}
              paymentPeriod={product.durations.map((d: any) => `${d}${t('년')}`).join(' / ')}
              chips={product.allowMonthlyPaid ? [t('월납가능')] : []}
              minPayment={product.minForYear}
              exchange={props.switchActive}
              isLast={(props.items.length < 3 && (props.items.length === idx + 1))}
              state={state}
              company={product.company}
            />
          );
        })
      }
    </div>
  );
};

export default HotProductItems;