import { initializeApp, getApps, getApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAICO2YQ5lUi30Z52UXtF3d8LPHumKm7q8",
    authDomain: "insureinapp.firebaseapp.com",
    projectId: "insureinapp",
    storageBucket: "insureinapp.appspot.com",
    messagingSenderId: "554766735240",
    appId: "1:554766735240:web:3ddbda02a1252ff9110c3d",
    measurementId: "G-QJPWG6N938"
  };
  
  // Initialize Firebase
  const apps = getApps();
  if (apps.length === 0) {
    const app = initializeApp(firebaseConfig);
    // getAnalytics(app);
    initializeFirestore(getApp(), {
      experimentalForceLongPolling: true,
      // useFetchStreams: false,
      // experimentalAutoDetectLongPolling: false,
    });
  }
};

export default initFirebase;
