import { useFlow } from 'configs/stackflow';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { returnDate } from 'utils/common/functions';

interface Props{
  theme: string;
  title: string;
  date: Number;
  text: string;
  isLastItem: boolean;
}

const NoticeItem:React.FC<Props> = (props) => {
  const { push } = useFlow();

  return (
    <div
      className={`notice-item col-start gap6 ${props.isLastItem ? '' : 'borderBottomC14W05'} ${props.theme}`}
      onClick={() => push("NoticeDetailPage", {title: props.title, date: props.date, text: props.text})}
    >
      <div className={`c1 m17 ${props.theme}`}>{props.title}</div>
      <div className={`c7 m13 ${props.theme}`}>{returnDate(props.date)}</div>
    </div>
  );
};

export default NoticeItem;