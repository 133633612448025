import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useFlow } from '../../../configs/stackflow';
import TextInput from '../../../components/input/text_input';
import { arrowBackDark, arrowBackLight } from '../../../configs/icon_route';
import BigBtn from '../../../components/btn/big_btn';
import { ActivityComponentType } from '@stackflow/react';
import Toast from 'components/toast/toast';
import Appbar from 'components/appbar/appbar';

const LoginPage:ActivityComponentType = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { push, replace, pop } = useFlow();

//toast
  const [toast, setToast] = useState('');

  const [mobileValue, setMobileValue] = useState('');
  const mobileChange = (e:ChangeEvent<HTMLInputElement>) => {
    setMobileValue(e.target.value);
    setMobileValid('');
  };

  const [mobileValid, setMobileValid] = useState('');

  const getCode = async () => {
    const mobilePattern = /^\d{10,11}$/;

    if (!mobilePattern.test(mobileValue)) {
      setMobileValid('format error');
    } else {
      push("LoginCodePage", {loginReplace: loginReplace, phoneNumber: mobileValue}, {animate: false});
    }
  };

  const loginReplace = () => {
    pop();
    pop();
    replace("HomePage", {});
  };

  return (
    <AppScreen>
      <Toast
        theme={type}
        text={t('잘못된 휴대전화 번호입니다.')}
        start={toast}
        setStart={setToast}
        toastName='failLogin'
      />
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('전화번호로 시작하기')}
          borderBottom={false}
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame hasAppbar ${type} p36 bgBG2`}>
          <div className={`login-my-id-frame`}>
            <div className='login-my-id-wrap'>
              {/* <img className='login-logo' src={logoLoginLight} alt={'인슐린 로고'} /> */}
              <div className='login-body-inputs-wrap gap36'>
                <div className='col-stretch gap24'>
                  <TextInput
                    theme={type}
                    label={t('휴대전화 번호')}
                    inputValue={mobileValue}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => mobileChange(e)}
                    placeholder={t('\'-\' 제외 숫자만 입력')}
                    icon={false}
                    validation={mobileValid !== '' ? false : true}
                    validMessage={t('휴대전화 번호가 잘못되었습니다')}
                    inputType='tel'
                    autoFocus
                  />
                </div>
                <div className='login-my-id-btns-wrap gap16'>
                  <BigBtn
                    theme={type}
                    type='primary'
                    text={t('인증코드 받기')}
                    onClick={() => getCode()}
                    fill={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default LoginPage;