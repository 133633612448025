import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useFlow } from '../../../configs/stackflow';
import TextInput from '../../../components/input/text_input';
import BigBtn from '../../../components/btn/big_btn';
import Appbar from '../../../components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from '../../../configs/icon_route';
import { ActivityComponentType } from '@stackflow/react';

type Params = {
  popRoot: () => void;
}

const SignUpPage:ActivityComponentType<Params> = ({ params }) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop, replace } = useFlow();

  const [emailValue, setEmailValue] = useState('');
  const emailChange = (e:ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
    setEmailValid('');
  };

  const [pwValue, setPwValue] = useState('');
  const pwChange = (e:ChangeEvent<HTMLInputElement>) => {
    setPwValue(e.target.value);
    setPwValid('');
  };

  const [emailValid, setEmailValid] = useState('');
  const [pwValid, setPwValid] = useState('');

  const signUpSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const pwPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#]{12,}$/;

    if (!emailPattern.test(emailValue)) {
      setEmailValid('format error');
    }
    if (!pwPattern.test(pwValue)) {
      setPwValid('format error');
    }
    if (emailPattern.test(emailValue) && pwPattern.test(pwValue)) {
      replace('SignUpCompletePage', {popRoot: params.popRoot});
    }
  };

  const [pwEyeOpen, setPwEyeOpen] = useState(false);

  return (
    <AppScreen>
      <Appbar
        theme={type}
        type='text'
        title={t('회원가입')}
        left={{
          icon: type === 'dark' ? arrowBackDark : arrowBackLight,
          onClick: () => pop(),
        }}
      />
      <div className='frame-cover'>
        <div className={`frame ${type} p36 t64`}>
          <div className={`signup-frame`}>
            <div className={`b20 c1 ${type}`}>{t('회원가입')}</div>
            <div className='signup-inputs-wrap gap16'>
              <TextInput
                theme={type}
                label={t('이메일')}
                inputValue={emailValue}
                onChange={(e:ChangeEvent<HTMLInputElement>) => emailChange(e)}
                placeholder={t('이메일 입력...')}
                icon={false}
                validation={emailValid !== '' ? false : true}
                validMessage={t('이메일 형식이 잘못되었습니다')}
              />
              <TextInput
                theme={type}
                label={t('비밀번호')}
                subLabel={t('(영어/숫자/특수문자 포함 12자 이상)')}
                inputValue={pwValue}
                onChange={(e:ChangeEvent<HTMLInputElement>) => pwChange(e)}
                placeholder={t('비밀번호 입력...')}
                icon={true}
                validation={pwValid !== '' ? false : true}
                validMessage={t('영어/숫자/특수문자 포함 12자 이상이어야 합니다')}
                eyeOpen={pwEyeOpen}
                setEyeOpen={setPwEyeOpen}
              />
            </div>
            <BigBtn
              theme={type}
              type='primary'
              text={t('회원가입')}
              onClick={() => signUpSubmit()}
              fill={true}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SignUpPage;