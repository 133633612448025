import { getApp } from 'firebase/app';
import { getDoc, doc, getFirestore, } from 'firebase/firestore';

const getExrateInfo = async () => {
  const app = getApp();
  const db = getFirestore(app);
  const d = await getDoc(doc(db, 'kr_info', 'info'));
  return d.data();
};

export default getExrateInfo;
