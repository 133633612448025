import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  url: string;
};

declare var mtw_open_url: any;

const BlogPostPage: ActivityComponentType<Params> = ({ params }) => {
  const { type } = useSelector((state: RootState) => state.theme);
  const { t } = useTranslation();
  const { pop, push } = useFlow();
  const [loading, setLoading] = useState(true);

  const handleUrl = (event: any) => {
    if (event.origin === 'https://blog.insurein.life') {
      if (event.data.includes('https://blog.insurein.life')) {
        const url = event.data.includes('/kr/app') ? event.data : event.data.replaceAll('/kr', '/kr/app');
        pop();
        push('BlogPostPage', { url });
      } else {
        mtw_open_url.postMessage(
          JSON.stringify({
            url: event.data,
          })
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleUrl, false);

    return () => {
      window.removeEventListener('message', handleUrl);
    };
  }, []);

  return (
    <AppScreen>
      <div className="frame-cover">
        <div className={`frame ${type} t48 bgBG1`} style={{ paddingBottom: '0px' }}>
          <Appbar
            theme={type}
            type="text"
            title={params.url.includes('seminar.insurein.life') ? t('세미나 모집') : t('블로그')}
            borderBottom={false}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className="col-stretch gap8">
            {loading ? (
              <div
                className="col-center"
                style={{ position: 'fixed', zIndex: 999, width: '100vw', height: '100vh', justifyContent: 'center' }}
              >
                <ReactLoading type="spin" width={32} color="#20363B" />
              </div>
            ) : null}
            <iframe
              onLoad={() => {
                setLoading(false);
              }}
              title={`blog-${new Date().getTime()}`}
              id="blog"
              src={`${params.url}?wv=y&ts=${new Date().getTime()}`}
              style={{ height: 'calc(100vh - 48px)' }}
              frameBorder="0"
              sandbox="allow-scripts allow-same-origin"
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default BlogPostPage;
