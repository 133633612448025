import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  items: {name: string, onClick: () => void}[];
  onClose: () => void;
}

const MoreMenuSheet:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const itemClick = (idx: number) => {
    props.items[idx].onClick();
    props.onClose();
  }
  return (
    <div className={`sheet-overlay ${props.theme} bgBlack05`}>
      <div className='dropdown-sheet'>
        <div className={`dropdown-sheet-items col-stretch bgBG2 radius12 ${props.theme}`}>
          {
            props.items.map((item: {name: string, onClick: () => void}, idx: number) => {
              return (
                <div
                  className={`dropdown-sheet-item c1 m17 ${idx === props.items.length - 1 ? '' : 'borderBottom05'} ${props.theme}`}
                  onClick={() => itemClick(idx)}
                >
                  {item.name}
                </div>
              );
            })
          }
        </div>
        <div className={`dropdown-sheet-cancel ${props.theme} colorRed bgBG2 radius12`} onClick={props.onClose}>{t('취소')}</div>
      </div>
    </div>
  );
};

export default MoreMenuSheet;