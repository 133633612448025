import React from 'react';
import SectionTitle from '../common/section_title';
import HomeBlogItem from './blog_item';
import MiddleBtn from '../btn/middle_btn';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  label: string;
  items: any[];
  onClickMore: any;
}

const HomeBlog:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className={`home-blog bgBG2 bg2Shadow ${props.theme}`}>
      <SectionTitle
        title={props.label}
        theme={props.theme}
      />
      <div>
        {
          props.items.map((item: any, idx: number) => {
            return (
              <HomeBlogItem
                thumb={item.thumb}
                type={item.type}
                key={idx}
                theme={props.theme}
                id={item.id}
                title={item.title}
                date={item.date}
                isHome={true}
                url={item.url}
              />
            );
          })
        }
        <MiddleBtn
          theme={props.theme}
          type='other'
          text={t('더 보기')}
          onClick={() => {
            props.onClickMore();
          }}
        />
      </div>
    </div>
  );
};

export default HomeBlog;