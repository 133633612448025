import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import kr from './kr.json';
import cn from './cn.json';
import vn from './vn.json';
import jp from './jp.json';
import en from './en.json';
import hk from './hk.json';
import id from './id.json';
import tw from './tw.json';
import th from './th.json';
import my from './my.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      kr: kr,
      vn: vn,
      cn: cn,
      jp: jp,
      en: en,
      hk: hk,
      id: id,
      tw: tw,
      th: th,
      my: my,
    },
    lng: 'kr',
    fallbackLng: 'kr',
    ns: ['page'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
