import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  unit: 'KRW',
  HKD: 0,
  USD: 0,
  exp: '',
  syncDate: '',
};

export const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    changeUnit: (state, action) => {
      state.unit = action.payload.unit;
    },
    updateData: (state, action) => {
      state.HKD = action.payload.hkd;
      state.USD = action.payload.usd;
      state.exp = action.payload.exp;
      state.syncDate = action.payload.syncDate;
    },
  },
});

export const {changeUnit, updateData} = exchangeSlice.actions;

export default exchangeSlice.reducer;
