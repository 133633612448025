// AddressForm.tsx
import React, { useRef, useState } from 'react';
import TextInput from 'components/input/text_input';
import Appbar from 'components/appbar/appbar';
import MiddleBtn from 'components/btn/middle_btn';
import { useTranslation } from 'react-i18next';
import { closeDark, closeLight } from 'configs/icon_route';

interface Props {
  theme: string;
  postNumb: string;
  setPostNumb: React.Dispatch<React.SetStateAction<string>>;
  postNumbValid: string;
  setPostNumbValid: React.Dispatch<React.SetStateAction<string>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  addressValid: string;
  setAddressValid: React.Dispatch<React.SetStateAction<string>>;
  addressEng: string;
  setAddressEng: React.Dispatch<React.SetStateAction<string>>;
  addressEngValid: string;
  setAddressEngValid: React.Dispatch<React.SetStateAction<string>>;
}

const AddressInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const postCodeRef = useRef(null);
  const [showPostcode, setShowPostCode] = useState(false);

  const getAddress = (data: any) => {
    props.setPostNumb(data.zonecode);
    props.setPostNumbValid('');
    props.setAddress(data.roadAddress);
    props.setAddressValid('');
    props.setAddressEng(data.roadAddressEnglish);
    props.setAddressEngValid('');

    setShowPostCode(false);
  };

  const openPostcode = () => {
    setShowPostCode(true);

    new (window as any).daum.Postcode({
      oncomplete: getAddress,
      width: '100%',
      height: '100%',
    }).embed(postCodeRef.current);
  };

  const closePostcode = () => {
    setShowPostCode(false);
  };

  return (
    <div className={`col-stretch gap8 w100 ${props.theme}`}>
      <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('주소')}</div>
      <div className={`address-input-modal ${showPostcode && 'show'}`}>
        <Appbar
          theme={props.theme}
          type="text"
          title={t('우편번호 찾기')}
          left={{
            icon: props.theme === 'dark' ? closeDark : closeLight,
            onClick: closePostcode,
          }}
        />
        <div ref={postCodeRef} className={'window'} />
      </div>
      <div className={`row-start gap8`}>
        <TextInput
          theme={props.theme}
          inputValue={props.postNumb}
          placeholder={t('우편번호 입력...')}
          icon={false}
          validation={props.postNumbValid === ''}
          validMessage={t('우편번호를 확인해주세요.')}
          inputType="tel"
          disabled={true}
        />
        <div style={{width: '121px'}}>
          <MiddleBtn theme={props.theme} text={'우편번호 찾기'} type="secondary" onClick={openPostcode} fill={true} />
        </div>
      </div>
      <TextInput
        theme={props.theme}
        inputValue={props.address}
        placeholder={t('주소 입력...')}
        icon={false}
        validation={props.addressValid === ''}
        validMessage={t('주소를 확인해주세요.')}
        inputType="text"
        disabled={true}
      />
      <TextInput
        theme={props.theme}
        inputValue={props.addressEng}
        placeholder={t('영문주소 입력...')}
        icon={false}
        validation={props.addressEngValid === ''}
        validMessage={t('영문주소를 확인해주세요.')}
        inputType="text"
        disabled={true}
      />
    </div>
  );
};

export default AddressInput;
