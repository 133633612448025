/* eslint-disable no-dupe-keys */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFlow } from '../../../configs/stackflow';
import { useTranslation } from 'react-i18next';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { arrowBackDark, arrowBackLight, arrowRightItemDark, arrowRightItemLight, outlinkDark, outlinkLight, pSimulation, reviewAvatar01, reviewAvatar02, reviewAvatar04, reviewAvatar05 } from '../../../configs/icon_route';
import Appbar from '../../../components/appbar/appbar';
import TabMenu from '../../../components/common/tab_menu';
import PaymentPeriod from '../../../components/product/payment_period';
import SectionTitle from '../../../components/common/section_title';
import RowItem from '../../../components/common/row_item';
import BlockItem from '../../../components/common/block_item';
import { ActivityComponentType } from '@stackflow/react';
import MoreMenuSheet from 'components/sheet/more_menu_sheet';
import ModalOk from 'components/modal/modal_ok';
import { returnExchange } from 'utils/common/functions';
import BigBtn from 'components/btn/big_btn';
import {  getDocs, query, where } from 'firebase/firestore';
import { getApp } from 'firebase/app';
import db from 'configs/db';
import { getAuth } from 'firebase/auth';
import products from 'configs/products';
import ProductAppStep from 'components/product/product_application_step';
import { List } from 'immutable';
import Toast from 'components/toast/toast';
import taskStates from 'configs/task_states';
import SmallBtn from 'components/btn/small_btn';
import createUserTask from 'utils/firebase_firestore/create_user_tasks';
import MiddleBtn from 'components/btn/middle_btn';
import reviewData from 'configs/review_data';
import ReviewCard from 'components/review/review_card';
import SNPTable from 'components/table/snp_table';
import IRRTable from 'components/table/irr_table';
import CreditTable from 'components/table/credit_table';

type Params = {
  userType: string;
  userEmail?: string;
  isSubscribed: boolean;
  setMainToast?: Dispatch<SetStateAction<string>>;
  productCode: string;
};

const ProductInfoPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { unit } = useSelector((state:RootState) => state.exchange);
  const {pop, push, replace} = useFlow();
  const {t} = useTranslation();

// 신청 중인지 아닌지
  const [tabActive, setTabActive] = useState(0);

  const [switchA, setSwitchA] = useState(true);
  const [switchB, setSwitchB] = useState(true);
  const switchToggleA = () => {
    setSwitchA(!switchA);
  };
  const switchToggleB = () => {
    setSwitchB(!switchB);
  };

//
  const [switchTripleA, setSwitchTripleA] = useState(0);
  const [switchTripleB, setSwitchTripleB] = useState(0);
  const switchToggleTripleA = (idx: number) => {
    setSwitchTripleA(idx);
  };
  const switchToggleTripleB = (idx: number) => {
    setSwitchTripleB(idx);
  };

// 가입 신청 정보
  const [toast, setToast] = useState('');

  const [active, setActive] = useState([0, '']); // [index, 'reject'] 스탭 idx(0~5), 리젝이면 'rejecet' 나머지 스트링은 일반
  const [rejectMsg, setRejectMsg] = useState(t('영문 성명이 제대로 입력되지 않았습니다. 여권과 동일한 이름으로 확인 부탁드립니다.'));

  const [fileA, setFileA] = useState<any>(List([]));
  const [fileB, setFileB] = useState<any>(List([]));
  const [fileC, setFileC] = useState<any>(List([]));
  const [fileValidA, setFileValidA] = useState('');
  const [fileValidB, setFileValidB] = useState('');
  const [fileValidC, setFileValidC] = useState('');

  const handleFileA = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileA(e.target.files?.[0]);
    setFileValidA('');
  };
  const handleFileB = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileB(e.target.files?.[0]);
    setFileValidB('');
  };
  const handleFileC = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileC(e.target.files?.[0]);
    setFileValidC('');
  };

  const openFileFinder = (id: string) => {
    if (document.querySelector(`input#${id}`)) {
      (document.querySelector(`input#${id}`)! as any).click();
    }
  };

  const saveClick = () => {
    if (fileA !== List([]) && fileB !== List([]) && fileC !== List([])) {
      setToast('saveThisPage');
    } else {
      if (fileA === List([])) {
        setFileValidA('noFile');
      }
      if (fileB === List([])) {
        setFileValidB('noFile');
      }
      if (fileC === List([])) {
        setFileValidC('noFile');
      }
      setModal('fileValid');
    }
  };

// more
  const [moreOpen, setMoreOpen] = useState(false);
  const moreClose = () => {
    setMoreOpen(false);
  };

// modal
  const [modal, setModal] = useState('');
  const modalClose = () => {
    setModal('');
  };
  const unableToEnter = () => {
    setModal('unableToEnter');
  };

  const [moveSignal, setMoveSignal] = useState('');

  useEffect(() => {
    if (moveSignal === 'appInfo') {
      replace("ProductApplicationInfoPage", {setToastStart: params.setMainToast, fromWhere: 'productInfo' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveSignal]);

  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    req();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const req = async () => {
    const auth = getAuth(getApp());
    const user = auth.currentUser;

    const tasks = await getDocs(query(db.getKrTaskCol(), where('userId', '==', user?.uid ?? ''), where('productCode', '==', params.productCode)));
    
    const ds = tasks.docs.map((d) => ({id: d.id, ...d.data()}));

    if (ds.length > 0) {
      setData(ds[0]);
      setLoading(false);
    } else {
      setTabActive(1);
      setData({
        id: null,
        productCode: params.productCode,
        step: taskStates.ESTIMATE,
        subStep: 1,
      });
      setLoading(false);
    }
  };

  const reqEstimate = async () => {
    if (data.id === null) {
      const auth = getAuth();
      const id = (await createUserTask(params.productCode, auth.currentUser?.uid ?? '', auth.currentUser?.phoneNumber ?? '') ?? '');
      push("PlanRequestPage", {taskId: id, logo: product.logo, productName: product.name, setToast: setToast, refresh: req, productCode: data.productCode});
    } else {
      push("PlanRequestPage", {taskId: data.id, logo: product.logo, productName: product.name, setToast: setToast, refresh: req, productCode: data.productCode});
    }
  };

  if (loading) {
    return  <div />;
  }

  const product = products.filter((p: any) => p.code === params.productCode)[0];

  const applyFromSimulation = () => {
    pop();
    reqEstimate();
  };

  return (
    <AppScreen>
      {
        moreOpen ?
        <MoreMenuSheet
          theme={type}
          items={[
            {
              name: t('신탁 명의 변경'),
              onClick: () => unableToEnter(),
            },
            {
              name: t('해지'),
              onClick: () => unableToEnter(),
            },
            {
              name: t('양도'),
              onClick: () => unableToEnter(),
            },
            {
              name: t('인출'),
              onClick: () => unableToEnter(),
            },
          ]}
          onClose={moreClose}
        /> : null
      }
      {
        modal === 'unableToEnter' ?
        <ModalOk
          theme={type}
          text={t('서비스 준비 중입니다.')}
          btn={{name: t('확인'), onClick: () => modalClose()}}
        /> : null
      }
      <Toast
        theme={type}
        text={t('작성 중인 내용이 저장되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='reqSaved'
      />
      <Toast
        theme={type}
        text={t('설계서 요청이 완료되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='planReqComplete'
      />
      <Toast
        theme={type}
        text={t('청약서 요청이 완료되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='subsReqComplete'
      />
      <Toast
        theme={type}
        text={t('파일 정보가 저장되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='saveThisPage'
      />
      <Toast
        theme={type}
        text={t('요청서를 제출했습니다.')}
        start={toast}
        setStart={setToast}
        toastName='reqSubmit'
      />
      <Toast
        theme={type}
        text={t('송금증을 제출했습니다.')}
        start={toast}
        setStart={setToast}
        toastName='rmtSubmit'
      />
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('상품 정보')}
          color='bgBG2'
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
          right={
            null
            // params.userType === 'user' && params.isSubscribed ? {
            //   icon: type === 'dark' ? moreDark : moreLight,
            //   onClick:() => setMoreOpen(true),
            // } : null
          }
        />
        <div className={`frame ${type} gap12 ${data.id === null ? `fixed-bottom` : ''} hasAppbar`} style={{paddingBottom: data.id === null ? '80px' : '8px'}}>
          {
            data.id !== null ? null : (
              <div style={{position: 'fixed', bottom: '0px', width: '100%', backgroundColor: 'white', zIndex: 1000}}>
                <div style={{padding: '10px 20px'}}>
                  <BigBtn
                    theme={type}
                    type='primary'
                    text={t('설계서 요청')}
                    onClick={reqEstimate}
                    fill={true}
                  />
                </div>
              </div>
            )
          }
          <div className='col-stretch'>
            <div className={`product-info-head col-start gap8 bgBG2 ${type}`}>
              <div className='row-center gap6'>
                <img src={product.logo} alt='product logo' />
                <div className='m13'>{product.company}</div>
              </div>
              <div className={`c1 b20 ${type}`}>{product.name}</div>
              <div className='col-start gap12'>
                <div className={`m13 c1 light`}><span className={`b17 light`}>{t('최소')} {returnExchange(product.minForYear, 'USD')?.toLocaleString()} <span className={`r15 c7 light`}>{'USD'} / year</span></span></div>
              </div>
              <div className='row-center gap8'>
                <div className={`home-product-item-period c1 m13 bg16 radius4 light`}>{product.durations.map((d: any) => `${d}${t('년')}`).join(' / ')}</div>
                {
                  (product.allowMonthlyPaid ? [t('월납가능')] : []).map((chip: any, i: number) => (
                    <div key={String(i)} className={`home-product-item-period c1 m13 bg15 radius4 light`}>
                      {chip}
                    </div>
                  ))
                }
              </div>
              <div />
              {
                product.code === 'ZURICH_01' ? (
                  <div className='product-snp-table-wrapper col-start gap12'>
                    <div className='row-center gap6'>
                      <div className='r15'>{t('예상 연평균 이율')}</div>
                      <div className='b15' style={{color: '#00B55A'}}>{product.code === 'ZURICH_01' ? t('S&P500 연동') : product.irrRange}</div>
                    </div>
                    <SNPTable />
                  </div>
                ) : (
                  <div className='radius8 product-irr-wrapper col-start gap2' style={{marginTop: '4px'}}>
                    <div className='row-center gap6'>
                      <div className='m15'>{t('예상 연평균 이율')}</div>
                      <div className='b15 per'>{product.code === 'ZURICH_01' ? t('S&P500 연동') : product.irrRange}</div>
                    </div>
                    <div className='row-center gap6' style={{marginBottom: '6px'}}>
                      <div className='m13 fullratio'>({t('이행률 평균')}: {`${product.avgFullfillmentRatio}`})</div>
                      <img src="/icon/avif/question.png" alt="q" onClick={() => push('CommonGuide', {type: 'irr&fullfillmentRatio'})} />
                    </div>
                    <IRRTable rows={product.irrTable?.basic}/>
                  </div>
                )
              }
              {
                product.code === 'ZURICH_01' ? null : (
                  <div className='col-start gap4'>
                    <div className='m13 c7 light'>- {t('연평균 이율은 보험사에서 발행한 최근 설계서 기준으로 예측됩니다.')}</div>
                    <div className='m13 c7 light'>- {t('예상 평균 연이율은 최초 납입부터 해당 기간까지의 IRR(내부수익률)로 계산됩니다. ex) 30년 : 0~30년까지의 수익률')}</div>
                    <div className='m13 c7 light'>- {t('위 표는 예측값으로 실제 발급한 설계서 값과 차이가 있을 수 있습니다. 상품 가입전 꼭 설계서를 확인하세요.')}</div>
                  </div>
                )
              }
              {
                !product.intro ? null : (
                  <div className='col-start product-intro-wrapper m15 c7 light gap6'>
                    {product.intro.map((intro: any, i: any) => {
                      return (
                        <div key={String(i)} className={intro.class}>{intro.content}</div>
                      );
                    })}
                  </div>
                )
              }
            </div>
            {
              data.id === null ? null :
              data.step !== taskStates.COMPLETE ?
              <TabMenu
                theme={type}
                items={[t('가입 진행 정보'), t('상품 정보')]}
                active={tabActive}
                setActive={setTabActive}
                backgroundColor='bgBG2'
              /> :
              <TabMenu
                theme={type}
                items={[t('납입 정보'), t('계약 정보'), t('상품 정보')]}
                active={tabActive}
                setActive={setTabActive}
                backgroundColor='bgBG2'
              />
            }
          </div>
          {
            tabActive === 0 && data.step !== taskStates.COMPLETE ?
            <div className='col-stretch gap12'>
              <div className={`col-stretch bgBG2 bg2Shadow ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('진행 단계')}
                />
                <div className='product-app-info-steps gap2'>
                  <ProductAppStep
                    theme={type}
                    title={t('설계서 발급')}
                    child={
                      <div>
                        {
                          !data.subStep || data.subStep === 1 ?
                            t('Step 1/2 - 설계서 요청서를 작성해주세요.') :
                            t('Step 2/2 - 설계서 발급 대기중입니다.')
                        }
                        {
                          !data.isReject ? null : (
                            <div className='home-product-item-chip-red m13 radius4' style={{marginTop: "6px"}}>{data.rejectMention}</div>
                          )
                        }
                        {
                          !data.subStep || data.subStep === 1 ? (
                            <div style={{marginTop: '12px'}}>
                              <SmallBtn
                                theme={type}
                                type='primary'
                                text={t('설계서 요청')}
                                onClick={reqEstimate}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    }
                    inactiveChild={
                      <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                        {
                          data.estimateFiles && data.estimateFiles.map((file: any, i: number) => {
                            return (
                              <SmallBtn
                                key={String(i)}
                                theme={type}
                                type='secondary'
                                text={t('설계서 열람') + `- ${i + 1}`}
                                onClick={() => push('ProductPlanPage', {
                                  data,
                                })}
                              />
                            );
                          })
                        }
                      </div>
                    }
                    active={data.step === taskStates.ESTIMATE}
                    isLast={false}
                    reject={data.step === taskStates.ESTIMATE && data.isReject}
                  />
                  <ProductAppStep
                    theme={type}
                    title={t('청약서 작성')}
                    active={data.step === taskStates.CONTRACT_WRITE}
                    isLast={false}
                    reject={data.step === taskStates.CONTRACT_WRITE && data.isReject}
                    child={
                      <div>
                        {
                          !data.subStep || data.subStep === 1 ?
                            t('Step 1/3 - 청약서 요청서를 작성해주세요.') :
                            data.subStep === 2 ?
                            t('Step 2/3 - 청약서 수령 대기중입니다.') :
                            t('Step 3/3 - 청약서를 제출해주세요.')
                        }
                        {
                          data.subStep === 3 ? (
                            <div style={{marginTop: '6px'}}>
                            <SmallBtn
                              theme={type}
                              type='secondary'
                              text={t('청약서 열람')}
                              onClick={() => push("PdfViewer", {title: t('청약서 열람'), url: data.contractFile[0]})}
                            />
                            </div>
                          ) : null
                        }
                        {
                          !data.isReject ? null : (
                            <div className='home-product-item-chip-red m13 radius4' style={{marginTop: "6px"}}>{data.rejectMention}</div>
                          )
                        }
                        {
                          !data.subStep || data.subStep === 1 ? (
                            <div style={{marginTop: '12px'}}>
                              <SmallBtn
                                theme={type}
                                type='primary'
                                text={t('청약서 요청')}
                                onClick={() => push("SubsRequestPage", {
                                  logo: product.logo, productName: product.name, setToast: setToast, taskId: data.id,
                                  refresh: req, productCode: data.productCode
                                })}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    }
                    inactiveChild={
                      (data.step !== taskStates.COMPLETE && data.step !== taskStates.CONTRACT) ? null :
                      <div>
                        <SmallBtn
                          theme={type}
                          type='secondary'
                          text={t('청약서 열람')}
                          onClick={() => push("PdfViewer", {title: t('청약서 열람'), url: data.contractFile[0]})}
                        />
                      </div>
                    }
                  />
                  <ProductAppStep
                    theme={type}
                    title={t('청약서 심사')}
                    active={data.step === taskStates.CONTRACT}
                    isLast={false}
                    reject={data.step === taskStates.CONTRACT && data.isReject}
                    child={
                      <div className='col-stretch gap12 w100'>
                        {
                          !data.subStep || data.subStep === 1 ?
                            t('Step 1/4 - 보험사에서 청약서를 수신중입니다.') :
                            data.subStep === 2 ?
                            t('Step 2/4 - 보험사에서 언더라이팅 중입니다.') :
                            data.subStep === 3 ?
                            t('Step 3/4 - 납부 후 송금증(카드 결제 시 전표)을 제출해주세요.') :
                            t('Step 4/4 - 보험사에서 증서를 발급하고 있습니다.')
                        }
                        {
                          true ?
                          <SmallBtn
                            theme={type}
                            type='primary'
                            text={t('송금증 업로드')}
                            onClick={() => push("RmtRequestPage", {
                              file: data.transferFiles ? data.transferFiles[0] : null,
                              bankInfo: product.bankInfo, taskId: data.id,
                              logo: product.logo, productName: product.name, setToast: setToast})}
                          /> : null
                        }
                      </div>
                    }
                    inactiveChild={
                      data.step !== taskStates.COMPLETE ? null :
                      <div>
                        <SmallBtn
                          theme={type}
                          type='secondary'
                          text={t('보험 증서 열람')}
                          onClick={() => push("PdfViewer", {title: '', url: ''})}
                        />
                      </div>
                    }
                  />
                  <ProductAppStep
                    theme={type}
                    title={t('청약 완료')}
                    active={data.step === taskStates.COMPLETE}
                    isLast={true}
                    reject={data.step === taskStates.COMPLETE && data.isReject}
                    child={undefined}
                  />
                </div>
              </div>
            {
              active[0] === 5 ?
              <div className={`col-stretch radius8 bgBG2 ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('우편 발송 안내')}
                />
                <div className={`product-app-info-text-wrap c1 m15 ${type}`}>
                  · {t('발송 주소 : HONGKONG CITY')}<br/>
                  · {t('해외 발송 시 주의 사항')}
                </div>
              </div> : null
            }
            {/* {
              active[0] === 0 ?
              <div className={`col-stretch radius8 bgBG2 ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('설계서 요청')}
                />
                <div className='product-app-info-btn-wrap'>
                  <MiddleBtn
                    theme={type}
                    type='secondary'
                    text={t('설계서 요청')}
                    icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                    iconLocation='right'
                    onClick={() => push("PlanRequestPage", {logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast})}
                  />
                </div>
              </div> :
              active[0] === 2 ?
              <div className={`col-stretch radius8 bgBG2 ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('청약서 요청')}
                />
                <div className='product-app-info-btn-wrap'>
                  <MiddleBtn
                    theme={type}
                    type='secondary'
                    text={t('청약서 요청')}
                    icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                    iconLocation='right'
                    onClick={() => push("SubsRequestPage", {logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast})}
                  />
                </div>
              </div> :
              active[0] === 3 ?
              <div className={`col-stretch radius8 bgBG2 ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('청약서 요청 재입력')}
                />
                <div className='product-app-info-btn-wrap'>
                  <MiddleBtn
                    theme={type}
                    type='secondary'
                    text={t('청약서 요청 재입력')}
                    icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                    iconLocation='right'
                    onClick={() => push("SubsRequestPage", {logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast})}
                  />
                </div>
              </div> : null
            }
            {
              active[0] === 4 ?
              <div className={`col-stretch radius8 bgBG2 ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('서류 제출')}
                />
                <div className='product-app-info-btn-wrap col-stretch gap20'>
                  <UploadInput
                    theme={type}
                    label={t('여권 사본')}
                    info={[t('· 유효기간 6개월 이상 / 서명 必 / 컬러본')]}
                    file={fileA}
                    setFile={setFileA}
                    handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileA(e)}
                    btn={{
                      text: t('업로드'),
                      onClick: () => openFileFinder('fileA')
                    }}
                    state={'normal'}
                    id={'fileA'}
                    validation={fileValidA !== '' ? false : true}
                    validMessage={t('파일이 업로드되지 않았습니다.')}
                  />
                  <div className={`col-divider bg15 ${type}`} />
                  <UploadInput
                    theme={type}
                    label={t('영문 주민등록 등본')}
                    info={[t('· 발급일자 3개월 이내 / 컬러본'), t('· 영문등본에 수익자 미기재 시 수익자 여권 첨부')]}
                    file={fileB}
                    setFile={setFileB}
                    handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileB(e)}
                    btn={{
                      text: t('업로드'),
                      onClick: () => openFileFinder('fileB')
                    }}
                    state={'normal'}
                    id={'fileB'}
                    validation={fileValidB !== '' ? false : true}
                    validMessage={t('파일이 업로드되지 않았습니다.')}
                  />
                  <div className={`col-divider bg15 ${type}`} />
                  <UploadInput
                    theme={type}
                    label={t('송금증 또는 카드 결제 시 전표')}
                    info={[t('· 사본 앞/뒤 포함')]}
                    file={fileC}
                    setFile={setFileC}
                    handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileC(e)}
                    btn={{
                      text: t('업로드'),
                      onClick: () => openFileFinder('fileC')
                    }}
                    state={'normal'}
                    id={'fileC'}
                    validation={fileValidC !== '' ? false : true}
                    validMessage={t('파일이 업로드되지 않았습니다.')}
                  />
                </div>
              </div> : null */}
            {/* } */}
          <div className='product-app-info-btns-wrap col-stretch gap16'>
            {
              active[0] === 4 ?
              <BigBtn
                theme={type}
                type='primary'
                text={t('저장')}
                onClick={() => saveClick()}
              /> : null
            }
            {/* <BigBtn
              theme={type}
              type='otherWarning'
              text={t('상품 신청 취소')}
              onClick={() => console.log('sdf')}
            /> */}
          </div>
            </div> :
            tabActive === 0 && data.step === taskStates.COMPLETE ?
            <div className='col-stretch gap12'>
              <div className={`product-info-period bgBG2 ${type}`}>
                <PaymentPeriod
                  theme={type}
                  period={(data.contractReqInfo.paymentDuration ?? 5) * 12}
                  periodPaid={data.contractReqInfo.useMonthlyPayment ? 1 : 12}
                />
              </div>
              <div className={`bgBG2 col-stretch ${type}`}>
                {
                  params.userType === 'seller' || params.userType === 'master' ?
                  <SectionTitle
                    theme={type}
                    title={t('주요 정보')}
                    switch={true}
                    switchType='triple'
                    switchTripleActive={switchTripleA}
                    switchTripleClick={switchToggleTripleA}
                  /> :
                  <SectionTitle
                    theme={type}
                    title={t('주요 정보')}
                    switch={true}
                    switchActive={switchA}
                    switchToggle={switchToggleA}
                  />
                }
                {
                  data.contractReqInfo.useMonthlyPayment ? (
                    <RowItem
                      theme={type}
                      label={t('월 납입금')}
                      text={
                        params.userType === 'seller' || params.userType === 'master' ?
                        `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD / 12), (switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit)).toLocaleString()} ${switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit}` :
                        `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD / 12), (switchA ? 'USD' : unit)).toLocaleString()} ${switchA ? 'USD' : unit}`
                      }
                      isLastItem={false}
                    />
                  ) : (
                    <RowItem
                      theme={type}
                      label={t('연 납입금')}
                      text={
                        params.userType === 'seller' || params.userType === 'master' ?
                        `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD), (switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit)).toLocaleString()} ${switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit}` :
                        `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD), (switchA ? 'USD' : unit)).toLocaleString()} ${switchA ? 'USD' : unit}`
                      }
                      isLastItem={false}
                    />
                  )
                }
                <RowItem
                  theme={type}
                  label={t('총 납입금')}
                  text={
                    params.userType === 'seller' || params.userType === 'master' ?
                    `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD), (switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit)).toLocaleString()} ${switchTripleA === 0 ? 'USD' : switchTripleA === 1 ? 'HKD' : unit}` :
                    `${returnExchange(Number(data.contractReqInfo.yearlyPaymentUSD), (switchA ? 'USD' : unit)).toLocaleString()} ${switchA ? 'USD' : unit}`
                  }
                  isLastItem={true}
                />
              </div>
            </div> :
            tabActive === 1 && data.step === taskStates.COMPLETE ?
            <div className='col-stretch gap12'>
              <div className={`bgBG2 col-stretch ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('계약 정보')}
                  switch={false}
                />
                <RowItem
                  theme={type}
                  label={t('계약자')}
                  text={data.contractReqInfo.enName}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('피보험자')}
                  text={data.contractReqInfo.beneficiaryInfoEnName}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('청약서 보기')}
                  icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                  onClick={() => push("PdfViewer", {title: t('청약서 열람'), url: data.contractFile[0]})}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('계약일')}
                  text={data.contractDate}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('신탁 명의 변경일')}
                  text={'-'}
                  isLastItem={true}
                />
              </div>
            </div> :
            (tabActive === 2 && data.step === taskStates.COMPLETE) || (tabActive === 1 && data.step !== taskStates.COMPLETE) ?
            <div className='col-stretch gap12'>
              {
                !product.promotion ? null : <img alt="promotion" src={product.promotion} />
              }
              {
                !product.features ? null : (
                  <div className={`bgBG2 col-stretch ${type}`}>
                    <SectionTitle
                      theme={type}
                      title={t('상품 특장점')}
                      switch={false}
                    />
                    <div style={{padding: '8px 0px'}}>
                      {
                        product.features.map((feature: any, i: number) => {
                          return (
                            <div className='row-center' style={{height: '63px', padding: '10px 20px'}} key={String(i)}>
                              <img alt="feature" src={feature.icon} style={{width: '43px', marginRight: '10px'}} />
                              <div className='m15'>{feature.explain}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                )
              }
              {
                !product.recommends ? null : (
                  <div className={`bgBG2 col-stretch ${type}`}>
                    <SectionTitle
                      theme={type}
                      title={t('이런 고객님께 추천')}
                      switch={false}
                    />
                    <div style={{padding: '18px 20px'}} className='col-start gap10'>
                    {
                      product.recommends.map((r: any, i: number) => {
                        const avatar = i === 0 ? reviewAvatar04 : i === 1 ? reviewAvatar05 : i === 2 ? reviewAvatar01 : reviewAvatar02;

                        return (
                          <div key={String(i)} className='row-center gap4 radius8' style={{border: 'solid 1px #E4E6ED', padding: '8px 12px 8px 8px'}}>
                            <img alt="avatar" src={avatar} style={{width: '33px'}}/>
                            <div className='m15'>{r}</div>
                          </div>
                        );
                      })
                    }
                    </div>
                  </div>
                )
              }
              <div className={`bgBG2 col-stretch ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('AI 수익 시뮬레이션')}
                  switch={false}
                />
                <div className='col-center' style={{padding: '8px 20px 24px 20px'}}>
                  <img alt="simulation" src={pSimulation} style={{width: '100%', marginBottom: '4px'}} />
                  <div className='m15' style={{textAlign: 'center', marginBottom: '20px'}}>‘연차별 예상 수익’을 확인해보세요</div>
                  <div style={{width: '100%'}}>
                    <div onClick={() => push('ProductSimulation', {applyFromSimulation: applyFromSimulation, productCode: params.productCode, taskId: data.id, req, setToast})} className='radius8' style={{padding: '0px 10px', width: '100%', height: '46px', justifyContent: 'space-between', backgroundColor: '#EBECF2', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <div style={{width: '24px', height: '24px'}} />
                      <div className='m15'>{t('시뮬레이션')}</div>
                      <img alt="arrow" src={arrowRightItemLight} style={{width: '24px', height: '24px'}}/>
                    </div>
                  </div>
                </div>
              </div>
              {
                reviewData.filter((r) => r.productId === params.productCode).length === 0
                  ? null : (
                    <div className={`bgBG2 col-stretch ${type}`}>
                      <SectionTitle
                        theme={type}
                        title={t('고객 후기')}
                        switch={false}
                      />
                      <div className='home-review-list' onClick={() => push('ReviewListsPage', {productId: params.productCode})}>
                        {
                          reviewData.filter((r) => r.productId === params.productCode).map((review: any, i: number) => {
                            return (
                              <ReviewCard key={String(i)} avatar={review.avatar} reviewer={review.reviewer} star={review.star} content={review.abstract} />
                            );
                          })
                        }
                      </div>
                      <MiddleBtn
                        theme={'light'}
                        type='other'
                        text={t('더 보기')}
                        onClick={() => {
                          push('ReviewListsPage', {productId: params.productCode});
                        }}
                      />
                    </div>
                  )
              }
              <div className={`bgBG2 col-stretch ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('상품 정보')}
                  switch={true}
                  switchActive={switchB}
                  switchToggle={switchToggleB}
                  isSales={params.userType === 'seller' || params.userType === 'master'}
                />
                <RowItem
                  theme={type}
                  label={t('납입기간')}
                  text={product.durations.map((d: any) => `${d}${t('년')}`).join(' / ')}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('보험기간')}
                  text={product.benefitTerm}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('가입 연령')}
                  text={product.availableAge}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('최소 보험료 (연납기준)')}
                  text={
                    params.userType === 'seller' || params.userType === 'master' ?
                    `${returnExchange(product.minForYear, (switchTripleB === 0 ? 'USD' : switchTripleB === 1 ? 'HKD' : unit)).toLocaleString()} ${switchTripleB === 0 ? 'USD' : switchTripleB === 1 ? 'HKD' : unit}` :
                    `${returnExchange(product.minForYear, (switchB ? 'USD' : unit)).toLocaleString()} ${switchB ? 'USD' : unit}`
                  }
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('월납 가능 여부')}
                  text={
                    product.allowMonthlyPaid ? t('가능') : t('불가능')
                  }
                  isLastItem={false}
                />
                {
                  product.fulfillment !== ''
                    ? (
                      <RowItem
                      theme={type}
                      label={t('이행률')}
                      text={t('이행률 확인')}
                      icon={type === 'dark' ? outlinkDark : outlinkLight}
                      isLink={product.fulfillment}
                      isLastItem={false}
                    />  
                    ) : null
                }
                <RowItem
                  theme={type}
                  label={t('판매사 제공 정보')}
                  text={t('판매사 사이트')}
                  icon={type === 'dark' ? outlinkDark : outlinkLight}
                  isLink={product.homepageLink}
                  isLastItem={true}
                />  
              </div>
              {/* <div className={`bgBG2 col-stretch ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('납입 계좌 정보')}
                  switch={false}
                />
                <RowItem
                  theme={type}
                  label={t('은행명')}
                  text={product.bankInfo.bankName}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('은행주소')}
                  text={product.bankInfo.bankAddress}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('은행코드')}
                  text={product.bankInfo.bankCode}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('계좌명')}
                  text={product.bankInfo.bankAccountName}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('계좌번호')}
                  text={
                    <div style={{textAlign: 'right'}}>
                      {
                        product.bankInfo.bankAccount.map((ac: any, i: number) => {
                          return (
                            <div key={String(i)}>
                              {`${ac.addr} (${ac.curr})`}
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('Swift Code')}
                  text={product.bankInfo.swiftCode}
                  isLastItem={true}
                />
              </div> */}
              <div className={`bgBG2 col-stretch ${type}`}>
                <SectionTitle
                  theme={type}
                  title={t('회사 정보')}
                  switch={false}
                />
                <RowItem
                  theme={type}
                  label={t('회사명')}
                  text={product.company}
                  isLastItem={false}
                />
                <RowItem
                  theme={type}
                  label={t('홈페이지')}
                  text={product.companyHomepageLabel}
                  icon={type === 'dark' ? outlinkDark : outlinkLight}
                  isLink={product.companyHomepage}
                  isLastItem={false}
                /> 
                <RowItem
                  theme={type}
                  label={t('시가총액')}
                  text={product.marketCap}
                  isLink={product.companyStock}
                  isLastItem={false}
                />
                <BlockItem
                  theme={type}
                  text={''}
                  isLastItem={false}
                  children={(
                    <div className='col-start gap8'>
                      <div className='row-center gap6'>
                        <div className='m16'>{t('신용평가등급')}</div>
                        <img style={{width: '16px', height: '16px'}} src="/icon/avif/question.png" alt="q" onClick={() => push('CommonGuide', {type: 'companyCredit'})} />
                      </div>
                      <div className='m13 c7 light'>- {t('평가기관: 세계 3대 신용평가사 및 미국의 국가 인정 통계 평가기관')}</div>
                      <CreditTable items={product.creditTable} />
                    </div>
                  )}
                />
                <BlockItem
                  theme={type}
                  text={product.companyInfo}
                  isLastItem={true}
                />
              </div>
            </div> : null
          }
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductInfoPage;