import React from 'react';
import SectionTitle from '../common/section_title';
import { useTranslation } from 'react-i18next';
import MiddleBtn from 'components/btn/middle_btn';
import ReviewCard from './review_card';
import reviewData from 'configs/review_data';
import { useFlow } from 'configs/stackflow';

interface Props{
  theme: string;
}

const HomeReviewSection:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const {push} = useFlow();

  return (
    <div className={`home-product col-stretch bgBG2 bg2Shadow ${props.theme}`}>
      <SectionTitle
        theme={props.theme}
        title={t('고객 후기')}
        switch={false}
      />
      <div>
      <div className='home-review-list' onClick={() => push('ReviewListsPage', {productId: 'all'})}>
        {
          reviewData.map((review: any, i: number) => {
            return (
              <ReviewCard key={String(i)} avatar={review.avatar} reviewer={review.reviewer} star={review.star} content={review.abstract} />
            );
          })
        }
      </div>
      <MiddleBtn
          theme={props.theme}
          type='other'
          text={t('더 보기')}
          onClick={() => {
            push('ReviewListsPage', {productId: 'all'});
          }}
        />
      </div>
    </div>
  );
};

export default HomeReviewSection;
