import React from 'react';
import BigBtn from '../btn/big_btn';

interface Props{
  theme: string;
  text: string;
  btnY: any; // name: string, onClick: () => void
  btnN: any; // name: string, onClick: () => void
}

const ModalYN:React.FC<Props> = (props) => {
  return (
    <div className={`modal-overlay zi-modal bgBlack05 ${props.theme}`}>
      <div className={`modal bgBG2 ${props.theme} modal-shadow`}>
        <div className={`modal-text m17 c1 ${props.theme}`}>{props.text}</div>
        <div className='modal-yn-btn-wrap'>
          <BigBtn
            theme={props.theme}
            type='secondary'
            text={props.btnN.name}
            onClick={props.btnN.onClick}
            fill={true}
            withOtherBtn={true}
          />
          <BigBtn
            theme={props.theme}
            type='primary'
            text={props.btnY.name}
            onClick={props.btnY.onClick}
            fill={true}
            withOtherBtn={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalYN;