import React, { Dispatch, SetStateAction } from 'react';
import MiddleBtn from '../btn/middle_btn';
import { removeCircleDark, removeCircleLight } from '../../configs/icon_route';

interface Props{
  theme: string;
  label: string;
  subLabel?: string;
  info: string;
  inputValue: string[];
  onChange: (e:React.ChangeEvent<HTMLInputElement>, idx:number) => void;
  placeholder: string;
  btn: any; // type, text, disabled, icon, onClick
  validation: boolean; // true: 정상, false: 에러
  validMessage?: string;
  inputType?: string;
  removeInput: (idx: number) => void;
  focus: boolean[];
  setFocus: Dispatch<SetStateAction<boolean[]>>;
}

const MultiTextInput:React.FC<Props> = (props) => {
  const onFocus = (idx: number) => {
    let arr = props.focus;
    arr[idx] = true;
    props.setFocus([...arr]);
  };

  const onBlur = (idx: number) => {
    let arr = props.focus;
    arr[idx] = false;
    props.setFocus([...arr]);
  };

  return (
    <div className='col-stretch gap12'>
      <div className='col-start gap9'>
        <div className='col-start gap6'>
        <div className='text-input-labels-wrap'>
          <div className={`m15 c1 ${props.theme}`}>
            {props.label}
          </div>
          {
            props.subLabel ?
            <div className={`m13 c7 ${props.theme}`}>{props.subLabel}</div> : null
          }
        </div>
          <div className={`m15 c7 ${props.theme}`}>
            {props.info}
          </div>
          {
            !props.validation ?
            <div className={`m15 colorRed ${props.theme}`}>
              {props.validMessage}
            </div> : null
          }
        </div>
        <div className='col-stretch gap12 w100'>
          {
            props.inputValue.map((value: string, idx: number) => {
              return (
                <div className='row-center' key={String(idx)}>
                  <div className={`text-input-wrap ${props.focus[idx] ? 'borderC1' : 'borderC13'} bgBG2 ${props.theme}`}>
                    <input
                      className={`m17 c1 ${props.theme} text-input-area`}
                      value={value}
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.onChange(e, idx)}
                      type={!props.inputType ? 'text' : props.inputType}
                      placeholder={props.placeholder}
                      onFocus={() => onFocus(idx)}
                      onBlur={() => onBlur(idx)}
                    />
                  </div>
                  {
                    idx !== 0 ?
                    <div className='multi-text-input-remove' onClick={() => props.removeInput(idx)}>
                      <img src={props.theme === 'dark' ? removeCircleDark : removeCircleLight} alt='remove input' />
                    </div> : null
                  }
                </div>
              );
            })
          }
        </div>
      </div>
      <MiddleBtn
        theme={props.theme}
        type={props.btn.type}
        text={props.btn.text}
        disabled={props.btn.disabled}
        icon={props.btn.icon}
        iconLocation='left'
        onClick={() => props.btn.onClick()}
      />
    </div>
  );
};

export default MultiTextInput;