import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  type: 'light',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeSwitch: (state, actrion) => {
      if (state.type === 'dark') {
        state.type = 'light';
      } else {
        state.type = 'dark';
      }
    },
  },
});

export const {themeSwitch} = themeSlice.actions;

export default themeSlice.reducer;
