import { getApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";

const db = {
  getKrSellerCol: () => {
    const db = getFirestore(getApp());
    const col = collection(db, 'kr_sellers'); 
    return col;
  },
  getKrCustomerCol: () => {
    const db = getFirestore(getApp());
    const col = collection(db, 'kr_users'); 
    return col;
  },
  getKrTaskCol: () => {
    const db = getFirestore(getApp());
    const col = collection(db, 'kr_user_tasks'); 
    return col;
  },
};

export default db;
