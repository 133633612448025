import { useTranslation } from "react-i18next";

const CreditExpTable = () => {
  const {t} = useTranslation();
  return (
    <div className='table-wrapper radius8 borderC14 light'>
      <div className='table-header-1 table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">{t('설명')}</div>
        <div className="table-credit-col1">S&P</div>
        <div className="table-credit-col1">Moody's</div>
        <div className="table-credit-col1 last">Fitch</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Extremely strong</div>
        <div className="table-credit-col1">AAA</div>
        <div className="table-credit-col1">Aaa</div>
        <div className="table-credit-col1 last">AAA</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Very strong</div>
        <div className="table-credit-col1">AA</div>
        <div className="table-credit-col1">Aa</div>
        <div className="table-credit-col1 last">AA</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Strong</div>
        <div className="table-credit-col1">A</div>
        <div className="table-credit-col1">A</div>
        <div className="table-credit-col1 last">A</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Adequate</div>
        <div className="table-credit-col1">BBB</div>
        <div className="table-credit-col1">Baa</div>
        <div className="table-credit-col1 last">BBB</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Less vulnerable</div>
        <div className="table-credit-col1">BB</div>
        <div className="table-credit-col1">Ba</div>
        <div className="table-credit-col1 last">BB</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">More vulnerable</div>
        <div className="table-credit-col1">B</div>
        <div className="table-credit-col1">B</div>
        <div className="table-credit-col1 last">B</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Currently vulnerable</div>
        <div className="table-credit-col1">CCC</div>
        <div className="table-credit-col1">Caa</div>
        <div className="table-credit-col1 last">CCC</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center'>
        <div className="table-credit-col4">Currently highly vulnerable</div>
        <div className="table-credit-col1">CC</div>
        <div className="table-credit-col1">-</div>
        <div className="table-credit-col1 last">CC</div>
      </div>
      <div className='table-credit-row-wrapper m13 row-center last'>
        <div className="table-credit-col4">Default</div>
        <div className="table-credit-col1">SD/D</div>
        <div className="table-credit-col1">Ca</div>
        <div className="table-credit-col1 last">D</div>
      </div>
    </div>
  );
};

export default CreditExpTable;
