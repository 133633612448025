import db from "configs/db";
import { doc, updateDoc } from "firebase/firestore";

const saveContractReqInfo = async (taskId: string, data: any, confirm = false) => {
  const {
    yearlyPaymentUSD,
    useMonthlyPayment = false,
    paymentMethod,
    cardInfoType,
    cardInfoNo,
    cardInfoValidDur,
    invoiceInfo,
    invoiceEtc,
    insuranceContractDeliveryAddrType,
    enName,
    gender,
    birth,
    isSmoke,
    passportNo,
    address,
    zipcode,
    email,
    companyInfoCeoName,
    companyInfoBusType,
    companyInfoAddr,
    companyInfoJobDuty,
    companyInfoDuration,
    assetInfoAnnualSalary,
    assetInfoTotalAsset,
    beneficiaryInfoEnName,
    beneficiaryInfoPNo,
    beneficiaryInfoRelations,
    passportFile,
    rrFile,
    paymentDuration,
  } = data;

  const updatedValue: any = {
    contractReqInfo: {
      yearlyPaymentUSD,
      useMonthlyPayment,
      paymentMethod,
      cardInfoType,
      cardInfoNo,
      cardInfoValidDur,
      invoiceInfo,
      invoiceEtc: invoiceEtc ?? '',
      insuranceContractDeliveryAddrType,
      enName,
      gender,
      birth,
      isSmoke,
      passportNo,
      address,
      zipcode,
      email,
      companyInfoCeoName,
      companyInfoBusType,
      companyInfoAddr,
      companyInfoJobDuty,
      companyInfoDuration,
      assetInfoAnnualSalary,
      assetInfoTotalAsset,
      beneficiaryInfoEnName,
      beneficiaryInfoPNo,
      beneficiaryInfoRelations,
      passportFile,
      rrFile,
      paymentDuration,
    },
  };

  if (confirm) {
    updatedValue.subStep = 2;
    updatedValue.isReject = false;
    updatedValue.rejectMention = '';
    updatedValue.forIfa = true;
  }

  const proms = [
    updateDoc(doc(db.getKrTaskCol(), taskId), updatedValue),
  ];

  await Promise.all(proms);
};

export default saveContractReqInfo;
