import { chat, flagCircleKr, homeAppbarLogo } from 'configs/icon_route';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ChannelService from 'configs/channel_service';

const HomeAppbar = () => {
  const { type } = useSelector((state:RootState) => state.theme);

  return (
    <div className={`home-appbar row-center jtf-between bgBG2 ${type}`}>
      <div className='home-appbar-logo-wrap'>
        <img src={homeAppbarLogo} alt='insurein logo' />
      </div>
      <div className='row-stretch'>
        <div className='home-appbar-icon-wrap' onClick={() => ChannelService.showMessenger()}>
          <img src={chat} alt='chat' />
          <div className={`home-appbar-icon-dot bgRed ${type}`} />
        </div>
        <div className='home-appbar-flag-wrap'>
          <img src={flagCircleKr} alt='flag' />
        </div>
      </div>
    </div>
  );
};

export default HomeAppbar;