import { reviewAvatar01, reviewAvatar02, reviewAvatar03 } from "./icon_route";

const reviewData = [
  {
    avatar: reviewAvatar01,
    productId: 'AXA_ULTIMATE',
    productDetails: ['연납 $20,000', '5년 선납'],
    reviewer: '30대 · 남성 · 전문의',
    star: 5,
    abstract: '과거에 가입하고 유지중인 변액보험이 있는데 10년이 지났는데도 원금이 안되어 있어서 어떻게 해야할지 고민이던 차에 해외 보험이 납입기간도 짧아서 부담이 없고 정말 원금이 이렇게 2배, 3배 이상의 수익이 장기적으로 가능하다면 하지 않을 이유가 없다고 생각했어요. 내가 추가로 어떤 상품에 가입해서 추가적인 저축을 하는 것이 아니라 단지 금고에 있는 돈을 다른 금고로 이동하는 것이라고 생각하니 결정하는데 있어 도움이 되었던 것 같아요.',
    qa: [
      {q: '상품의 어떤 점이 마음에 들어 가입 결정을 하게 되셨나요?', a: '과거에 가입하고 유지중인 변액보험이 있는데 10년이 지났는데도 원금이 안되어 있어서 어떻게 해야할지 고민이던 차에 해외 보험이 납입기간도 짧아서 부담이 없고 정말 원금이 이렇게 2배, 3배 이상의 수익이 장기적으로 가능하다면 하지 않을 이유가 없다고 생각했어요. 내가 추가로 어떤 상품에 가입해서 추가적인 저축을 하는 것이 아니라 단지 금고에 있는 돈을 다른 금고로 이동하는 것이라고 생각하니 결정하는데 있어 도움이 되었던 것 같아요.'},
      {q: '해외상품인데 불안한 점은 없으셨나요?', a: '물론 처음에는 의심도 되고 불안했어요. 우선 언어가 자유롭지 않다보니 불편하지 않을까란 생각도 했고요. 처음 들어보는 보험회사라 나름 검색을 해보기도 했습니다. 글로벌 Top10 보험사라고 하는데 들어본적이 없으니까요. 하지만 정말 미국 포브스 글로벌 2000대 기업 중 100위 안에 있는(코카콜라 100위) 기업이라는 것을 알게 되었고 글로벌 Top10 보험사라는 것과 자산규모 등이 확인되니 안심이 좀 되더라고요. 그리고 언어야 웹에서 번역이 다 자동으로 되이 큰 문제는 아니라고 생각했어요.'},
      {q: '미국 달러(USD)로 납입하고 투자되는 상품인데 환율이 떨어졌을 때 환차손에 대한 걱정은 없으셨나요?', a: '물론 저도 그 생각을 하지 않은 것은 아닙니다. 그런데 환차손을 볼 수도 있지만 환차익을 볼 수도 있잖아요. 그리고 생각을 해봤는데요. 환율은 매일 오르고 내리고를 반복하는데 우선 제가 납입하는 기간은 5년이니까 그 기간동안 납입했을 때의 환율과 10년 20년 후에 찾을 때 환율이 떨어지면 어떡하지란 생각으로 30%정도 하락했을 때를 봤더니 환률이 떨어진다해도 손해는 아니더라고요.'},
    ],
  },
  {
    avatar: reviewAvatar02,
    productId: 'ZURICH_01',
    productDetails: ['월납 $500', '5년 납'],
    reviewer: '40대 · 여성 · 대기업 과장',
    star: 5,
    abstract: '자녀에게 적립식으로 목돈을 만들어 물려주고 싶은 생각이 있었차에 이 상품을 알게 되었습니다. 무엇보다도 자녀가 둘인 저에게는 두개의 상품을 가입하자니 조금은 부담이 되었는데 증권 분할 기능이 있다는 것을 알고 정말 좋은 기능이라고 생각했습니다. 그리고 무엇보다 피보험자가 제한없이 변경되는 것이 정말 큰 장점이라고 생각이 되었어요. 지금은 아이들이 미성년자라서 제 이름으로 계약을 했지만 추후 자녀의 이름으로 모두 변경이 가능하다고 하니 두 아이의 이름으로 증권을 분할하여 물려줄 생각으로 가입을 결정하게 되었습니다. 해외상품인데 불안한 점은 없으셨나요?: 불안했죠. 주위 지인에게 물어봐도 이 상품을 아는 사람이 없더라고요. 아무래도 남들이 잘 모르는 상품이다보니 조금 더 알아보고 하자는 생각을 하게 되었습니다. 그래서 해당 회사의 홈페이지에 들어가서 상품 브로셔를 직접 살펴보기도 하고 했습니다. 모두 영어로 되어 있었는데 번역 프로그램을 통해 꼼꼼하게 살펴봤어요. 사실 가입을 하는 방법도 몰랐는데 인슐린에서 제공하는 서비스를 통해 비교적 쉽게 직접 가입할 수 있게 되었네요. 미국 달러(USD)로 납입하고 투자되는 상품인데 환율이 떨어졌을 때 환차손에 대한 걱정은 없으셨나요?: 미국 달러가 위기의 상황에서는 항상 올라간다는 것을 알고 있습니다.',
    qa: [
      {q: '상품의 어떤 점이 마음에 들어 가입 결정을 하게 되셨나요?', a: '자녀에게 적립식으로 목돈을 만들어 물려주고 싶은 생각이 있었차에 이 상품을 알게 되었습니다. 무엇보다도 자녀가 둘인 저에게는 두개의 상품을 가입하자니 조금은 부담이 되었는데 증권 분할 기능이 있다는 것을 알고 정말 좋은 기능이라고 생각했습니다. 그리고 무엇보다 피보험자가 제한없이 변경되는 것이 정말 큰 장점이라고 생각이 되었어요. 지금은 아이들이 미성년자라서 제 이름으로 계약을 했지만 추후 자녀의 이름으로 모두 변경이 가능하다고 하니 두 아이의 이름으로 증권을 분할하여 물려줄 생각으로 가입을 결정하게 되었습니다.'},
      {q: '해외상품인데 불안한 점은 없으셨나요?', a: '불안했습니다. 주위 지인에게 물어봐도 이 상품을 아는 사람이 없더라고요. 아무래도 남들이 잘 모르는 상품이다보니 조금 더 알아보고 하자는 생각을 하게 되었습니다. 그래서 해당 회사의 홈페이지에 들어가서 상품 브로셔를 직접 살펴보기도 하고 했습니다. 모두 영어로 되어 있었는데 번역 프로그램을 통해 꼼꼼하게 살펴봤어요.'},
      {q: '미국 달러(USD)로 납입하고 투자되는 상품인데 환율이 떨어졌을 때 환차손에 대한 걱정은 없으셨나요?', a: '미국 달러가 위기의 상황에서는 항상 올라간다는 것을 알고 있습니다. 기축통화이니만큼 오히려 우리나라 원화보다 더 안전하지 않을까요? 환율이 떨어지면 외국에서 달러로 쓰겠다는 생각을 하니 그렇게 제게는 환율에 대한 걱정은 없었던 것 같습니다.'},
    ],
  },
  {
    avatar: reviewAvatar03,
    productId: 'ZURICH_01',
    productDetails: ['월납 $1,000', '5년 납'],
    reviewer: '40대 · 여성 · 약사',
    star: 5,
    abstract: '5년이란 납입을 마친후 거의 10년마다 자산이 두 배로 늘어난다면 노후 자산으로 이것만큼 좋은 상품이 없다란 생각이 들었어요. 내가 원하는 시점에 인출도 할 수 있고 자동으로 설정해 놓으면 매월 연금처럼 받을 수 있다는 점도 무척 마음에 들어 선택하게 되었어요.',
    qa: [
      {q: '상품의 어떤 점이 마음에 들어 가입 결정을 하게 되셨나요?', a: '5년이란 납입을 마친후 거의 10년마다 자산이 두 배로 늘어난다면 노후 자산으로 이것만큼 좋은 상품이 없다란 생각이 들었어요. 내가 원하는 시점에 인출도 할 수 있고 자동으로 설정해 놓으면 매월 연금처럼 받을 수 있다는 점도 무척 마음에 들어 선택하게 되었어요. 사실 은퇴후 외국에서 살아보고 싶은 생각도 많았는데 그런 부분에 있어 달러로 받을 수 있다는 것도 좋았어요.'},
      {q: '해외상품인데 불안한 점은 없으셨나요?', a: '글로벌 시대에 모든 것을 해외에서 직접 구입하는 시대임에도 금융상품에 대해서는 만큼은 저 역시 보수적으로 처음엔 생각했어요. 수익적인면이나 회사의 역사나 안정성을 보았을 때 해외금융상품을 선택하는 것이 더 현명한 선택이라고 생각이 들었고 생각보다는 가입을 간편하게 직접할 수 있어 결정을 하게 되었어요.'},
      {q: '미국 달러(USD)로 납입하고 투자되는 상품인데 환율이 떨어졌을 때 환차손에 대한 걱정은 없으셨나요?', a: '해외에 가면 달러는 받아주는데 원화는 안받아주지 않나요? 원화가 국제 결제통화가 아니라서 그런것도 있지 않나요? 미국 달러가 기축통화이니 저는 별로 신경쓰지 않았던 것 같아요. 오히려 일부는 달러를 가지고 있는 것이 좋다는 생각도 있었고요. 제 미래 노후자금을 모두 달러로 저축하는 것은 아니니까요.'},
    ],
  },
];

export default reviewData;
