import { reviewFillStar } from 'configs/icon_route';
import React from 'react';

interface Props{
  avatar: string;
  reviewer: string;
  star: number;
  content: string;
}

const ReviewCard:React.FC<Props> = (props) => {
  return (
    <div className='review-card-wrapper'>
      <div className='review-card-reviewer-wrapper'>
        <img alt="review-avatar" src={props.avatar} />
        <div>
          <div className='reviewer-label'>{props.reviewer}</div>
          <div style={{height: '12px', display: 'flex', flexDirection: 'row'}}>
            {
              new Array(props.star).fill(1).map((v, i) => {
                return <img alt="fill-star" src={reviewFillStar} className='star' key={String(i)} />
              })
            }
          </div>
        </div>
      </div>
      <div className='review-card-abstract'>
        {props.content}
      </div>
    </div>
  );
};

export default ReviewCard;
