import React from 'react';
import SwitchTextBtn from './switch_text_btn';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import SwitchTextBtnTriple from './switch_text_btn_triple';

interface Props{
  theme: string;
  title: any;
  switch?: boolean;
  switchActive?: boolean;
  switchToggle?: () => void;
  switchType?: string;
  switchTripleActive?: number;
  switchTripleClick?: (n: number) => void;
  isSales?: boolean;
};

const SectionTitle:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const {unit} = useSelector((state:RootState) => state.exchange);
  return (
    <div className={`section-title borderDashBottom ${props.theme}`}>
      <div className={`c5 m15 ${props.theme}`}>{props.title}</div>
      {
        props.switch && props.switchType === 'triple' ?
        <SwitchTextBtnTriple
          theme={props.theme}
          active={props.switchTripleActive ? props.switchTripleActive : 0}
          onClick={props.switchTripleClick ? props.switchTripleClick : undefined}
          text={['USD', 'HKD', 'KRW']}
          size='small'
        /> :
        props.switch ?
        <SwitchTextBtn
          theme={props.theme}
          active={props.switchActive ? props.switchActive : false}
          onClick={props.switchToggle ? props.switchToggle : undefined}
          leftText={props.isSales ? 'HKD' : 'USD'}
          rightText={t('KRW')}
          size='small'
        /> : null
      }
    </div>
  );
};

export default SectionTitle;