import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import btnLoadingDark from './btn-loading-dark.json';
import btnLoadingLight from './btn-loading-light.json';

interface Props {
  theme: string;
  type: string; // secondary, other, primary
  text: string;
  disabled?: boolean;
  underline?: boolean;
  icon?: string;
  iconLocation?: string; //left, right
  onClick: () => void;
  loading?: boolean;
  fill?: boolean;
}

const MiddleBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const btnClick = (e: React.MouseEvent) => {
    if (props.disabled) {
      return;
    } else {
      e.stopPropagation();
      props.onClick();
    }
  };

  return (
    <div
      onClick={(e: React.MouseEvent) => btnClick(e)}
      className={`middle-btn ${props.iconLocation === 'right' ? ' right' : ''} ${
        props.type === 'primary' ? 'bgSDG' : props.type === 'secondary' ? 'bg15' : ''
      } ${props.theme} ${props.disabled ? 'disabled' : ''} ${props.fill ? 'flexFill' : ''}`}
    >
      {props.loading ? (
        <Lottie
          animationData={props.theme === 'dark' ? btnLoadingDark : btnLoadingLight}
          style={{ width: 20, height: 20 }}
        />
      ) : (
        <>
          {props.icon && props.iconLocation !== 'right' ? (
            <img src={props.icon} alt={'btn icon'} />
          ) : props.icon && props.iconLocation === 'right' ? (
            <div style={{ width: 24 }} />
          ) : null}
          <div
            className={`${props.underline ? 'r15' : 'b15'} ${
              props.type === 'primary'
                ? props.theme === 'light'
                  ? 'slg'
                  : 'slg'
                : props.type === 'secondary'
                ? 'c5'
                : 'c7'
            } ${props.theme} ${props.underline ? 'underline' : ''}`}
          >
            {props.text}
          </div>
          {props.icon && props.iconLocation === 'right' ? <img src={props.icon} alt={'btn icon'} /> : null}
        </>
      )}
    </div>
  );
};

export default MiddleBtn;
