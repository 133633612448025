import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  location: 'kr',
};

export const locationSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    locationChange: (state, action) => {
      state.location = action.payload.location;
    },
  },
});

export const {locationChange} = locationSlice.actions;

export default locationSlice.reducer;
