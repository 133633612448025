import React from 'react';
import ReactDOM from 'react-dom/client';

import "@stackflow/plugin-basic-ui/index.css";
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';

import './stylesheets/common.scss';
import './stylesheets/component.scss';

import store from './store/store';
import i18n from './locales';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
        <App />
    </Provider>
  </I18nextProvider>
);

reportWebVitals();
