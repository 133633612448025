import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import MiddleBtn from '../btn/middle_btn';
import { useTranslation } from 'react-i18next';
import { documentDark, documentLight, uploadDark, uploadLight, uploadStateCancel, uploadStateCheck } from '../../configs/icon_route';
import { List } from 'immutable';

interface Props{
  theme: string;
  label: string;
  info: string[];
  file?: any;
  setFile?: Dispatch<SetStateAction<File>>;
  handleFile?: (e:React.ChangeEvent<HTMLInputElement>) => void;
  btn: any;
  state: string; // normal, processing, reject, complete
  id: string;
  validation: boolean;
  validMessage: string;
  btnType?: string;
}

const UploadInput:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className={`upload-input ${props.theme}`}>
      <div className='upload-input-top'>
        <div className={`upload-input-label-wrap`}>
          <div className={`m15 c1 ${props.theme}`}>
            {props.label}
          </div>
          {
            props.state !== 'normal' ?
            <div className='upload-input-state-wrap'>
              {
                props.state === 'processing' ? null :
                props.state === 'reject' ? <img className='upload-input-state-icon' src={uploadStateCancel} alt='rejected' /> :
                props.state === 'complete' ? <img className='upload-input-state-icon' src={uploadStateCheck} alt='completed' /> : null
              }
              <div className={`upload-input-state ${props.state === 'processing' ? 'c7' : props.state === 'reject' ? 'colorRed' : props.state === 'complete' ? 'colorGreen' : ''} m13`}>
                {
                  props.state === 'processing' ? t('검토 중') :
                  props.state === 'reject' ? t('다시 제출') :
                  props.state === 'complete' ? t('제출 완료') : ''
                }
              </div>
            </div> : null
          }
        </div>
        <div className='col-start gap4'>
          {
            props.info.map((item: string, idx: number) => {
              return (<div className={`upload-input-info r15 c7 ${props.theme}`} key={idx}>{item}</div>);
            })
          }
          {
            !props.validation ? <div className={`r15 colorRed ${props.theme}`}>{props.validMessage}</div> : null
          }
        </div>
      </div>
      {
        props.file && props.file !== List([]) ?
        <div className={`upload-input-file c1 r15 border ${props.theme}`}>
          <img src={props.theme === 'dark' ? documentDark : documentLight} alt='uploaded file' />
          <div className='els1'>
            {props.file.name}
          </div>
        </div> : null
      }
      <MiddleBtn
        theme={props.theme}
        icon={props.btnType && props.btnType === 'primary' ? uploadDark : props.theme === 'dark' ? uploadDark : uploadLight}
        type={props.btnType ? props.btnType : 'secondary'}
        text={props.btn.text}
        onClick={() => props.btn.onClick()}
        disabled={
          props.state === 'processing' ? true :
          props.state === 'reject' ? false :
          props.state === 'complete' ? true : false
        }
        loading={props.file && props.file.name !== '' ? false : true}
      />
      <input type="file" onChange={props.handleFile} style={{display: 'none'}} id={props.id} />
    </div>
  );
};

export default UploadInput; 