import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  theme: string;
  items: string[];
  setActive: Dispatch<SetStateAction<number>>;
  active: number;
  backgroundColor?: string;
};

const TabMenu:React.FC<Props> = (props) => {
  return (
    <div className={`row-stretch ${props.backgroundColor ? props.backgroundColor : ''} ${props.theme}`}>
      {
        props.items.map((item: string, idx: number) => {
          return (
            <div
              key={String(idx)}
              className={`tab-menu-item m17 ${props.active === idx ? 'c1 borderBottomC1' : 'c9'} ${props.active === idx ? 'active' : ''} ${props.theme}`}
              onClick={() => props.setActive(idx)}
            >
              {item}
            </div>
          );
        }) 
      }
    </div>
  );
};

export default TabMenu;