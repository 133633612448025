import React from 'react';
import { arrowDownBoldLight } from '../../configs/icon_route';

interface Props{
  theme: string;
  type?: string; // flag
  item?: string[];
  flagItem?: {name: string, flag: string}[];
  selectedItem: number;
  onClick: () => void;
  placeholder: string;
  label?: string;
}

const DropdownInput:React.FC<Props> = (props) => {

  return (
    <div className={`col-stretch ${props.theme} gap9`} onClick={props.onClick}>
      {
        props.label ?
        <div className={`dropdown-label c1 m15 ${props.theme}`}>{props.label}</div> : null
      }
      <div className={`dropdown-item-row border bg17 row-center radius8  ${props.theme}`}>
        {
          props.type === 'flag' && props.flagItem ?
          <div className='row-center gap12'>
            <img className={`dropdown-flag borderC14 ${props.theme}`} src={props.flagItem[props.selectedItem].flag} alt='flag' />
            <div className={`dropdown-item m17 ${props.theme} ${props.selectedItem || props.selectedItem === 0 ? 'c1' : 'c8'}`}>
              {props.selectedItem || props.selectedItem === 0 ? props.flagItem[props.selectedItem].name : props.placeholder}
            </div>
          </div> :
          props.type !== 'flag' && props.item?
          <div className={`dropdown-item m17 ${props.theme} ${props.selectedItem || props.selectedItem === 0 ? 'c1' : 'c8'}`}>
            {props.selectedItem || props.selectedItem === 0 ? props.item[props.selectedItem] : props.placeholder}
          </div> : null
        }
        <img className='dropdown-item-icon' src={arrowDownBoldLight} alt='dropdown arrow' />
      </div>
    </div>
  );
};

export default DropdownInput;