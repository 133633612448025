import { useTranslation } from "react-i18next";

const IRRTable = ({rows}: any) => {
  const {t} = useTranslation();
  return (
    <div className='table-wrapper radius8 borderC14 light'>
      <div className='table-header-1 m13'>{t('예상 평균 연이율')} (IRR)</div>
      {
        rows.map((row: any, i: number) => {
          return (
            <div key={String(i)}>
              <div className='table-row-2'>
                <div className='col-center label m13'>
                  <div>{row.durationLabel}</div>
                </div>
                <div className='content m13'>{row.ratioLabel}</div>
              </div>
              {i !== rows.length - 1 ? <div className='table-divider'/> : null}
            </div>
          );
        })
      }
    </div>
  );
};

export default IRRTable;
