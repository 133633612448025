import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {doc, getFirestore, updateDoc, } from 'firebase/firestore';

const updateFCMToken = async (token: string) => {
  const u = getAuth(getApp()).currentUser;

  if (u === null) {
    return;
  }

  const app = getApp();
  const db = getFirestore(app);

  await updateDoc(doc(db, 'kr_users', u.uid), {
    fcmToken: token,
  });
};

export default updateFCMToken;
