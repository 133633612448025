
import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import TextInput from 'components/input/text_input';
import Toast from 'components/toast/toast';
import { arrowBackDark, arrowBackLight, logoLoginLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  loginReplace: () => void;
  phoneNumber: string;
}

declare var window: any;

const LoginCodePage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState('codeSend');

  const [codeValid, setCodeValid] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const codeChange = (e:ChangeEvent<HTMLInputElement>) => {
    setCodeValue(e.target.value);
    setCodeValid('');
  };

  const submitCode = async () => {
    setIsLoading(true);
    const codePattern = /^\d{6}$/;

    if (!codePattern.test(codeValue)) {
      setCodeValid('format error');
    } else {
      try {
        const result = await window.confirmationResult.confirm(codeValue);
        if (result.user) {
          pop();
          params.loginReplace();
        } else {
          setCodeValid('123');
        }
      } catch (err) {
        setCodeValid('123');
        setIsLoading(false);
      }
    }
  }

  const codeSend = () => {
    setIsLoading(false);
    sendSMS();
    setToast('codeSend');
  };

  useEffect(() => {
    const auth = getAuth();
    auth.languageCode = 'ko';
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        'size': 'invisible',
        'callback': (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        }
      });
    }

    sendSMS();
  }, []);

  const sendSMS = async () => {
    setStartTime(Math.floor(new Date().getTime() / 1000));
  
    const auth = getAuth();
    const confirmationResult = await signInWithPhoneNumber(auth, `+82${params.phoneNumber}`, window.recaptchaVerifier);

    if (confirmationResult) {
      window.confirmationResult = confirmationResult;
    } else {
      // setCodeValid('123');
    }
  };

  const [startTime, setStartTime] = useState<number>();
  const [tm, setTm] = useState<string>();

  let timer: any;

  useEffect(() => {
    if (startTime) {
      setTm('3:00');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(() => {
        const current = Math.floor(new Date().getTime() / 1000);
        const d = (startTime + 180) - current;

        if (d < 0) {
          clearInterval(timer);
          return;
        }
        
        const min = Math.floor(d / 60);
        const sec = d % 60;
        setTm(`${min}:${sec.toString().padStart(2, '0')}`);
      }, 1000);
  
      return () => {
        clearInterval(timer);
      }
    }
  }, [startTime]);

  return (
    <AppScreen>
      <Toast
        theme={type}
        text={t('인증번호가 발송되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='codeSend'
      />
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('로그인')}
          borderBottom={false}
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame hasAppbar ${type} p36 bgBG2`}>
          <div className={`login-my-id-frame`}>
            <div className='login-my-id-wrap'>
              {/* <img className='login-logo' src={logoLoginLight} alt={'인슐린 로고'} /> */}
              <div className='tSLG m15 text-center'>{t('휴대전화로 발송된 인증번호를 입력해주세요.')}<br />{t('3분 후 인증코드가 만료됩니다.')}</div>
              <div className='login-body-inputs-wrap gap36'>
                <div className='col-stretch gap24'>
                  <TextInput
                    theme={type}
                    label={t('인증코드')}
                    inputValue={codeValue}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => codeChange(e)}
                    placeholder={t('인증코드 6자리...')}
                    icon={false}
                    validation={codeValid !== '' ? false : true}
                    validMessage={t('인증코드가 잘못되었습니다')}
                    countDown={tm}
                    inputType='tel'
                    maxLength={6}
                    autoFocus
                  />
                </div>
                <div className='login-my-id-btns-wrap'>
                  <div id="sign-in-button" style={{display: 'none'}}/>
                  {
                    tm === '0:00' ? (
                      <BigBtn
                        theme={type}
                        type='secondary'
                        text={t('인증코드 재발송')}
                        onClick={() => codeSend()}
                        fill={true}
                      />
                    ) : (
                      <BigBtn
                        theme={type}
                        type='primary'
                        text={t('로그인')}
                        onClick={() => submitCode()}
                        fill={true}
                        isLoading={isLoading}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default LoginCodePage;