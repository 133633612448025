import { useFlow } from 'configs/stackflow';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  theme: string;
  id: string;
  title: string;
  date: string;
  isHome: boolean;
  isLastItem?: boolean;
  url: string;
  type: string;
  thumb: string;
}

declare var mtw_open_url: any;

const HomeBlogItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { push } = useFlow();

  const openUrl = (url: string) => {
    if (url.includes('https://blog.insurein.life')) {
      const newUrl = url.includes('/kr/app') ? url : url.replaceAll('/kr', '/kr/app');
      push('BlogPostPage', { url: newUrl });
    } else {
      mtw_open_url.postMessage(
        JSON.stringify({
          url: url,
        })
      );
    }
  };

  return (
    <>
      {props.isHome ? (
        <div className={`home-blog-item borderBottom05 ${props.theme}`} onClick={() => openUrl(props.url)}>
          <div className="home-blog-item-left">
            <div
              style={{
                padding: '2px 5px',
                backgroundColor: '#EBECF2',
                color: 'black',
                fontSize: '11px',
                fontWeight: '500',
                borderRadius: '4px',
              }}
            >
              {props.type}
            </div>
            <div className={`m15 c1 els2 ${props.theme}`}>{props.title}</div>
            <div className={`m13 c7 ${props.theme}`}>{props.date}</div>
          </div>
          <img className="home-blog-item-thumbnail" src={props.thumb} alt='thumbnail' />
        </div>
      ) : (
        <div className="p20" onClick={() => openUrl(props.url)}>
          <div className={`home-blog-item blog ${props.isLastItem ? '' : 'borderBottom05'} ${props.theme}`}>
            <div className="home-blog-item-left">
              <div
                style={{
                  padding: '2px 5px',
                  backgroundColor: '#EBECF2',
                  color: 'black',
                  fontSize: '11px',
                  fontWeight: '500',
                  borderRadius: '4px',
                }}
              >
                {props.type}
              </div>
              <div className={`m15 c1 els2 ${props.theme}`}>{props.title}</div>
              <div className={`m13 c7 ${props.theme}`}>{props.date}</div>
            </div>
            <img className="home-blog-item-thumbnail" src={props.thumb} alt='thumbnail' />
          </div>
        </div>
      )}
    </>
  );
};

export default HomeBlogItem;
