import React from 'react';
import SectionTitle from '../common/section_title';
import { useTranslation } from 'react-i18next';
import HomeMyInsurePaymentsItem from './my_insure_payments_item';
import products from 'configs/products';
import PaymentPeriod from 'components/product/payment_period';
import { useFlow } from 'configs/stackflow';

interface Props{
  theme: string;
  items: any[];
  switch: boolean;
  switchActive: boolean;
  switchToggle: () => void;
}

const HomeMyInsure:React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { push } = useFlow();

  if (props.items.length === 0) {
    return <div />;
  }

  return (
    <div className={`home-my-insure bgBG2 bg2Shadow ${props.theme}`}>
      <SectionTitle
        theme={props.theme}
        title={t('내 보험')}
        switch={props.switch}
        switchActive={props.switchActive}
        switchToggle={props.switchToggle}
      />
      <div className='home-my-insure-body'>
        <div className='home-my-insure-items-wrap'>
          <div className={`home-my-insure-items-row`}>
            {/* <div className='home-my-insure-items'>
              {
                props.items.map((item: any, idx: number) => {
                  const product = products.filter((p: any) => p.code === item.productCode)[0];

                  return (
                    <HomeMyInsureRefundItem
                      key={String(idx)}
                      theme={props.theme}
                      logo={product.logo}
                      name={product.name}
                      refund={1}
                      earningRate={8}
                      remainMonth={10}
                    />
                  );
                })
              }
            </div> */}
            <div className='home-my-insure-items'>
              {
                props.items.map((item:any, idx:number) => {
                  const product = products.filter((p: any) => p.code === item.productCode)[0];

                  return (
                    <div
                      key={String(idx)}
                      className={`home-my-insure-item-wrap col-stretch gap20 ${idx === props.items.length - 1 ? '' : 'borderBottomC14W05'} ${props.theme}`}
                      onClick={() => push("ProductInfoPage", {userType: 'user', isSubscribed: true, productCode: product.code})}
                    >
                      <HomeMyInsurePaymentsItem
                        key={idx}
                        theme={props.theme}
                        logo={product.logo}
                        name={product.name}
                        company={product.company}
                        payments={Number(item.contractReqInfo.yearlyPaymentUSD)}
                        remainMonth={item.contractReqInfo.useMonthlyPayment ? 1 : 12}
                        totalMonth={(item.contractReqInfo.paymentDuration ?? 5) * 12}
                        exchange={props.switchActive}
                      />
                      <div style={{padding: '0px 20px'}}>
                        <PaymentPeriod
                          theme={props.theme}
                          period={(item.contractReqInfo.paymentDuration ?? 5) * 12}
                          periodPaid={item.contractReqInfo.useMonthlyPayment ? 1 : 12}                    
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
            {/* <div className='home-my-insure-items'>
              {
                props.items.map((item:any, idx:number) => {
                  const product = products.filter((p: any) => p.code === item.productCode)[0];

                  return (
                    <HomeMyInsureFullfillmentItem
                      key={idx}
                      theme={props.theme}
                      logo={product.logo}
                      name={product.name}
                      ffmtRate={3}
                      change={1}
                    />
                  );
                })
              }
            </div> */}
          </div>
        </div> 
      </div>
    </div>
  );
};

export default HomeMyInsure;