import React from 'react';
import { useTranslation } from 'react-i18next';
import { returnAmountUnit, returnExchange } from 'utils/common/functions';

interface Props{
  theme: string;
  data: {year: string, value: number, more?: any}[];
  type: string; // normal(인출 없는 경우 기본 illustration summary), supNormal(인출 있는 경우 illustration summary), supSupplementary(인출 있는 경우 supplementary illustration summary)
  unit: string;
}

const AccountValueTable:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className={`table-wrap borderC14 radius8 ${props.theme}`}>
      <div className={`table-row flex-center c1 m15 bg16 borderBottomC14 ${props.theme}`}>
        {t('연차별 환급금')}
      </div>
      <div className={`table-row row-center c1 m15 bg16 borderBottomC14 ${props.theme}`}>
        <div className={`table-row-item borderRightC14 flex-center ${props.theme}`} style={{width: 64}} >
          {t('년차')}
        </div>
        <div className={`table-row-item borderRightC14 flexFill flex-center ${props.theme}`}>
          {`${t('환급금')} (${props.unit})`}
        </div>
        <div className={`table-row-item borderRightC14 flex-center ${props.theme}`} style={{width: 100}}>
          {t('비고')}
        </div>
      </div>
      {
        props.data.map((item: {year: string, value: number, more?: string}, idx: number) => {
          return (
            <div key={String(idx)} className={`table-row row-center c1 m15 bgBG2 ${props.data.length - 1 === idx ? '' : 'borderBottomC14'} ${props.theme}`}>
              <div className={`table-row-item borderRightC14 flex-center ${props.theme}`} style={{width: 64}} >
                {item.year.includes('a') ? <>{item.year.replace('a', '')}<span className={`c0 m13 ${props.theme}`}>{t('세')}</span></> : item.year}
              </div>
              <div className={`table-row-item borderRightC14 flexFill flex-center gap4 ${props.theme}`}>
                {returnAmountUnit(Math.floor(returnExchange(item.value, props.unit)), props.unit)}
              </div>
              <div className={`table-row-item flex-center ${props.theme}`} style={{width: 100}}>
                {item.more ?? '-'}
              </div>
            </div>
        )})
      }
    </div>
  );
};

export default AccountValueTable;
