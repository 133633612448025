import {stackflow} from "@stackflow/react";
import {basicRendererPlugin} from "@stackflow/plugin-renderer-basic";
import {basicUIPlugin} from "@stackflow/plugin-basic-ui";
import HomePage from "../pages/user/home";
import LoginPage from "../pages/user/login/login";
import LoginCodePage from "../pages/user/login/login_code";
import SignUpPage from "../pages/user/login/sign_up";
import SignUpCompletePage from "../pages/user/login/sign_up_complete";
import ProductApplicationInfoPage from "../pages/user/product/product_application_info";
import ProductInfoPage from "../pages/user/product/product_info";
import MyProductListPage from "../pages/user/myProduct/my_product_list";
import ProductRefundCalcPage from "../pages/user/product/product_refund_calc";
import ExchangeCalculatePage from "../pages/user/exchange/exchange_calculate";
import PlanRequestPage from "../pages/user/subscription/plan_request";
import SubsRequestPage from "pages/user/subscription/subs_request";
import SettingPage from "pages/user/setting/setting";
import NoticeListPage from "pages/user/setting/notice";
import NoticeDetailPage from "pages/user/setting/notice_detail";
import LoadingPage from "pages/user/loading";
import PdfViewer from "pages/pdf/viewer";
import RmtRequestPage from "pages/user/subscription/rmt_request";
import IntroducePage from "pages/user/login/introduce";
import SelectLocationPage from "pages/user/login/select_location";
import BlogPostPage from "pages/user/blog/blog_post";
import IdCardPage from "pages/user/setting/id_card";
import ReviewListsPage from "pages/user/review/review_lists";
import ReviewPostPage from "pages/user/review/review_post";
import ProductSimulation from "pages/user/product/product_simulation";
import CommonGuide from "pages/user/guide/common_guide";
import ZurichSnpInput from "pages/user/product/simulator/zurich_snp_input";
import ProductAISimulationResult from "pages/user/product/simulator/product_ai_simulation_result";
import ProductAISimulationWithdraw from "pages/user/product/simulator/product_ai_simulation_withdraw";
import ProductPlanPage from "pages/user/product/product_plan";
import ProductPlanDetailPage from "pages/user/product/product_plan_detail";

export const {Stack, useFlow} = stackflow({
  transitionDuration: 150,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme: "cupertino",
    }),
  ],
  activities: {
    HomePage,
    SignUpPage,
    SignUpCompletePage,
    LoginPage,
    LoginCodePage,
    ProductApplicationInfoPage,
    ProductInfoPage,
    MyProductListPage,
    ProductRefundCalcPage,
    ExchangeCalculatePage,
    PlanRequestPage,
    SubsRequestPage,
    SettingPage,
    NoticeListPage,
    NoticeDetailPage,
    LoadingPage,
    PdfViewer,
    RmtRequestPage,
    IntroducePage,
    SelectLocationPage,
    BlogPostPage,
    IdCardPage,
    ReviewListsPage,
    ReviewPostPage,
    ProductSimulation,
    CommonGuide,
    ZurichSnpInput,
    ProductAISimulationResult,
    ProductAISimulationWithdraw,
    ProductPlanPage,
    ProductPlanDetailPage,
  },
  initialActivity: () => "LoadingPage",
});
