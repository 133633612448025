import {configureStore} from '@reduxjs/toolkit';

import authReducer from './reducers/authReducer';
import themeReducer from './reducers/themeReducer';
import langReducer from './reducers/langReducer';
import exchangeReducer from './reducers/exchangeReducer';
import myDataReducer from './reducers/myDataReducer';
import locationReducer from './reducers/locationReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    exchange: exchangeReducer,
    lang: langReducer,
    myData: myDataReducer,
    location: locationReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
