import React from 'react';
import BigBtn from '../btn/big_btn';

interface Props{
  theme: string;
  text: string;
  btns: {type: string, text: string, onClick: () => void}[]; // name: string, onClick: () => void
}

const ModalCol:React.FC<Props> = (props) => {
  return (
    <div className={`modal-overlay zi-modal bgBlack05 ${props.theme}`}>
      <div className={`modal col-stretch bg15 radius12 ${props.theme}`}>
        <div className={`modal-col-text c1 r16 text-center ${props.theme}`}>{props.text}</div>
        <div className='col-stretch gap8'>
          {
            props.btns.map((btn: {type: string, text: string, onClick: () => void}, idx: number) => {
              return (
                <BigBtn
                  key={String(idx)}
                  theme={props.theme}
                  type={btn.type}
                  text={btn.text}
                  onClick={btn.onClick}
                  fill={true}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ModalCol;