import React from 'react';

interface Props{
  theme: string;
  isActive: boolean;
  onClick: () => void;
  text: string;
  padding: boolean;
}

const RadioInput:React.FC<Props> = (props) => {
  return (
    <div className={`radio-input row-center gap8 ${props.padding ? ' padding' : ''}`} onClick={() => props.onClick()}>
      <div className={`radio-circle ${props.theme} ${props.isActive ? ' active borderC1' : ' borderC9'}`}>
        <div className={`radio-circle-inner ${props.theme} ${props.isActive ? ' active bg1' : ''}`} />
      </div>
      <div className={`m17 c3 ${props.theme}`}>{props.text}</div>
    </div>
  );
};

export default RadioInput;