import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  initialLoading: true,
  isMobile: false,
  isLoggedIn: false,
  accessToken: '',
  userId: '',
  deviceType: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
      state.initialLoading = false;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload.isMobile;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.initialLoading = false;
    },
  },
});

export const {login, setIsMobile, logout} = authSlice.actions;

export default authSlice.reducer;
