import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import CreditExpTable from 'components/table/credit_exp_table';
import guides from 'configs/guide';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  type: string;
}

const CommonGuide:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop, push } = useFlow();

  const handleUrl = (event: any) => {
    if (event.origin === 'https://blog.insurein.life') {
      if (event.data.includes('https://blog.insurein.life')) {
        // inner link
        const url = event.data.includes('/kr/app') ? event.data : event.data.replaceAll('/kr', '/kr/app');
        pop();
        push("BlogPostPage", {url});
      } else {
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleUrl, false);

    return () => {
      window.removeEventListener('message', handleUrl);
    }
  }, []);

  const data = (guides as any)[params.type];

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} t48 bgBG2`} style={{paddingBottom: '0px'}}>
          <Appbar
            theme={type}
            type='text'
            title={data.title}
            borderBottom={false}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className='col-stretch gap8' style={{padding: '20px'}}>
            {
              data.content.map((content: any, i: number) => {
                if (content.type === 'creditExpTable') {
                  return <CreditExpTable />;
                }

                if (content.type === 'title') {
                  return <div key={String(i)} className='b17'>{content.text}</div>;
                }

                return (
                  <div key={String(i)} className='r15 c7 light'>{content.text}</div>
                );
              })
            }
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default CommonGuide;