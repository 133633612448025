import db from "configs/db";
import { doc, updateDoc } from "firebase/firestore";

const uploadTransfer = async (taskId: string, files: any) => {
  await updateDoc(doc(db.getKrTaskCol(), taskId), {
    transferFiles: files,
  });
};

export default uploadTransfer;
