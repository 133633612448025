import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  myData: {},
  tasks: [],
};

export const myDataSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.myData = action.payload.data;
      state.loading = false;
      state.tasks = action.payload.tasks;
    },
  },
});

export const {setData} = myDataSlice.actions;

export default myDataSlice.reducer;
