import taskStates from "configs/task_states";

const getTaskLabel = (step: string, subStep: number, useDetail = false) => {
  if (step === taskStates.ESTIMATE) {
    if (useDetail) {
      if (!subStep || subStep === 1) {
        return '설계서 요청';
      }

      if (subStep === 2) {
        return '설계서 발급대기';
      }
    }

    return '설계서 발급';
  }

  if (step === taskStates.CONTRACT_WRITE) {
    if (useDetail) {
      if (subStep === 1) {
        return '청약서 요청';
      }

      if (subStep === 2) {
        return '청약서 수령대기';
      }

      if (subStep === 3) {
        return '청약서 작성';
      }
    }

    return '청약서 작성';
  }

  if (step === taskStates.CONTRACT) {
    if (useDetail) {
      if (subStep === 1) {
        return '보험사 청약서 수신중';
      }

      if (subStep === 2) {
        return '보험사 언더라이팅중';
      }

      if (subStep === 3) {
        return '송금증 제출'
      }

      if (subStep === 4) {
        return '보험사 증서 발급중';
      }
    }

    return '청약서 심사';
  }

  if (step === taskStates.COMPLETE) {
    return '청약 완료';
  }

  return '상품 할당 전';
};

export default getTaskLabel;
