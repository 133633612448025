import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  type: string;
  text: string;
  onClick: () => void;
}

const SmallBtn:React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`small-btn ${props.type === 'primary' ? 'bgSDG slg' : props.type === 'secondary' ? 'bg15 c1' : ''} ${props.theme}`}
      onClick={() => props.onClick()}
    >
      {props.text}
    </div>
  );
};

export default SmallBtn;