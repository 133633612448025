import db from "configs/db";
import { doc, updateDoc } from "firebase/firestore";

const saveEstimateReqInfo = async (taskId: string, data: any, confirm = false) => {
  const {
    enName,
    gender,
    birth,
    isSmoke,
    payments,
    withdrawalStartAge,
    withdrawalStartYear,
    withdrawalEndYear,
    withdrawalYearRecur,
    introducerPN,
    paymentDuration,
    useMonthlyPayment,
  } = data;

  const updatedValue: any = {
    introducerPN,
    estimateReqInfo: {
      enName,
      gender,
      birth,
      isSmoke,
      payments,
      withdrawalStartAge,
      withdrawalStartYear,
      withdrawalEndYear,
      withdrawalYearRecur,
      paymentDuration,
      useMonthlyPayment,
    },
  };

  if (confirm) {
    updatedValue.subStep = 2;
    updatedValue.isReject = false;
    updatedValue.rejectMention = '';
    updatedValue.forIfa = true;
  }

  await Promise.all([
    updateDoc(doc(db.getKrTaskCol(), taskId), updatedValue),
  ]);
};

export default saveEstimateReqInfo;
