import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  type?: string; // flag
  items?: string[];
  flagItems?: {name:string, flag:string, disabled:boolean, id: string}[];
  // onClick: (i:number) => void;
  onClose: () => void;
  setSelectedItem: Dispatch<SetStateAction<number>>;
  setCalc?: Dispatch<SetStateAction<boolean>>;
  selectFunc?: () => void;
}

const DropdownSheet:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const itemClick = (idx: number) => {
    if (props.type === 'flag' && props.flagItems && props.flagItems[idx].disabled) {

    } else {
      props.setSelectedItem(idx);
      props.onClose();
      if (props.setCalc) {
        props.setCalc(false);
      }
      if (props.selectFunc) {
        props.selectFunc();
      }
    }
  }

  return (
    <div className={`sheet-overlay ${props.theme} bgBlack05`}>
      <div className='dropdown-sheet'>
        <div className={`dropdown-sheet-items col-stretch bgBG2 radius12 ${props.theme}`}>
          {
            props.type !== 'flag' && props.items ?
            props.items?.map((item: string, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`dropdown-sheet-item c1 m17 ${idx === (props.items?.length || 0) - 1 ? '' : 'borderBottom05'} ${props.theme}`}
                  onClick={() => itemClick(idx)}
                >
                  {item}
                </div>
              );
            }) :
            props.type === 'flag' && props.flagItems ?
            props.flagItems?.map((item: {name: string, flag: string, disabled: boolean}, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`dropdown-sheet-item c1 m17 ${idx === (props.flagItems?.length || 0) - 1 ? '' : 'borderBottom05'} ${item.disabled ? ' disabled' : ''} ${props.theme}`}
                  onClick={() => itemClick(idx)}
                >
                  <div className='row-center gap12'>
                    <img className={`dropdown-flag borderC14 ${props.theme}`} src={item.flag} alt='flag' />
                    <div className={`dropdown-item`}>
                      {item.name}
                    </div>
                  </div>
                </div>
              );
            }) :null
          }
        </div>
        <div className={`dropdown-sheet-cancel ${props.theme} colorRed bgBG2 radius12`} onClick={props.onClose}>{t('취소')}</div>
      </div>
    </div>
  );
};

export default DropdownSheet;