import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  lang: 'kr',
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    langChange: (state, action) => {
      state.lang = action.payload.lang;
    },
  },
});

export const {langChange} = langSlice.actions;

export default langSlice.reducer;
