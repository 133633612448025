import React from 'react';

interface Props{
  theme: string;
  active: number;
  onClick: ((idx: number) => void) | undefined;
  text: string[];
  size: string;
}

const SwitchTextBtnTriple:React.FC<Props> = (props) => {

  const switchClick = (idx: number) => {
    if (props.onClick && props.onClick !== undefined) {
      props.onClick(idx);
    } else {
      return;
    }
  };

  return (
    <>
      {
        props.size === 'small' ?
        <div className={`switch-text-btn-triple small bg16 ${props.theme}`}>
          <div className={`switch-text-btn-triple-area small active${props.active} ${props.active >= 0 && props.active < 3 ? 'bgBG2' : ''} ${props.theme}`} />
          <div
            className={`switch-text-btn-triple-text small m13 ${props.active === 0 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(0)
            }
          >
            {
              props.text[0]
            }
          </div>
          <div
            className={`switch-text-btn-triple-text small m13 ${props.active === 1 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(1)
            }
          >
            {
              props.text[1]
            }
          </div>
          <div
            className={`switch-text-btn-triple-text small m13 ${props.active === 2 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(2)
            }
          >
            {
              props.text[2]
            }
          </div>
        </div> :
        props.size === 'normal' ?
        <div className={`switch-text-btn-triple normal border1010 bg16 ${props.theme}`}>
          <div className={`switch-text-btn-triple-area normal active${props.active} ${props.active >= 0 && props.active < 3 ? 'bgBG2' : ''} ${props.theme}`} />
          <div
            className={`switch-text-btn-triple-text normal m13 ${props.active === 0 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(0)
            }
          >
            {
              props.text[0]
            }
          </div>
          <div
            className={`switch-text-btn-triple-text normal m13 ${props.active === 1 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(1)
            }
          >
            {
              props.text[1]
            }
          </div>
          <div
            className={`switch-text-btn-triple-text normal m13 ${props.active === 2 ? 'c1' : 'c7'} ${props.theme}`}
            onClick={
              () => switchClick(2)
            }
          >
            {
              props.text[2]
            }
          </div>
        </div> : null
      }
    </>
  );
};

export default SwitchTextBtnTriple;