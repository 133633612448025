import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { returnDate } from 'utils/common/functions';

type Params = {
  title: string;
  date: Number;
  text: string;
}

const NoticeDetailPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} p16 t64 bgBG2`}>
          <Appbar
            theme={type}
            type='text'
            title={t('')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className={`b20 c1 ${type}`}>{params.title}</div>
          <div className={`notice-detail-date m15 c7 ${type}`}>{returnDate(params.date)}</div>
          <div className={`notice-detail-text r18 c5 ${type}`}>{params.text}</div>
        </div>
      </div>
    </AppScreen>
  );
};

export default NoticeDetailPage;