import React from 'react';
import { logoDark, logoLight } from '../../configs/icon_route';

type Props = {
  theme: string;
  type: string; // logo, text
  title?: string;
  left?: any;
  right?: any;
  color?: string;
  visible?: boolean;
  outWrap?: boolean; // 밖에서 감싸는 fixed 부모태그가 존재 할 시
  borderBottom?: boolean;
};

const Appbar:React.FC<Props> = (props) => {
  return (
    <div
      className={
        `appbar blur12 ${props.borderBottom === false ? '' : 'borderBottomC14W05'}
        ${props.color ? props.color : props.outWrap ? '' : 'bgBG2'}
        ${props.theme}
        ${props.outWrap ? 'outWrap' : ''}
        ${props.visible === true ? 'show' : props.visible === false ? 'hide' : ''}`
      }
    >
      <div
        className='appbar-icon-area'
        onClick={
          props.left ?
          () => props.left.onClick() :
          undefined
        }
      > 
        {
          props.left ? <img className='appbar-icon' src={props.left.icon} alt={props.left.icon} /> : null
        }
      </div>
      {
        props.type === 'logo' ?
        <img
          className='appbar-logo'
          src={props.theme === 'dark' ? logoDark : logoLight}
          alt='logo'
        /> :
        <div className={`b17 c1 ${props.theme}`}>{props.title}</div>
      }
      <div
        className='appbar-icon-area'
        onClick={
          props.right ?
          () => props.right.onClick() :
          undefined
        }
      >
        {
          props.right ? <img className='appbar-icon' src={props.right.icon} alt={props.right.icon} /> : null
        }
      </div>
    </div>
  );
};

export default Appbar;