const zurichSFFeeData = [
  0.05548,
  0.05548,
  0.05548,
  0.02848,
  0.02848,
  0.01248,
  0.01248,
  0.01248,
  0.01248,
  -0.003330214,
0.012590865,
0.012598622,
0.012604628,
0.012608813,
-0.007748471,
0.012614367,
0.01263051,
0.012630528,
0.012643353,
-0.007713283,
0.012647441,
0.012642895,
0.012666769,
0.012689488,
-0.007708402,
0.012691821,
0.012681045,
0.012699785,
0.012717157,
-0.007672718,
0.012717668,
0.012716343,
0.012746312,
0.012741444,
-0.007637125,
0.01275697,
0.012749293,
0.012774137,
0.012780118,
-0.007602381,
0.012794505,
0.012798097,
0.012799679,
0.012817667,
-0.007550634,
0.012812407,
0.012827999,
0.012861123,
0.012854713,
-0.007519533,
0.012866611,
0.012876804,
0.01288521,
0.012891754,
-0.0074717,
0.012902308,
0.012926864,
0.012929327,
0.012929669,
-0.007427067,
0.012938727,
0.012979186,
0.012975401,
0.012991317,
-0.007387012,
0.012976913,
0.01301245,
0.013024338,
0.013034435,
-0.007329181,
0.013040749,
0.013071741,
0.013053958,
0.013105716,
-0.007301416,
0.013110527,
0.013112168,
0.013111561,
0.01313392,
-0.007231325,
0.01316225,
0.013157216,
0.013175782,
0.013219243,
-0.007194824,
0.013220673,
0.013208719,
0.013248659,
0.013260098,
-0.007112735,
0.013259638,
0.013268077,
0.013303134,
0.013337208,
-0.00706797,
0.013334893,
0.013337285,
0.013397417,
0.013366216,
-0.006974232,
];

export default zurichSFFeeData;
