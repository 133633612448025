export const blogItems = [
  {
    type: '기사',
    id: '07',
    thumb: '/img/avif/post/article03.avif',
    title: '[개인연금 비상]③ 연금보험, 같은 조건인데 홍콩 보험사 2배 더 준다',
    url: 'https://biz.chosun.com/stock/finance/2024/05/24/PBSP65SMIRAQPDXMXAGGCMWGM4/',
    date: '조선일보 / 24.05.24',
  },
  {
    type: '기사',
    id: '06',
    thumb: '/img/avif/post/article01.avif',
    title: '[저물지 않은 홍콩]①합리적 세금·지리적 이점, 아시아 허브로서 여전히 건재',
    url: 'https://biz.chosun.com/international/international_general/2024/09/23/AEZX3LW6QZCWJGZGPASPE5KU4Y/',
    date: '조선일보 / 24.09.23',
  },
  {
    type: '기사',
    id: '06',
    thumb: '/img/avif/post/article02.avif',
    title: '강남 부자가 찾는 ‘홍콩보험’ 정체는… 年 6~7% 안정적 수익',
    url: 'https://biz.chosun.com/stock/finance/2024/02/13/HIC4K4DZEJA6NE4QRYHOQBYTJ4/',
    date: '조선일보 / 24.02.13',
  },
  {
    type: '블로그',
    id: '10',
    thumb: '/img/avif/post/list06.avif',
    title: 'insurein 앱으로 외국보험 가입하기 - [1] 설계서, 청약서 신청',
    url: 'https://blog.insurein.life/kr/app/%EC%9D%B8%EC%8A%90%EB%A6%B0%EC%9C%BC%EB%A1%9C-%EC%99%B8%EA%B5%AD%EB%B3%B4%ED%97%98-%EA%B0%80%EC%9E%85%ED%95%98%EA%B8%B0-1%ED%8E%B8',
    date: '24.11.06',
  },
  {
    type: '블로그',
    id: '01',
    thumb: '/img/avif/post/list01.avif',
    title: '[에디터픽] 왜 선진국에서는 스위스 포춘으로 S&P500(미국 주가지수)에 투자할까요?',
    url: 'https://blog.insurein.life/kr/app/%EC%8A%A4%EC%9C%84%EC%8A%A4-%ED%8F%AC%EC%B6%98-S&P500-%ED%88%AC%EC%9E%90',
    date: '24.10.22',
  },
  {
    type: '블로그',
    id: '02',
    thumb: '/img/avif/post/list02.avif',
    title: '보험이 투자상품이라고요? - 저축성보험으로 달러자산에 투자하기',
    url: 'https://blog.insurein.life/kr/app/%EC%A0%80%EC%B6%95%EC%84%B1%EB%B3%B4%ED%97%98-%EB%8B%AC%EB%9F%AC%EC%9E%90%EC%82%B0-%ED%88%AC%EC%9E%90',
    date: '24.10.21',
  },
  {
    type: '블로그',
    id: '03',
    thumb: '/img/avif/post/list03.avif',
    title: '왜 글로벌 보험상품들이 한국 보험상품들보다 혜택이 많을까요?',
    url: 'https://blog.insurein.life/kr/app/%EA%B8%80%EB%A1%9C%EB%B2%8C-%EB%B3%B4%ED%97%98%EC%83%81%ED%92%88-%ED%98%9C%ED%83%9D',
    date: '24.10.18',
  },
  {
    type: '블로그',
    id: '04',
    thumb: '/img/avif/post/list04.avif',
    title: '대한민국 국민이 외국 보험상품에 가입해도 되나요?',
    url: 'https://blog.insurein.life/kr/app/%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD-%EA%B5%AD%EB%AF%BC-%EC%99%B8%EA%B5%AD-%EB%B3%B4%ED%97%98%EC%83%81%ED%92%88',
    date: '24.10.16',
  },
  {
    type: '블로그',
    id: '05',
    thumb: '/img/avif/post/list05.avif',
    title: 'insurein 앱으로 글로벌 TOP20 회사들의 저축성 보험 둘러보기',
    url: 'https://blog.insurein.life/kr/app/%EC%9D%B8%EC%8A%90%EB%A6%B0-%EC%84%9C%EB%B9%84%EC%8A%A4-%EC%86%8C%EA%B0%9C',
    date: '24.10.15',
  },
];