import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import SectionTitle from 'components/common/section_title';
import AccountValueTable from 'components/table/account_value_table';
import { arrowBackLight } from 'configs/icon_route';
import irrData from 'configs/product/irr_data';
import zurichSFFeeData from 'configs/product/zurich_sf_fee_data';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import { getAuth } from 'firebase/auth';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { returnExchange } from 'utils/common/functions';
import createUserTask from 'utils/firebase_firestore/create_user_tasks';

type Params = {
  req: Function;
  setToast: any;
  taskId?: string;
  productCode: string,
  paidYear: number,
  payment: number,
  snpRatio: number,
};

const ProductAISimulationResult:ActivityComponentType<Params> = ({params}) => {
  const {pop, push} = useFlow();

  const [switchB, setSwitchB] = useState(true);
  const switchToggleB = () => {
    setSwitchB(!switchB);
  };
  

  const product = products.filter((p: any) => p.code === params.productCode)[0];

  // @ts-expect-error
  const tableData = irrData[product.code][`${params.paidYear}y`];

  const calAccountValue = (irr: number, targetYear: number) => {
    let accountValue = 0;
    for (let i = 1; i <= params.paidYear; i++) {
      accountValue += ((params.payment) * (irr ** (targetYear - i + 1)));
    }

    return accountValue;
  };

  const [zurichSFData, setZurichSFData] = useState([]);

  useEffect(() => {
    const arr: any = [];
    const totalValue = params.payment * params.paidYear;

    const snpRatio = (1 + (params.snpRatio >= 11.7 ? 11.7 : params.snpRatio > 0 ? params.snpRatio : 0) / 100);

    zurichSFFeeData.forEach((feeRatio, i) => {
      if (i === 0) {
        arr.push({year: (i + 1).toString(), value: (params.payment * 1.08 - (totalValue * feeRatio))});
      } else if (i < 5) {
        arr.push({year: (i + 1).toString(), value: (arr[i - 1].value * snpRatio) + params.payment - (totalValue * feeRatio)});
      } else if (i < 10) {
        const av = (arr[i - 1].value * snpRatio) - (totalValue * feeRatio);

        arr.push({year: (i + 1).toString(), value: av, 
          more: (
            <div className='col-center'>
              <div className='m13 c2 light'>
                원금
                <span className='b13' style={{marginLeft: '2px', color: '#00B55A'}}>{
                  (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) < 1000 ?
                    (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) :
                    Math.round((Math.round((av / (params.payment * params.paidYear)) * 10) / 10)).toLocaleString()
                }</span>
                배
              </div>
            </div>
          )
        });
      } else {
        const av = arr[i - 1].value * (snpRatio - feeRatio);

        arr.push({year: (i + 1).toString(), value: av, 
          more: (
            <div className='col-center'>
              <div className='m13 c2 light'>
                원금
                <span className='b13' style={{marginLeft: '2px', color: '#00B55A'}}>{
                  (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) < 1000 ?
                    (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) :
                    Math.round((Math.round((av / (params.payment * params.paidYear)) * 10) / 10)).toLocaleString()
                }</span>
                배
              </div>
            </div>
          )
        });
      }
    });

    setZurichSFData(arr);
  }, []);

  const reqEstimate = async () => {
    if (params.taskId === null || !params.taskId) {
      const auth = getAuth();
      const id = (await createUserTask(params.productCode, auth.currentUser?.uid ?? '', auth.currentUser?.phoneNumber ?? '') ?? '');
      push("PlanRequestPage", {taskId: id, logo: product.logo, productName: product.name, setToast: params.setToast, refresh: params.req, productCode: product.code});
    } else {
      push("PlanRequestPage", {taskId: params.taskId ?? '', logo: product.logo, productName: product.name, setToast: params.setToast, refresh: params.req, productCode: product.code});
    }
  };

  return (
    <AppScreen>
      <div className='frame-cover'>
        <Appbar
          theme='light'
          type='text'
          title={t('AI 시뮬레이션')}
          left={{
            icon: arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className='frame light t56 pd20 bgBG3' style={{paddingBottom: '80px'}}>
        <div style={{position: 'fixed', bottom: '0px', width: '100%', backgroundColor: 'white', zIndex: 1000}}>
          <div style={{padding: '10px 20px'}}>
            <BigBtn
              theme={'light'}
              type='primary'
              text={t('설계서 요청')}
              onClick={reqEstimate}
              fill={true}
            />
          </div>
        </div>
          <div className='col-stretch gap24'>
            <div style={{backgroundColor: 'white'}}>
              <SectionTitle
                theme='light'
                title={t('계산 결과')}
                switch={true}
                switchActive={switchB}
                switchToggle={switchToggleB}
                isSales={false}
              />
              <div style={{margin: '20px 20px 0px 20px', border: 'solid 1px #e9e9e9'}} className={`product-info-head col-start gap8 bgBG2 light radius16`}>
                <div className='row-center gap6'>
                  <img src={product.logo} alt='product logo' />
                  <div className='m13'>{product.company}</div>
                </div>
                <div className={`c1 b20 light`}>{product.name}</div>
                <div className='col-start gap12'>
                  <div className={`m13 c1 light`}><span className={`b17 light`}>{returnExchange(params.payment, switchB ? 'USD' : 'KRW')?.toLocaleString()} <span className={`r15 c7 light`}>{switchB ? 'USD' : 'KRW'} / year</span></span></div>
                </div>
                <div className='row-center gap8'>
                  <div className={`home-product-item-period c1 m13 bg16 radius4 light`}>{params.paidYear + '년 납입'}</div>
                  {
                    product.code === 'ZURICH_01' ? <div className={`home-product-item-period c1 m13 bg16 radius4 light`}>{'시뮬레이션 수익률: ' + params.snpRatio + '%'}</div> : null
                  }
                </div>
              </div>
              <div style={{padding: '20px 20px 0px 20px', textAlign: 'center'}} className='m13 light c7'>
                {t('인슐린 AI 시뮬레이션은 실제 설계서들의 Basic Plan을 학습하여 예측합니다. 개별 고객정보에 따라 시뮬레이션 결과는 달라질 수 있으며 정확한 값을 알기 위해서는 설계서를 신청해주세요.')}
              </div>
              <div style={{padding: '20px'}}>
                <AccountValueTable
                  theme={'light'} data={
                    product.code === 'ZURICH_01' ?
                    zurichSFData.filter((v: any) => v.year % 5 === 0) :
                  tableData.filter((v: any) => (v.year >= 10 && v.year % 5 === 0) || v.year === 93).map((v: any) => {
                    const irr = v.irr['B'];
                    const av = calAccountValue(1 + irr, v.year);
                    return {
                      year: v.year.toString(),
                      value: av,
                      more: (
                        <div className='col-center'>
                          <div className='m13 c2 light'>
                            원금
                            <span className='b13' style={{marginLeft: '2px', color: '#00B55A'}}>{
                              (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) < 1000 ?
                                (Math.round((av / (params.payment * params.paidYear)) * 10) / 10) :
                                Math.round((Math.round((av / (params.payment * params.paidYear)) * 10) / 10)).toLocaleString()
                            }</span>
                            배
                          </div>
                          <div className='r12 c5 light'>{`IRR ${Math.round(irr * 10000) / 100}%`}</div>
                        </div>
                      )
                    };
                  })}
                type={'normal'} unit={switchB ? 'USD' : 'KRW'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductAISimulationResult;
