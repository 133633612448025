import db from 'configs/db';
import { getDocs, query, where } from 'firebase/firestore';

const getUserTasksByUser = async (userId: string) => {
  const q = query(db.getKrTaskCol(),
      where('userId', '==', userId));

  const d = await getDocs(q);
  return d.docs.map((d) => ({...d.data(), 'id': d.id}));
};

export default getUserTasksByUser;
