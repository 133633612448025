import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  title: string;
  date: string;
  id: string;
  url: string;
}

const ReviewPostPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} t48 bgBG1`}>
          <Appbar
            theme={type}
            type='text'
            title={t('')}
            borderBottom={false}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className='col-stretch gap8'>

          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ReviewPostPage;