import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import TextInput from 'components/input/text_input';
import SNPTable from 'components/table/snp_table';
import { arrowBackLight } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import { t } from 'i18next';
import { useState } from 'react';

type Params = {
  onNext: any;
};

const ProductAISimulationWithdraw:ActivityComponentType<Params> = ({params}) => {
  const {pop} = useFlow();

  const [interest, setInterest] = useState<any>('11.95');

  const product = products.filter((p: any) => p.code === 'ZURICH_01')[0];

  return (
    <AppScreen>
      <div className='frame-cover'>
        <Appbar
          theme='light'
          type='text'
          title={t('AI 시뮬레이션')}
          left={{
            icon: arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className='frame light t72 pd20 bgBG2'>
          <div className='product-simul-top'>
            <div className='col-stretch gap24'>
            <div className={`product-info-head col-start gap8 bgBG2 light radius16`} style={{border: 'solid 1px #e9e9e9'}}>
                <div className='row-center gap6'>
                  <img src={product.logo} alt='product logo' />
                  <div className='m13'>{product.company}</div>
                </div>
                <div className={`c1 b20 light`}>{product.name}</div>
              </div>
              <div className='b17'>{t('예상하는 S&P500 평균 수익률을 입력해주세요.')}</div>
              <SNPTable />
              <TextInput
                theme='light'
                label={t('예상 S&P500 평균 수익률')}
                subLabel={': ' + t('단위 %')}
                inputValue={interest}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setInterest(e.target.value)}
                placeholder={t('숫자만 입력')}
                validation
                validMessage=''
                // validation={paymentValid === 'error' || paymentValid === 'minError' ? false : true}
                // validMessage={paymentValid === 'error' ? t('납입금을 확인해 주세요.') : paymentValid === 'minError' ? t('최소금액을 확인해주세요') : ''}
                inputType='tel'
                icon={false}
              />
              <BigBtn
                theme='light'
                type='primary'
                text={t('시뮬레이션')}
                onClick={() => params.onNext()}
              />
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductAISimulationWithdraw;
