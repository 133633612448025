import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { useEffect, useRef, useState } from 'react';
import Appbar from '../../../components/appbar/appbar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { arrowBackDark, arrowBackLight, arrowDownBoldDark, arrowDownBoldLight, flagHk, flagKr, flagUs, verticalEqualDark, verticalEqualLight } from '../../../configs/icon_route';
import { useFlow } from '../../../configs/stackflow';
import { ActivityComponentType } from '@stackflow/react';
import DropdownSheet from 'components/sheet/dropdown_sheet';

type Params = {
  permission: string;
  selectedIdx: number;
}

const ExchangeCalculatePage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  const [selectedIdx, setSelectedIdx] = useState(params.selectedIdx);

  const [firstValue, setFirstValue] = useState<string>('1');
  const [secondValue, setSecondValue] = useState<string>('');

  const {HKD, USD} = useSelector((state: RootState) => state.exchange);
  
  useEffect(() => {
    if (HKD && USD) {
      setFirstValue('1');

      setSecondValue(USD.toLocaleString());
    }
  }, [HKD, USD, selectedIdx]);

  const selectedIdxRef = useRef<any>();

  useEffect(() => {
    selectedIdxRef.current = selectedIdx;
  }, [selectedIdx]);

  const firstOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/,/g, '');

    if (input === '') {
      setFirstValue('');
      setSecondValue('');
      return;
    }

    if (input.length >= 2 && input[0] === '0') {
      input = input.slice(1);
    }

    const parsedValue = parseInt(input, 10);

    if (!isNaN(parsedValue)) {
      const formattedFirstValue = parsedValue.toLocaleString();
      const result = (parsedValue * (selectedIdxRef.current === 0 ? USD : HKD)).toLocaleString();
      setFirstValue(formattedFirstValue);
      setSecondValue(result);
    }
  };

  const secondOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/,/g, '');

    if (input === '') {
      setFirstValue('');
      setSecondValue('');
      return;
    }

    if (input.length >= 2 && input[0] === '0') {
      input = input.slice(1);
    }

    const parsedValue = parseInt(input, 10);

    if (!isNaN(parsedValue)) {
      const formattedFirstValue = parsedValue.toLocaleString();
      const result = (parsedValue / (selectedIdxRef.current === 0 ? USD : HKD)).toLocaleString();
      setSecondValue(formattedFirstValue);
      setFirstValue(result);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownClose = () => {
    setDropdownOpen(false);
  };
  const selectFunc = () => {
    // 선택 시 함수
  };

  return (
    <AppScreen>
      {
        dropdownOpen ?
        <DropdownSheet
          theme={type}
          items={['USD', 'HKD']}
          onClose={dropdownClose}
          setSelectedItem={setSelectedIdx}
          selectFunc={selectFunc}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} p20 gap16 t64`}>
          <Appbar
            theme={type}
            type='text'
            title={t('환율 계산')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className='col-center gap12'>
            {
              params.permission === 'seller' || params.permission === 'master' ?
              <div className={`exchange-calculate-input-wrap radius8 bgBG2 borderC13 row-center w100 ${type}`}>
                <input className={`exchange-calculate-input c1 m17 w100 ${type}`} type='tel' value={firstValue} onChange={(e:React.ChangeEvent<HTMLInputElement>) => firstOnChange(e)} />
                <div className={`exchange-calculate-flag-arrow-wrap bg15 row-center gap6 ${type}`} onClick={() => setDropdownOpen(true)}>
                  <img className='exchange-calc-flag' src={selectedIdx === 0 ? flagUs : flagHk} alt='' />
                  <div className={`c1 m17 ${type}`}>{selectedIdx === 0 ? 'USD' : 'HKD'}</div>
                  <img className='exchange-calc-arrow' src={type === 'dark' ? arrowDownBoldDark : arrowDownBoldLight} alt='' />
                </div>
              </div> : 
              <div className={`exchange-calculate-input-wrap radius8 bgBG2 borderC13 row-center w100 ${type}`}>
                <input className={`exchange-calculate-input c1 m17 w100 ${type}`} type='tel' value={firstValue} onChange={(e:React.ChangeEvent<HTMLInputElement>) => firstOnChange(e)} />
                <div className={`exchange-calculate-flag-wrap bg15 row-center gap6 ${type}`}>
                  <img className='exchange-calc-flag' src={flagUs} alt='' />
                  <div className={`c1 m17 ${type}`}>{'USD'}</div>
                </div>
              </div>
            }
            <img className='exchange-calculate-icon' src={type === 'dark' ? verticalEqualDark : verticalEqualLight} alt='equal' />
            <div className={`exchange-calculate-input-wrap radius8 bgBG2 borderC13 row-center w100 ${type}`}>
              <input className={`exchange-calculate-input c1 m17 w100 ${type}`} type='tel' value={secondValue} onChange={(e:React.ChangeEvent<HTMLInputElement>) => secondOnChange(e)} />
              <div className={`exchange-calculate-flag-wrap bg15 row-center gap6 ${type}`}>
                <img className='exchange-calc-flag' src={flagKr} alt='KRW' />
                <div className={`c1 m17 ${type}`}>KRW</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ExchangeCalculatePage;