import { getApp } from 'firebase/app';
import {doc, getDoc, getFirestore, } from 'firebase/firestore';

const getUser = async (uid: string) => {
  const app = getApp();
  const db = getFirestore(app);
  const d = await getDoc(doc(db, 'kr_users', uid));
  return d.data();
};

export default getUser;
