import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Appbar from '../../../components/appbar/appbar';
import { ActivityComponentType } from '@stackflow/react';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useFlow } from '../../../configs/stackflow';
import { useTranslation } from 'react-i18next';
import { arrowBackDark, arrowBackLight, logoAxa } from '../../../configs/icon_route';
import SectionTitle from '../../../components/common/section_title';
import ProductAppStep from '../../../components/product/product_application_step';
import BigBtn from '../../../components/btn/big_btn';
import UploadInput from '../../../components/input/upload_input';
import { List } from 'immutable';
import ModalCol from '../../../components/modal/modal_col';
import Toast from 'components/toast/toast';
import ModalOk from 'components/modal/modal_ok';

type Params = {
  setToastStart: Dispatch<SetStateAction<string>> | undefined;
  fromWhere?: string;
}

const ProductApplicationInfoPage: ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop, push } = useFlow();
  const { t } = useTranslation();

  //toast
  const [toast, setToast] = useState('');

  const [active, setActive] = useState([0, '']); // [index, 'reject'] 스탭 idx(0~5), 리젝이면 'reject' 나머지 스트링은 일반
  const [rejectMsg, setRejectMsg] = useState(t('영문 성명이 제대로 입력되지 않았습니다. 여권과 동일한 이름으로 확인 부탁드립니다.'));

  const [fileA, setFileA] = useState<any>(List([]));
  const [fileB, setFileB] = useState<any>(List([]));
  const [fileC, setFileC] = useState<any>(List([]));
  const [fileValidA, setFileValidA] = useState('');
  const [fileValidB, setFileValidB] = useState('');
  const [fileValidC, setFileValidC] = useState('');

  const handleFileA = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileA(e.target.files?.[0]);
    setFileValidA('');
  };
  const handleFileB = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileB(e.target.files?.[0]);
    setFileValidB('');
  };
  const handleFileC = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileC(e.target.files?.[0]);
    setFileValidC('');
  };

  const openFileFinder = (id: string) => {
    if (document.querySelector(`input#${id}`)) {
      (document.querySelector(`input#${id}`)! as any).click();
    }
  };

  const [modal, setModal] = useState('');
  const openSaveModal = () => {
    setModal('save');
  };
  const closeSaveModal = () => {
    setModal('');
  };
  const modalSaveLeave = () => {
    if (params.setToastStart) {
      params.setToastStart('productAppSaved');
      closeSaveModal();
      pop();
    }
  };
  const modalLeave = () => {
    if (params.setToastStart) {
      params.setToastStart('');
      closeSaveModal();
      pop();
    }
  };

  const popClick = () => {
    if (active[0] === 4) {
      openSaveModal();
    } else {
      pop();
    }
  };

  const saveClick = () => {
    if (fileA !== List([]) && fileB !== List([]) && fileC !== List([])) {
      setToast('saveThisPage');
    } else {
      if (fileA === List([])) {
        setFileValidA('noFile');
      }
      if (fileB === List([])) {
        setFileValidB('noFile');
      }
      if (fileC === List([])) {
        setFileValidC('noFile');
      }
      setModal('fileValid');
    }
  };

  return (
    <AppScreen>
      {
        modal === 'save' ?
        <ModalCol
          theme={type}
          text={t('저장되지 않은 정보가 있습니다. 저장 후 페이지를 떠나시겠습니까?')}
          btns={[
            {type: 'primary', text: t('저장 후 떠나기'), onClick: () => modalSaveLeave()},
            {type: 'otherWarning', text: t('저장하지 않고 떠나기'), onClick: () => modalLeave()},
            {type: 'other', text: t('취소'), onClick: () => closeSaveModal()}
          ]}
        /> :
        modal === 'fileValid' ?
        <ModalOk
          theme={type}
          text={t('업로드되지 않은 파일이 있습니다.')}
          btn={{name: t('확인'), onClick: () => setModal('')}}
        /> : null
      }
      <Toast
        theme={type}
        text={t('작성 중인 내용이 저장되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='reqSaved'
      />
      <Toast
        theme={type}
        text={t('설계서 요청이 완료되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='planReqComplete'
      />
      <Toast
        theme={type}
        text={t('청약서 요청이 완료되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='subsReqComplete'
      />
      <Toast
        theme={type}
        text={t('파일 정보가 저장되었습니다.')}
        start={toast}
        setStart={setToast}
        toastName='saveThisPage'
      />
      <Toast
        theme={type}
        text={t('요청서를 제출했습니다.')}
        start={toast}
        setStart={setToast}
        toastName='reqSubmit'
      />
      <div className='frame-cover'>
        <div className={`frame ${type} gap8 t56`}>
          <Appbar
            theme={type}
            type='text'
            title={t('상품 신청 정보')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => popClick(),
            }}
          />
          <div className={`product-app-info-product col-start gap12 bgBG2 bg2Shadow ${type}`}>
            <img src={logoAxa} alt='product logo' />
            <div className={`b20 c1 ${type}`}>Wealth Advance Savings Series II - Ultimate</div>
          </div>
          <div className={`col-stretch bgBG2 bg2Shadow ${type}`}>
            <SectionTitle
              theme={type}
              title={t('진행 단계')}
            />
            <div className='product-app-info-steps gap2'>
            <ProductAppStep
              theme={type}
              title={t('설계서 발급')}
              child={
                <div>
                  {t('Step 1/3 - 설계서 요청서를 작성해주세요.')}
                </div>
              }
              active={active[0] === 0}
              isLast={false}
              reject={active[0] === 0 && active[1] === 'reject'}
            />
            <ProductAppStep
              theme={type}
              title={t('청약서 작성')}
              active={active[0] === 1}
              isLast={false}
              reject={active[0] === 1 && active[1] === 'reject'}
              child={
                <div>
                  {t('Step 1/3 - 설계서 요청서를 작성해주세요.')}
                </div>
              }
            />
            <ProductAppStep
              theme={type}
              title={t('청약서 심사')}
              active={active[0] === 2}
              isLast={false}
              reject={active[0] === 2 && active[1] === 'reject'}
              child={
                <div>
                  {t('Step 1/3 - 설계서 요청서를 작성해주세요.')}
                </div>
              }
            />
            <ProductAppStep
              theme={type}
              title={t('청약 완료')}
              active={active[0] === 3}
              isLast={true}
              reject={active[0] === 3 && active[1] === 'reject'}
              child={
                <div>
                  {t('Step 1/3 - 설계서 요청서를 작성해주세요.')}
                </div>
              }
            />
            </div>
          </div>
          {
            active[1] === 'reject' ?
            <div className={`col-stretch bgBG2 bg2Shadow ${type}`}>
              <SectionTitle
                theme={type}
                title={t('수정 안내')}
              />
              <div className={`product-app-info-text-wrap c1 m15 ${type}`}>
                {rejectMsg}
              </div>
            </div> : null
          }
          {
            active[0] === 5 ?
            <div className={`col-stretch radius8 bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('우편 발송 안내')}
              />
              <div className={`product-app-info-text-wrap c1 m15 ${type}`}>
                · {t('발송 주소 : HONGKONG CITY')}<br/>
                · {t('해외 발송 시 주의 사항')}
              </div>
            </div> : null
          }
          {
            active[0] === 0 ?
            <div className={`col-stretch radius8 bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('설계서 요청')}
              />
              <div className='product-app-info-btn-wrap'>
                {/* <MiddleBtn
                  theme={type}
                  type='secondary'
                  text={t('설계서 요청')}
                  icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                  iconLocation='right'
                  onClick={() => push("PlanRequestPage", {taskId: '', logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast, refresh: () => {}, productCode: data.productCode})}
                /> */}
              </div>
            </div> :
            active[0] === 2 ?
            <div className={`col-stretch radius8 bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('청약서 요청')}
              />
              <div className='product-app-info-btn-wrap'>
                {/* <MiddleBtn
                  theme={type}
                  type='secondary'
                  text={t('청약서 요청')}
                  icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                  iconLocation='right'
                  onClick={() => push("SubsRequestPage", {
                    logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast,
                    taskId: '', refresh: () => {},
                  })}
                /> */}
              </div>
            </div> :
            active[0] === 3 ?
            <div className={`col-stretch radius8 bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('청약서 요청 재입력')}
              />
              <div className='product-app-info-btn-wrap'>
                {/* <MiddleBtn
                  theme={type}
                  type='secondary'
                  text={t('청약서 요청 재입력')}
                  icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                  iconLocation='right'
                  onClick={() => push("SubsRequestPage", {
                    logo: logoAxa, productName: 'Wealth Advance Savings Series II - Ultimate', setToast: setToast,
                    taskId: '', refresh: () => {},
                  })}
                /> */}
              </div>
            </div> : null
          }
          {
            active[0] === 4 ?
            <div className={`col-stretch radius8 bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('서류 제출')}
              />
              <div className='product-app-info-btn-wrap col-stretch gap20'>
                <UploadInput
                  theme={type}
                  label={t('여권 사본')}
                  info={[t('· 유효기간 6개월 이상 / 서명 必 / 컬러본')]}
                  file={fileA}
                  setFile={setFileA}
                  handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileA(e)}
                  btn={{
                    text: t('업로드'),
                    onClick: () => openFileFinder('fileA')
                  }}
                  state={'normal'}
                  id={'fileA'}
                  validation={fileValidA !== '' ? false : true}
                  validMessage={t('파일이 업로드되지 않았습니다.')}
                />
                <div className={`col-divider bg15 ${type}`} />
                <UploadInput
                  theme={type}
                  label={t('영문 주민등록 등본')}
                  info={[t('· 발급일자 3개월 이내 / 컬러본'), t('· 영문등본에 수익자 미기재 시 수익자 여권 첨부')]}
                  file={fileB}
                  setFile={setFileB}
                  handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileB(e)}
                  btn={{
                    text: t('업로드'),
                    onClick: () => openFileFinder('fileB')
                  }}
                  state={'normal'}
                  id={'fileB'}
                  validation={fileValidB !== '' ? false : true}
                  validMessage={t('파일이 업로드되지 않았습니다.')}
                />
                <div className={`col-divider bg15 ${type}`} />
                <UploadInput
                  theme={type}
                  label={t('송금증 또는 카드 결제 시 전표')}
                  info={[t('· 사본 앞/뒤 포함')]}
                  file={fileC}
                  setFile={setFileC}
                  handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileC(e)}
                  btn={{
                    text: t('업로드'),
                    onClick: () => openFileFinder('fileC')
                  }}
                  state={'normal'}
                  id={'fileC'}
                  validation={fileValidC !== '' ? false : true}
                  validMessage={t('파일이 업로드되지 않았습니다.')}
                />
              </div>
            </div> : null
          }
          <div className='product-app-info-btns-wrap col-stretch gap16'>
            {
              active[0] === 4 ?
              <BigBtn
                theme={type}
                type='primary'
                text={t('저장')}
                onClick={() => saveClick()}
              /> : null
            }
            <BigBtn
              theme={type}
              type='otherWarning'
              text={t('상품 신청 취소')}
              onClick={() => console.log('sdf')}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductApplicationInfoPage;