const guides = {
  'irr&fullfillmentRatio': {
    title: '이행률이란?',
    content: [
      {type: 'title', 'text': '이행률이라는건 뭔가요?'},
      {type: 'p1', 'text': '이행률은 보험사에서 예상 배당금 대비 실제로 얼마나 배당했는지를 나타내는 지표입니다.'},
      {type: 'p1', 'text': '법적으로 매년 보험사의 홈페이지에 의무적으로 공시해야하며 인슐린은 해당 정보를 가져와 고객에게 제공합니다.'},
      {type: 'p1', 'text': '(실제 배당금 / 예상 배당금) * 100(%) 로 계산됩니다.'},
      {type: 'p1', 'text': '앱 내에서의 이행률 평균은 해당 보험사가 공시한 전체 기간에 대한 평균값으로 표시됩니다.'},
      // {type: 'p1', 'text': ''},
      // {type: 'p1', 'text': ''},
      // {type: 'title', 'text': '예상 연평균 이율은 어떻게 계산되는 건가요?'},
      // {type: 'p1', 'text': '인슐린에서 표시하는 예상 연평균 이율은 보험사에서 제공한 해당 상품 설계서의 Basic Plan 기준 최초 납입년부터 20년간의 IRR(내부 수익률)을 계산하여 제공하고 있습니다.'},
    ],
  },
  'companyCredit': {
    title: '신용평가사 등급 참고표',
    content: [
      {type: 'creditExpTable'},
    ],
  },
};

export default guides;
