import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import NoticeItem from 'components/setting/notice_item';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const NoticeListPage:ActivityComponentType = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();

  const noticeItems = [
    {title: '개인정보 처리방침 개정 안내', date: 1711949791000, text: '개인정보 처리방침 개정 안내'},
  ];

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} gap24 t56`}>
          <Appbar
            theme={type}
            type='text'
            title={t('공지사항')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className={`col-stretch bgBG2 bg2Shadow ${type}`}>
            {
              noticeItems.map((item: {title: string, date: Number, text: string}, idx: number) => {
                return (
                  <NoticeItem
                    theme={type}
                    title={item.title}
                    date={item.date}
                    text={item.text}
                    isLastItem={idx + 1 === noticeItems.length}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default NoticeListPage;