import React from 'react';

interface Props{
  theme: string;
  stepLength: number;
  currentStep: number;
  label: string;
}

const ProgressBar:React.FC<Props> = (props) => {
  return (
    <div className='col-stretch gap12'>
      <div className='progress-bar-head'>
        <div className={`progress-bar-label b17 c1 ${props.theme}`}>{props.label}</div>
        <div className={`progress-bar-step b17 c1 ${props.theme}`}>{props.currentStep.toString()}/{props.stepLength.toString()}</div>
      </div>
      <div className={`progress-bar row-center gap4 radius12`}>
        {
          Array.from({ length: props.stepLength }, (_, index) => (
            <div key={index} className={`progressbar-item ${index + 1 <= props.currentStep ? ' bgSF' : ' bgSFE'} ${props.theme}`} />
          ))
        }
      </div>
    </div>
  );
};

export default ProgressBar;