import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props{
  theme: string;
  text: string;
  start: string;
  setStart: Dispatch<SetStateAction<string>>;
  toastName: string;
}

const Toast:React.FC<Props> = (props) => {
  const [animationName, setAnimationName] = useState('false');

  useEffect(() => {
    if (
      (props.start === props.toastName)
    ) {
      if (animationName === 'false') {
        props.setStart('');
        setAnimationName('toast1');
        setTimeout(() => {
          props.setStart('');
          setAnimationName('false');
        }, 3000);
      } else if (animationName === 'toast1') {
        props.setStart('');
        setAnimationName('toast2');
        setTimeout(() => {
          props.setStart('');
          setAnimationName('false');
        }, 3000);
      } else if (animationName === 'toast2') {
        props.setStart('');
        setAnimationName('toast1');
        setTimeout(() => {
          props.setStart('');
          setAnimationName('false');
        }, 3000);
      } 
    }
  }, [animationName, props, props.start]);

  return (
    <div className={`toast-wrap radius24 borderC1 bg15 ${props.theme}`} style={{animationName: animationName}}>
      <div style={{whiteSpace: 'pre'}} className={`c1 m17 ${props.theme}`}>{props.text}</div>
    </div>
  );
};

export default Toast;