import { AppScreen } from '@stackflow/plugin-basic-ui';
import BigBtn from 'components/btn/big_btn';
import DropdownInput from 'components/input/dropdown_input';
import DropdownSheet from 'components/sheet/dropdown_sheet';
import { flagCn, flagHk, flagJp, flagKr, flagTh, flagTw, flagVn } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { locationSlice } from 'store/reducers/locationReducer';
import { RootState } from 'store/store';

const SelectLocationPage = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { push } = useFlow();
  const dispatch = useDispatch();

  const flagItems = [
    { name: t('대한민국'), flag: flagKr, disabled: false, id: 'kr'},
    { name: t('日本'), flag: flagJp, disabled: false, id: 'jp' },
    { name: t('中國'), flag: flagCn, disabled: false, id: 'cn' },
    { name: t('香港'), flag: flagHk, disabled: false, id: 'hk' },
    { name: t('台灣'), flag: flagTw, disabled: false, id: 'tw' },
    { name: t('ประเทศไทย'), flag: flagTh, disabled: false, id: 'th' },
    { name: t('Việt Nam'), flag: flagVn, disabled: false, id: 'vn' }
  ];

  const [selectedItem, setSelectedItem] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const dropdownClose = () => {
    setDropdownOpen(false);
  };

  const locationChangeAndPush = () => {
    dispatch(locationSlice.actions.locationChange({location: flagItems[selectedItem].id}));
    push("IntroducePage", {});
  };

  return (
    <AppScreen>
      {
        dropdownOpen ? 
        <DropdownSheet
          theme={type}
          type='flag'
          flagItems={flagItems}
          onClose={dropdownClose}
          setSelectedItem={setSelectedItem}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} t56 p20 bgBG2`}>
          <div className='col-stretch gap20'>
            <div className='b20 c1 select-location-label'>{t('국적을 선택해주세요')}</div>
            <DropdownInput
              theme={type}
              type='flag'
              flagItem={[
                { name: t('대한민국'), flag: flagKr },
                { name: t('日本'), flag: flagJp },
                { name: t('中國'), flag: flagCn },
                { name: t('香港'), flag: flagHk },
                { name: t('台灣'), flag: flagTw },
                { name: t('ประเทศไทย'), flag: flagTh },
                { name: t('Việt Nam'), flag: flagVn }
              ]}
              selectedItem={selectedItem}
              onClick={() => dropdownToggle()}
              placeholder={t('국적을 선택해주세요')}
            />
          </div>
          <div className='fixed-bottom-btn'>
            <BigBtn
              theme={type}
              type='primary'
              text={t('다음')}
              onClick={() => locationChangeAndPush()}
              fill={true}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SelectLocationPage;