import { arrowBackDark, arrowBackLight, arrowFrontDark, arrowFrontLight } from 'configs/icon_route';
import React, { Dispatch, SetStateAction, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  value: Date | null;
  setValue: Dispatch<SetStateAction<Date | null>>;
  setValid: Dispatch<SetStateAction<string>>;
}

const MonthPicker:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const today = new Date();
  const maxDate = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());

  const renderMonthContent = (month: any) => {
    if (month === 0) {
      return t('1월');
    } else if (month === 1) {
      return t('2월');
    } else if (month === 2) {
      return t('3월');
    } else if (month === 3) {
      return t('4월');
    } else if (month === 4) {
      return t('5월');
    } else if (month === 5) {
      return t('6월');
    } else if (month === 6) {
      return t('7월');
    } else if (month === 7) {
      return t('8월');
    } else if (month === 8) {
      return t('9월');
    } else if (month === 9) {
      return t('10월');
    } else if (month === 10) {
      return t('11월');
    } else {
      return t('12월');
    }
  };

  return (
    <ReactDatePicker
      // formatWeekDay={(nameOfDay) => returnWeekDay(nameOfDay)}
      dateFormat='yyyy/MM'
      showMonthYearPicker
      showFullMonthYearPicker
      shouldCloseOnSelect
      minDate={today}
      maxDate={maxDate}
      selected={props.value}
      onChange={(date:Date) => {
        props.setValue(date);
        props.setValid('');
      }}
      customInput={
        <div className={`month-picker-input ${props.value ? 'c1' : 'c10'} m17 radius8 borderC13 bgBG2 ${props.theme}`}>
          {
            props.value ?
            `${(props.value.getFullYear().toString())}/${(props.value.getMonth() + 1).toString()}` : t('신용카드 유효기간 입력...')
          }
        </div>
      }
      popperClassName='date-period-popper'
      calendarClassName={`date-period-calendar bgBG2 ${props.theme}`}
      renderMonthContent={renderMonthContent}
      renderCustomHeader={({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className={`date-period-popper-header row-center bgBG2 ${props.theme}`}>
          <button
            type='button'
            onClick={decreaseYear}
            disabled={prevMonthButtonDisabled}
            className='date-period-popper-arrow'
          >
            <img src={props.theme === 'dark' ? arrowBackDark : arrowBackLight} alt='decrease month' />
          </button>
          <div className={`row-center gap4`}>
            <div className={`m17 c1 ${props.theme}`}>{date.getFullYear()}</div>
          </div>
          <button
            type='button'
            onClick={increaseYear}
            disabled={nextMonthButtonDisabled}
            className='date-period-popper-arrow'
          >
            <img src={props.theme === 'dark' ? arrowFrontDark : arrowFrontLight} alt='increase month' />
          </button>
        </div>
      )}
    />
  );
};

export default MonthPicker;