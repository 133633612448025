import React from 'react';

interface Props{
  theme: string;
  active: boolean;
  onClick: any;
  leftText: string;
  rightText: string;
  size: string; //small, normal
  multiSwitchIdx?: number;
}

const SwitchTextBtn:React.FC<Props> = (props) => {
  return (
    <div
      className={`switch-text-btn ${props.size} ${props.theme} ${props.size === 'normal' ? 'border1010 bg15' : props.theme === 'light' ? 'bg16' : 'bgBG1'} ${props.size}`}
      onClick={props.multiSwitchIdx || props.multiSwitchIdx === 0 ? (e:React.MouseEvent<HTMLDivElement>) => props.onClick(e, props.multiSwitchIdx) : props.onClick}
    >
      <div className={`switch-text-btn-area bgBG2 ${props.theme} ${props.active ? 'active' : 'inactive'} ${props.size}`} />
      <div className={`switch-text-btn-text left m13 ${props.active ? 'c1' : 'c7'} ${props.theme} ${props.size}`}>{props.leftText}</div>
      <div className={`switch-text-btn-text right m13 ${props.active ? 'c7' : 'c1'} ${props.theme} ${props.size}`}>{props.rightText}</div>
    </div>
  );
};

export default SwitchTextBtn;