import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { eyeCloseDark, eyeCloseLight, eyeOpenDark, eyeOpenLight } from '../../configs/icon_route';
import { useTranslation } from 'react-i18next';

interface Props {
  theme: string;
  label?: string;
  subLabel?: string;
  inputValue: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  eyeOpen?: boolean;
  setEyeOpen?: Dispatch<SetStateAction<boolean>>;
  icon: boolean;
  // btn?: any; // type, text, disabled, icon, onClick
  validation: boolean; // true: 정상, false: 에러
  validMessage?: string;
  inputType?: string;
  countDown?: string;
  blockType?: boolean;
  inputClick?: () => void;
  setOnFocus?: Dispatch<SetStateAction<boolean>>;
  onFocus?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
}

const TextInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [onFocus, setOnFocus] = useState(false);

  // useEffect(() => {
  //   if (props.autoFocus) {
  //     setTimeout(() => {
  //       inputClick();
  //     }, 1000);
  //   }
  // }, [props.autoFocus]);

  const inputClick = () => {
    if (inputRef && inputRef.current !== null) {
      inputRef.current.focus();
      // inputRef.current.scrollIntoView();
    }
  };

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const toggleEye = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (props.setEyeOpen) {
      props.setEyeOpen(!props.eyeOpen);
    }
  };

  const changeFocus = () => {
    if (props.setOnFocus) {
      props.setOnFocus(true);
    } else {
      setOnFocus(true);
    }
  };

  const changeBlur = () => {
    if (props.setOnFocus) {
      props.setOnFocus(false);
    } else {
      setOnFocus(false);
    }
  };

  return (
    <div className={`text-input`} onClick={props.inputClick ? props.inputClick : () => inputClick()}>
      {props.label && (
        <div className="text-input-labels-wrap">
          <div className={`m15 c1 ${props.theme}`}>{props.label}</div>
          {props.subLabel ? <div className={`m13 c7 ${props.theme}`}>{props.subLabel}</div> : null}
        </div>
      )}
      <div
        className={`text-input-wrap ${
          (props.onFocus && props.setOnFocus) || (onFocus && !props.setOnFocus) ? 'borderC1' : 'borderC13'
        } ${props.theme === 'dark' ? 'bgBG2' : 'bg17'} ${props.theme} ${props.icon ? ' icon' : ''}${
          props.disabled && 'disabled'
        }`}
      >
        {props.blockType && !props.inputValue ? (
          <div className={`m17 c8 ${props.theme} text-input-area`}>{props.placeholder}</div>
        ) : props.blockType && props.inputValue ? (
          <div className={`m17 c1 ${props.theme} text-input-area`}>{props.inputValue}</div>
        ) : (
          <input
            className={`m17 c1 ${props.theme} text-input-area`}
            value={props.inputValue}
            onChange={(e) => inputChange(e)}
            type={
              props.inputType ? props.inputType : props.eyeOpen ? 'text' : props.eyeOpen === false ? 'password' : 'text'
            }
            placeholder={props.placeholder}
            ref={inputRef}
            onFocus={() => changeFocus()}
            onBlur={() => changeBlur()}
            maxLength={props.maxLength}
            disabled={props.disabled}
          />
        )}
        {props.icon ? (
          <div className="text-input-icon-area" onClick={(e: React.MouseEvent<HTMLDivElement>) => toggleEye(e)}>
            <img
              src={
                props.theme === 'dark' && !props.eyeOpen
                  ? eyeOpenDark
                  : props.theme === 'dark' && props.eyeOpen
                  ? eyeCloseDark
                  : props.theme === 'light' && !props.eyeOpen
                  ? eyeOpenLight
                  : eyeCloseLight
              }
              alt={props.eyeOpen ? t('비밀번호 보기') : t('비밀번호 가리기')}
            />
          </div>
        ) : null}
        {props.countDown ? <div className={`colorRed m13`}>{props.countDown}</div> : null}
      </div>
      {props.validation === false && props.validMessage ? (
        <div className={`m13 colorRed ${props.theme}`}>{props.validMessage}</div>
      ) : null}
    </div>
  );
};

export default TextInput;
