import React from 'react';

interface Props{
  theme: string;
  title: string;
  active: boolean;
  isLast: boolean;
  reject: boolean;
  child: any;
  inactiveChild?: any;
}

const ProductAppStep:React.FC<Props> = (props) => {
  return (
    <div className='col-stretch gap2'>
      <div className='row-center gap8'>
        <div className={`product-app-step-circle ${props.reject ? 'bgRed' : props.active ? 'bgS' : 'bg12'} ${props.theme}`} />
        <div className={`b17 ${props.active ? 'c1' : 'c10'} ${props.theme}`}>{props.title}</div>
      </div>
      {
        !props.isLast ? 
        <div className='product-app-step-text-wrap'>
          <div className={`product-app-step-text c9 m15 ${props.theme} ${props.theme}`}>
            {
              props.active ?
              props.child : props.inactiveChild
            }
          </div>
        </div> : null
      }
    </div>
  );
};

export default ProductAppStep;