import { AppScreen } from '@stackflow/plugin-basic-ui';
import Appbar from 'components/appbar/appbar';
import MiddleBtn from 'components/btn/middle_btn';
import SectionTitle from 'components/common/section_title';
import { arrowBackDark, arrowBackLight, clacTimes, graphScroll } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import SmallBtn from 'components/btn/small_btn';
import BarGraph from 'components/product/bar_graph';
import AccountValueTable from 'components/table/account_value_table';
import DropdownInput from 'components/input/dropdown_input';
import DropdownSheet from 'components/sheet/dropdown_sheet';
import { returnAmountUnit, returnExchange } from 'utils/common/functions';


const data = [
  {year: '1', value: 0},
  {year: '2', value: 0},
  {year: '3', value: 26534},
  {year: '4', value: 40943},
  {year: '5', value: 58032},
  {year: '10', value: 125508},
  {year: '15', value: 173934},
  {year: '20', value: 253894},
  {year: '25', value: 352953},
  {year: '30', value: 499436},
  {year: 'a65', value: 5629380},
  {year: 'a70', value: 8051852},
  {year: 'a75', value: 11704373},
  {year: 'a80', value: 17066195},
  {year: 'a85', value: 24942197},
  {year: 'a90', value: 35433157},
  {year: 'a95', value: 50392256},
  {year: 'a100', value: 71727543},
];

const pessiData = [
  {year: '1', value: 0},
  {year: '2', value: 0},
  {year: '3', value: 25699},
  {year: '4', value: 39335},
  {year: '5', value: 54808},
  {year: '10', value: 105009},
  {year: '15', value: 129773},
  {year: '20', value: 168322},
  {year: '25', value: 207163},
  {year: '30', value: 256813},
  {year: 'a65', value: 1271252},
  {year: 'a70', value: 1617268},
  {year: 'a75', value: 2102038},
  {year: 'a80', value: 2727525},
  {year: 'a85', value: 3570427},
  {year: 'a90', value: 4565533},
  {year: 'a95', value: 5956205},
  {year: 'a100', value: 7598448},
];

const optiData = [
  {year: '1', value: 0},
  {year: '2', value: 0},
  {year: '3', value: 27345},
  {year: '4', value: 42420},
  {year: '5', value: 61250},
  {year: '10', value: 147780},
  {year: '15', value: 228206},
  {year: '20', value: 371782},
  {year: '25', value: 578759},
  {year: '30', value: 917879},
  {year: 'a65', value: 22822029},
  {year: 'a70', value: 36588545},
  {year: 'a75', value: 59284011},
  {year: 'a80', value: 95085055},
  {year: 'a85', value: 147688875},
  {year: 'a90', value: 229429292},
  {year: 'a95', value: 366649161},
  {year: 'a100', value: 593683658},
];

const plans = ['y5000', 'y10000', 'y20000'];

const baseValue = 100000;

const ProductPlanPage = ({params}: any) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { unit } = useSelector((state:RootState) => state.exchange);
  const { pop, push } = useFlow();
  const { t } = useTranslation();

  const plansName = plans.map((item:string, idx:number) => (t('연납') + ' ' + parseInt(item.replace('y', '')).toLocaleString() + ' USD'));
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [planDropdown, setPlanDropdown] = useState(false);
  const planDropdownClose = () => {
    setPlanDropdown(false);
  };

  const [switchA, setSwitchA] = useState(true);
  const switchToggleA = () => {
    setSwitchA(!switchA);
  };

  const [switchB, setSwitchB] = useState(true);
  const switchToggleB = () => {
    setSwitchB(!switchB);
  };

  const [switchC, setSwitchC] = useState(true);
  const switchToggleC = () => {
    setSwitchC(!switchC);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
  e.preventDefault(); // 기본 터치 동작(스크롤 등) 방지
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault(); // 컨텍스트 메뉴 방지
  };

  const [guide, setGuide] = useState(true);

  console.log(params);

  const product = products.filter((product) => product.code === params.data.productCode)[0];

  return (
    <AppScreen>
      {
        planDropdown ?
        <DropdownSheet
          theme={type}
          items={plansName}
          onClose={planDropdownClose}
          setSelectedItem={setSelectedPlan}
        /> : null
      }
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={t('설계서')}
          color='bgBG2'
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame bgBG1 hasAppbar ${type}`}>
          <div className='col-stretch gap10'>
            <div className={`product-info-head col-stretch gap12 bgBG2 ${type}`}>
              <div className='row-center gap6'>
                <img src={product.logo} alt='product logo' />
                <div className='m13'>{product.company}</div>
              </div>
              <div className={`c1 b20 ${type}`}>{product.name}</div>
              <DropdownInput
                theme={type}
                item={plansName}
                selectedItem={selectedPlan}
                onClick={() => setPlanDropdown(true)}
                placeholder={t('설계서를 선택해 주세요.')}
              />
              <MiddleBtn
                theme={type}
                type='secondary'
                text={t('설계서 원본 보기')}
                onClick={() =>  push("PdfViewer", {title: '설계서 열람', url: params.data.estimateFiles[0]})}
              />
            </div>
            <div className={`col-stertch bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('납입 정보')} // t check
                switch={true}
                switchActive={switchB}
                switchToggle={switchToggleB}
              />
              <div className='product-plan-summary-wrap col-stretch gap12'>
              {/* t check */}
                <div className={`b20 c6 ${type}`}>{t('고객님의 납입 정보입니다.')}</div>
                <div className='col-stretch gap16'>
              {/* t check */}
                  <div className={`m17 c6 ${type}`}>{t('아래 납입 정보에 기반하여 설계되었습니다.')}</div>
                  <div className='col-stretch gap8'>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>A</div>
              {/* t check */}
                      <div className={`c7 m15 ${type}`}>{`${t('매년')} 100,000 ${switchB ? 'USD' : 'KRW'} ${t('납입')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>B</div>
              {/* t check */}
                      <div className={`c7 m15 ${type}`}>{`5 ${t('년간')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`row-center gap2`}>
                        <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>A</div>
                        <img className='product-plan-times' src={clacTimes} alt='times' />
                        <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>B</div>
                      </div>
              {/* t check */}
                      <div className={`c7 m15 ${type}`}>{`${t('총')} 500,000 ${switchB ? 'USD' : 'KRW'} ${t('납입')}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`col-stertch bgBG2 ${type}`}>
              <RowItem
                theme={type}
                label={t('이름')}
                text={'홍길동'}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('나이')}
                text={'40세'}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('성별')}
                text={t('남성')}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('흡연여부')}
                text={t('비흡연자')}
                isLastItem={true}
              />
            </div>
            <div className={`col-stertch bgBG2 ${type}`}>
              <RowItem
                theme={type}
                label={t('연납 보험료')}
                text={'USD 20,000'}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('납입기간')}
                text={'5' + t('년')}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('보장기간')}
                text={'100' + t('년')}
                isLastItem={true}
              />
            </div>
            <div className={`col-stertch bgBG2 ${type}`}>
              <RowItem
                theme={type}
                label={t('인출 시작')}
                text={'30' + t('년차')}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('인출 종료')}
                text={'60' + t('년차')}
                isLastItem={false}
              />
              <RowItem
                theme={type}
                label={t('연간 인출금액')}
                text={'USD 20,000'}
                isLastItem={true}
              />
            </div> */}
            <div className={`col-stertch bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={<><span className='tSLG b15'>{t('미 인출 시')}</span> {t('예상 환급금')}</>}
                switch={true}
                switchActive={switchA}
                switchToggle={switchToggleA}
              />
              <div className='product-plan-refund-summary-wrap col-stretch gap12'>
              {/* t check */}
                <div className={`b20 c6 ${type}`}>{t('‘미 인출 시’ 예상 환급금입니다.')}</div>
              {/* t check 변경 */}
                <div className={`m17 c6 ${type}`}>{t('보험사에서 제공하는 Basic Plan 시나리오입니다.')}</div>
              </div>
              {/* <div
                className={`product-plan-graph-wrap bgBG2 ${type}`} 
                onTouchMove={handleTouchMove}
                onContextMenu={handleContextMenu}
              >
                {
                  guide ?
                  <div className={`product-plan-graph-cover bgBlack05 gap16 col-center ${type}`}>
                    <img src={graphScroll} alt='graph horizontal scroll' />
                    <div className={`colorWhite m15 ${type}`}>{t('좌우로 스크롤하여 더보기')}</div>
                    <SmallBtn
                      theme={type}
                      type='secondary'
                      text={t('그래프 보기')}
                      onClick={() => setGuide(false)}
                    />
                  </div>
                  : null
                }
                {
                  <BarGraph chartData={data} unit={switchA ? 'USD' : 'KRW'} />
                }
              </div> */}
              <div className='product-plan-table-wrap col-stretch gap12'>
                <AccountValueTable
                  theme={type}
                  data={
                    data.map((item: {year: string, value: number}) => {
                      return (
                        {
                          year: item.year,
                          value: item.value,
                          more: (<div className='m13'><span className={`b13 tSLG ${type}`}>{Math.round(item.value * 10 / baseValue) / 10}</span>{t('배')}</div>)
                        }
                      )
                    })
                  }
                  type={t('normal')}
                  unit={switchA ? 'USD' : 'KRW'}
                />
                {/* <MiddleBtn
                  theme={type}
                  type='secondary'
                  text={t('연차별 환급금 전체보기')}
                  onClick={() => push('ProductPlanDetailPage', {data: data, pessiData: pessiData, optiData: optiData, baseValue: baseValue})}
                /> */}
              </div>
            </div>
            {/* <div className={`col-stertch bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('인출 정보')} // t check
                switch={true}
                switchActive={switchC}
                switchToggle={switchToggleC}
              />
              <div className='product-plan-summary-wrap col-stretch gap12'>
                <div className={`b20 c6 ${type}`}>{t('고객님의 인출 계획입니다.')}</div>
                  <div className='col-stretch gap8'>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`c7 m15 ${type}`}>{`${t('(인출안내)초기값before')}${t('(인출안내)인출 시작')} 30 ${t('(인출안내)년차에 환급금')} 390,427 ${switchC ? 'USD' : 'KRW'} ${t('(인출안내)초기값after')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>A</div>
                      <div className={`c7 m15 ${type}`}>{`${t('(인출안내)기간before')}30${t('(인출안내)년차 ~')} 39${t('(인출안내)년차(')}10${t('(인출안내)년) 동안')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>B</div>
                      <div className={`c7 m15 ${type}`}>{`${t('매년')} 100,000 ${switchC ? 'USD' : 'KRW'} ${t('인출')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`row-center gap2`}>
                        <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>A</div>
                        <img className='product-plan-times' src={clacTimes} alt='times' />
                        <div className={`product-plan-sCircle bgSF c17 b13 radius8 ${type}`}>B</div>
                      </div>
                      <div className={`c7 m15 ${type}`}>{`${t('총')} 1,000,000 ${switchB ? 'USD' : 'KRW'} ${t('인출')}`}</div>
                    </div>
                    <div className={`product-plan-info-square borderC14 radius8 row-center flex-center gap8 ${type}`}>
                      <div className={`c7 m15 ${type}`}>{`${t('(인출안내)인출 후')} 39 ${t('(인출안내)년차에 잉여금')} 320,427 ${switchC ? 'USD' : 'KRW'}`}</div>
                    </div>
                  </div>
              </div>
            </div> */}
            {/* <div className={`col-stertch bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={<><span className='tSLG b15'>{t('인출 시')}</span> {t('예상 환급금')}</>}
                switch={true}
                switchActive={switchA}
                switchToggle={switchToggleA}
              />
              <div className='product-plan-refund-summary-wrap col-stretch gap12'>
                <div className={`b20 c6 ${type}`}>{t('‘인출 시’ 예상 환급금입니다.')}</div>
                <div className={`m17 c6 ${type}`}>{t('보험사에서 제공하는 환급금 정보는 이행률 100%인 시나리오를 가정한 것입니다.')}</div>
              </div>
              <div
                className={`product-plan-graph-wrap bgBG2 ${type}`} 
                onTouchMove={handleTouchMove}
                onContextMenu={handleContextMenu}
              >
                {
                  guide ?
                  <div className={`product-plan-graph-cover bgBlack05 gap16 col-center ${type}`}>
                    <img src={graphScroll} alt='graph horizontal scroll' />
                    <div className={`colorWhite m15 ${type}`}>{t('좌우로 스크롤하여 더보기')}</div>
                    <SmallBtn
                      theme={type}
                      type='secondary'
                      text={t('그래프 보기')}
                      onClick={() => setGuide(false)}
                    />
                  </div>
                  : null
                }
                {
                  <BarGraph chartData={data} unit={switchA ? 'USD' : 'KRW'} />
                }
              </div>
              <div className='product-plan-table-wrap col-stretch gap12'>
                <AccountValueTable
                  theme={type}
                  data={
                    data.map((item: {year: string, value: number}) => {
                      return (
                        {
                          year: item.year,
                          value: item.value,
                          more: (<div className='m13'><span className={`b13 tSLG ${type}`}>{Math.round(item.value * 10 / baseValue) / 10}</span>{t('배')}</div>)
                        }
                      )
                    })
                  }
                  type={t('normal')}
                  unit={switchA ? 'USD' : 'KRW'}
                />
                <div className={`supplementary-summary borderC14 radius8 m15 flex-center gap4 ${type}`}>
                  {t('총 인출 금액')} : <span className='tSLG b15'>{returnAmountUnit(Math.floor(returnExchange(531215, switchA ? 'USD' : 'KRW')), switchA ? 'USD' : 'KRW')} {switchA ? 'USD' : 'KRW'}</span>
                </div>
                <MiddleBtn
                  theme={type}
                  type='secondary'
                  text={t('연차별 환급금 전체보기')}
                  onClick={() => push('ProductPlanDetailPage', {data: data, pessiData: pessiData, optiData: optiData, baseValue: baseValue})}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductPlanPage;
