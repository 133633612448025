import React, { useEffect, useState } from 'react';
import Appbar from '../../../components/appbar/appbar';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlow } from '../../../configs/stackflow';
import { RootState } from '../../../store/store';
import { arrowBackDark, arrowBackLight, logoAxa } from '../../../configs/icon_route';
import SectionTitle from '../../../components/common/section_title';
import DropdownInput from '../../../components/input/dropdown_input';
import DropdownSheet from '../../../components/sheet/dropdown_sheet';
import TextInput from '../../../components/input/text_input';
import MiddleBtn from '../../../components/btn/middle_btn';
import RowItem from '../../../components/common/row_item';

const ProductRefundCalcPage = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const {t} = useTranslation();
  const {pop} = useFlow();

  const [switchA, setSwitchA] = useState(true);
  const switchAClick = () => {
    setSwitchA(!switchA);
  };

  const [dropdownItem, setDropdownItem] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [sheetOpen, setSheetOpen] = useState(false);

  useEffect(() => {
    let arr = [];
    for(let i=1; i<31; i++){
      arr.push(i.toString() + t('년 차'));
    }
    setDropdownItem([...arr]);
  }, [])

  const dropdownClick = () => {
    setSheetOpen(true);
  };
  const dropdownSheetClose = () => {
    setSheetOpen(false);
  };

  const [payment, setPayment] = useState(0);
  const paymentChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPayment(parseInt(e.target.value));
  };

  const [calc, setCalc] = useState(false);
  const calcClick = () => {
    setCalc(true);
  };

  return (
    <AppScreen>
      <div className='frame-cover home'>
        {
          sheetOpen ?
          <DropdownSheet
            theme={type}
            items={dropdownItem}
            onClose={() => dropdownSheetClose()}
            setSelectedItem={setSelectedItem}
            setCalc={setCalc}
          /> : null
        }
        <div className={`frame ${type} gap16 t68 p0`}>
          <Appbar
            theme={type}
            type='text'
            title={t('환급금 계산')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop()
            }}
          />
          <div className='product-refund-calc-head'>
            <img className='product-refund-calc-logo' src={logoAxa} alt='logo' />
            <div className={`b17 c1 ${type}`}>AXA Super Power 5년 납 배당 저축상품</div>
          </div>
          <div className='col-stretch gap16 p16'>
            <div className={`product-info-items-wrap bgBG2 ${type}`}>
              <SectionTitle
                theme={type}
                title={t('연차별 예상환급금')}
                switch={true}
                switchActive={switchA}
                switchToggle={() => switchAClick()}
              />
              <div className='product-refund-calc-item col-stretch gap12 p16'>
                <div className={`r15 c1 ${type}`}>· {t('현재 상품은')}{' '}6{t('년 차에 수익전환이 예상됩니다')}</div>
                <TextInput
                  theme={type}
                  label={t('납입금') + ' (' + (switchA ? '$' : t('원')) + ')'}
                  inputValue={payment}
                  inputType={'number'}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => paymentChange(e)}
                  placeholder={t('납입금 입력...')}
                  icon={false}
                  validation={true}
                />
                <DropdownInput
                  theme={type}
                  label={t('연차')}
                  item={dropdownItem}
                  selectedItem={selectedItem}
                  placeholder={t('연차 선택...')}
                  onClick={() => dropdownClick()}
                />
                <div className='product-refund-calc-btn-wrap'>
                  <MiddleBtn
                    theme={type}
                    type='primary'
                    text={t('계산하기')}
                    onClick={() => calcClick()}
                  />
                </div>
              </div>
              {
                calc ?
                <div className='product-refund-calc-infos'>
                  <RowItem
                    theme={type}
                    label={t('현금 가치')}
                    text={`1,500,000 ${t('원')}`}
                    onClick={() => console.log('123')}
                  />
                  <RowItem
                    theme={type}
                    label={t('배당금')}
                    text={`1,500,000 ${t('원')}`}
                    onClick={() => console.log('123')}
                  />
                  <RowItem
                    theme={type}
                    label={t('만기 수익')}
                    text={`1,500,000 ${t('원')}`}
                    onClick={() => console.log('123')}
                  />
                  <RowItem
                    theme={type}
                    label={t('총 예상 환급금')}
                    text={`1,500,000 ${t('원')}`}
                    onClick={() => console.log('123')}
                    isLastItem={true}
                  />
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ProductRefundCalcPage;