import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  title: string;
  url: string;
};

const PdfViewer:ActivityComponentType<Params> = ({params}) => {
  const {pop} = useFlow();
  const { type } = useSelector((state:RootState) => state.theme);

  return (
    <AppScreen>
      <div className='frame-cover'>
        <Appbar
          theme={type}
          type='text'
          title={params.title}
          left={{
            icon: type === 'dark' ? arrowBackDark : arrowBackLight,
            onClick: () => pop(),
          }}
        />
        <div className={`frame ${type} gap36 hasAppbar t48`}>
          <iframe title="pdf" src={params.url} style={{height: 'calc(100vh - 48px)'}} />
        </div>
      </div>
    </AppScreen>
  );
};

export default PdfViewer;
