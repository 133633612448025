import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useFlow } from '../../../configs/stackflow';
import Appbar from '../../../components/appbar/appbar';
import { addCircleDark, addCircleLight, arrowBackDark, arrowBackLight } from '../../../configs/icon_route';
import ProgressBar from '../../../components/common/progress_bar';
import { ActivityComponentType } from '@stackflow/react';
import TextInput from '../../../components/input/text_input';
import RadioInput from '../../../components/input/radio_input';
import BigBtn from '../../../components/btn/big_btn';
import MultiTextInput from '../../../components/input/multi_text_input';
import ModalOk from '../../../components/modal/modal_ok';
import ModalCol from 'components/modal/modal_col';
import ModalYN from 'components/modal/modal_yn';
import saveEstimateInfo from 'utils/firebase_firestore/save_estimate_req_info';
import { doc, getDoc } from 'firebase/firestore';
import db from 'configs/db';
import { getAuth } from 'firebase/auth';
import { getApp } from 'firebase/app';
import getUser from 'utils/firebase_firestore/get_user';
import getUserTasksByUser from 'utils/firebase_firestore/get_user_tasks_by_user';
import { myDataSlice } from 'store/reducers/myDataReducer';
import products from 'configs/products';

type Params = {
  logo: string;
  productName: string;
  setToast?: Dispatch<SetStateAction<string>>;
  fromWhere?: string;
  moveAppInfo?: () => void;
  taskId: string;
  refresh: Function;
  productCode: string;
}

interface PropsA {
  theme: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  nameChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  nameValid: string;
  gender: number | boolean;
  setGender: Dispatch<SetStateAction<number | boolean>>;
  genderChange: (idx: number | boolean) => void;
  genderValid: string;
  birth: string;
  setBirth: Dispatch<SetStateAction<string>>;
  birthChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  birthValid: string;
  smoking: number | boolean;
  setSmoking: Dispatch<SetStateAction<number | boolean>>;
  smokingChange: (idx: number | boolean) => void;
  smokingValid: string;
  nextClick: () => void;
  introducerPN: string;
  setIntroducerPN: any;
}

interface PropsB {
  theme: string;
  payment: string[];
  paymentChange: (e:React.ChangeEvent<HTMLInputElement>, idx: number) => void;
  paymentFocus: boolean[];
  setPaymentFocus: Dispatch<SetStateAction<boolean[]>>;
  paymentValid: string;
  addPayment: () => void;
  removePayment: (idx: number) => void;
  startAge: string;
  startAgeChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  startAgeValid: string;
  startYear: string;
  startYearChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  startYearValid: string;
  endAge: string;
  endAgeChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  endAgeValid: string;
  withdrawal: string;
  withdrawalChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  withdrawalValid: string;
  modal: string;
  setModal: Dispatch<SetStateAction<string>>;
  submitClick: () => void;
  startType: number | boolean;
  startTypeChange: (idx: number | boolean) => void;
  startTypeValid: string;
  endYear: string;
  endYearChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  endYearValid: string;
  minForYear: number;
  product: any;
  useMonthlyPayment: boolean;
  paymentDuration: number;
  setUseMonthlyPayment: any;
  setPaymentDuration: any;
}

const PlanRequestPage: ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();

  const product = products.filter((p: any) => p.code === params.productCode)[0];

  const [step, setStep] = useState(1);

  const [modal, setModal] = useState('');

  // page A
  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState('');
  const [gender, setGender] = useState<number | boolean>(false);
  const [genderValid, setGenderValid] = useState('');
  const [birth, setBirth] = useState('');
  const [birthValid, setBirthValid] = useState('');
  const [smoking, setSmoking] = useState<number | boolean>(false);
  const [smokingValid, setSmokingValid] = useState('');

  const nameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValid('');
  };
  const genderChange = (idx: number | boolean) => {
    setGender(idx);
    setGenderValid('');
  };
  const birthChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBirth(e.target.value);
    setBirthValid('');
  };
  const smokingChange = (idx: number | boolean) => {
    setSmoking(idx);
    setSmokingValid('');
  };

  const nextClick = () => {
    const namePattern = /^[A-Za-z\s]+$/;
    const genderCheck = gender === 0 || gender === 1;
    const birthPattern = /^\d{8}$/;
    const smokingCheck = smoking === 0 || smoking === 1 || smoking === 2;

    if (namePattern.test(name) && genderCheck && birthPattern.test(birth) && smokingCheck) {
      setStep(2);
    } else {
      if (!namePattern.test(name)) {
        setNameValid('format error');
      }
      if (!genderCheck) {
        setGenderValid('no Choice');
      }
      if (!birthPattern.test(birth)) {
        setBirthValid('format error');
      }
      if (!smokingCheck) {
        setSmokingValid('no Choice');
      }
      setModal('validCheck');
    }
  };

  // page B
  const [payment, setPayment] = useState<string[]>(['']);
  const [paymentValid, setPaymentValid] = useState<string>('');
  const [paymentFocus, setPaymentFocus] = useState<boolean[]>([false]);
  const [startType, setStartType] = useState<number | boolean>(false);
  const [startTypeValid, setStartTypeValid] = useState<string>('');
  const [startAge, setStartAge] = useState<string>('');
  const [startAgeValid, setStartAgeValid] = useState<string>('');
  const [endAge, setEndAge] = useState<string>('');
  const [endAgeValid, setEndAgeValid] = useState<string>('');
  const [startYear, setStartYear] = useState<string>('');
  const [startYearValid, setStartYearValid] = useState<string>('');
  const [endYear, setEndYear] = useState<string>('');
  const [endYearValid, setEndYearValid] = useState<string>('');
  const [withdrawal, setWithDrawal] = useState<string>('');
  const [withdrawalValid, setWithdrawalValid] = useState<string>('');
  const [useMonthlyPayment, setUseMonthlyPayment] = useState<boolean>(false);
  const [paymentDuration, setPaymentDuration] = useState<number>(product.durations[0]);
  const [introducerPN, setIntroducerPN] = useState<string>('');

  const paymentChange = (e:React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setPaymentValid('');
    const arr = payment;
    arr[idx] = e.target.value;
    setPayment([...arr]);    
  };
  const startTypeChange = (idx: number | boolean) => {
    setStartType(idx);
    setStartTypeValid('');
  };
  const startAgeChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setStartAgeValid('');
    setStartAge(e.target.value);
  };
  const endAgeChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEndAgeValid('');
    setEndAge(e.target.value);
  };
  const startYearChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setStartYearValid('');
    setStartYear(e.target.value);
  };
  const endYearChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEndYearValid('');
    setEndYear(e.target.value);
  };
  const withdrawalChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setWithdrawalValid('');
    setWithDrawal(e.target.value);
  };

  const addPayment = () => {
    if (payment.length < 5) {
      const arr = payment;
      const arrFocus = paymentFocus;
      arr.push('');
      arrFocus.push(false);
      setPayment([...arr]);
      setPaymentFocus([...arrFocus]);
    } else {
      setModal('paymentOverflow');
    }
  };

  const removePayment = (idx: number) => {
    const arr = payment;
    const arrFocus = paymentFocus;
    const newArr = arr.filter((item: string, index: number) => idx !== index);
    const newArrFocus = arrFocus.filter((item: boolean, index: number) => idx !== index);
    setPayment([...newArr]);
    setPaymentFocus([...newArrFocus]);
  };

  const submitClick = async () => {
    const paymentCheck = payment.every((item: string, idx: number) => item !== '');
    const paymentMinCheck = payment.every((item: string, idx: number) => parseInt(item) >= product.minForYear);
    const startTypeCheck = startType === 0 || startType === 1;
    if (
      (paymentCheck && paymentMinCheck && withdrawal !== '' && params.setToast && startType === 0 && startAge !== '' && endAge !== '') ||
      (paymentCheck && paymentMinCheck && withdrawal !== '' && params.setToast && startType === 1 && startYear !== '' && endYear !== '')
    )  {
      await saveData(true);

      params.setToast('planReqComplete');
      pop();
    } else {
      if (!paymentCheck) {
        setPaymentValid('formatError');
      }
      if (!paymentMinCheck) {
        setPaymentValid('minError');
      }
      if (!startTypeCheck) {
        setStartTypeValid('formatError');
      }
      if (startType === 0) { // age
        if (startAge === '') {
          setStartAgeValid('formatError');
        }
        if (endAge === '') {
          setEndAgeValid('formatError');
        }
      }
      if (startType === 1) { // year
        if (startYear === '') {
          setStartYearValid('formatError');
        }
        if (endYear === '') {
          setEndYearValid('formatError');
        }
      }
      if (withdrawal === '') {
        setWithdrawalValid('formatError');
      }
    }
  };

  const backClick = () => {
    setStep(1)
  };

// modal
  const openSaveModal = () => {
    setModal('save');
  };
  const closeModal = () => {
    setModal('');
  };
  const modalSaveLeave = async () => {
    if (params.setToast) {
      await saveData(false);
   
      params.setToast('reqSaved');
      closeModal();
      pop();
    } else if (params.fromWhere === 'productInfo' && params.moveAppInfo) {
      params.moveAppInfo();
      pop();
    } else {
      pop();
    }
  };
  const modalLeave = () => {
    closeModal();
    pop();
  };
  const modalSubmit = async () => {
    if (params.setToast) {
      params.setToast('reqSubmit');
      closeModal();
      pop();
    }
  };

  const dispatch = useDispatch();

  const saveData = async (confirm: boolean) => {
    await saveEstimateInfo(params.taskId, {
      enName: name,
      gender,
      birth,
      isSmoke: smoking,
      payments: payment,
      withdrawalStartAge: startAge,
      withdrawalStartYear: startYear,
      withdrawalEndYear: endAge,
      withdrawalYearRecur: withdrawal,
      introducerPN,
      paymentDuration,
      useMonthlyPayment,
    }, confirm);

    if (confirm) {
      // refresh
      params.refresh();

      const userId = getAuth(getApp()).currentUser?.uid ?? '';

      const [myData, userTasks] = await Promise.all([
        getUser(userId),
        getUserTasksByUser(userId),
      ]);
    
      dispatch(myDataSlice.actions.setData({data: myData, tasks: userTasks}));
    }
  };

  useEffect(() => {
    req();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const req = async () => {
    const d = await getDoc(doc(db.getKrTaskCol(), params.taskId));
    const {estimateReqInfo} = (d.data() as any);
    if (estimateReqInfo) {
      setName(estimateReqInfo.enName);
      setGender(estimateReqInfo.gender);
      setBirth(estimateReqInfo.birth);
      setSmoking(estimateReqInfo.isSmoke);
      setPayment(estimateReqInfo.payments);
      setStartAge(estimateReqInfo.withdrawalStartAge);
      setStartYear(estimateReqInfo.withdrawalStartYear);
      setEndAge(estimateReqInfo.withdrawalEndYear);
      setWithDrawal(estimateReqInfo.withdrawalYearRecur);
    }
  };

  return (
    <AppScreen>
      {
        modal === 'save' ?
        <ModalCol
          theme={type}
          text={t('저장되지 않은 정보가 있습니다. 저장 후 페이지를 떠나시겠습니까?')}
          btns={[
            {type: 'primary', text: t('저장 후 떠나기'), onClick: () => modalSaveLeave()},
            {type: 'otherWarning', text: t('저장하지 않고 떠나기'), onClick: () => modalLeave()},
            {type: 'other', text: t('취소'), onClick: () => closeModal()}
          ]}
        /> :
        modal === 'validCheck' ?
        <ModalOk
          theme={type}
          text={t('입력한 정보를 확인해주세요.')}
          btn={{name: t('확인'), onClick: () => closeModal()}}
        /> :
        modal === 'submitCheck' ?
        <ModalYN
          theme={type}
          text={t('작성한 정보로 설계서를 요청하시겠습니까?')}
          btnY={{name: t('확인'), onClick: () => modalSubmit()}}
          btnN={{name: t('취소'), onClick: () => closeModal()}}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} p16 bgBG2 gap24 t64`}>
          <Appbar
            theme={type}
            type='text'
            title={t('설계서 요청')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: step === 1 ? () => openSaveModal() : () => backClick(),
            }}
          />
          <ProgressBar
            theme={type}
            stepLength={2}
            currentStep={step}
            label={step === 1 ? t('보험계약자 정보 입력') : t('상품 정보 입력')}
          />
          <div className={`plan-request-product-wrap bgBG2 radius8 col-start gap12 ${type}`}>
            <img src={params.logo} alt='product logo' />
            <div className={`c1 m17 ${type}`}>{params.productName}</div>
          </div>
          {
            step === 1 ?
            <PlanRequestPageA
              theme={type}
              name={name}
              setName={setName}
              nameChange={nameChange}
              nameValid={nameValid}
              gender={gender}
              setGender={setGender}
              genderChange={genderChange}
              genderValid={genderValid}
              birth={birth}
              setBirth={setBirth}
              birthChange={birthChange}
              birthValid={birthValid}
              smoking={smoking}
              setSmoking={setSmoking}
              smokingChange={smokingChange}
              smokingValid={smokingValid}
              nextClick={nextClick}
              introducerPN={introducerPN}
              setIntroducerPN={setIntroducerPN}
            /> :
            <PlanRequestPageB
              product={product}
              useMonthlyPayment={useMonthlyPayment}
              setUseMonthlyPayment={setUseMonthlyPayment}
              paymentDuration={paymentDuration}
              setPaymentDuration={setPaymentDuration}
              theme={type}
              payment={payment}
              paymentChange={paymentChange}
              paymentValid={paymentValid}
              paymentFocus={paymentFocus}
              setPaymentFocus={setPaymentFocus}
              addPayment={addPayment}
              removePayment={removePayment}
              startAge={startAge}
              startAgeChange={startAgeChange}
              startAgeValid={startAgeValid}
              startYear={startYear}
              startYearChange={startYearChange}
              startYearValid={startYearValid}
              endAge={endAge}
              endAgeChange={endAgeChange}
              endAgeValid={endAgeValid}
              withdrawal={withdrawal}
              withdrawalChange={withdrawalChange}
              withdrawalValid={withdrawalValid}
              modal={modal}
              setModal={setModal}
              submitClick={submitClick}
              startType={startType}
              startTypeValid={startTypeValid}
              startTypeChange={startTypeChange}
              endYear={endYear}
              endYearChange={endYearChange}
              endYearValid={endYearValid}
              minForYear={product.minForYear}
            />
  // const [startType, setStartType] = useState<string>('age');
  // const [endYear, setEndYear] = useState<string>('');
  // const [endYearValid, setEndYearValid] = useState<string>('');
          }
        </div>
      </div>
    </AppScreen>
  );
};

const PlanRequestPageA:React.FC<PropsA> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('영문 이름(여권과 동일)')}
          inputValue={props.name}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.nameChange(e)}
          placeholder={t('영문 이름(여권과 동일) 입력...')}
          icon={false}
          validation={props.nameValid !== '' ? false : true}
          validMessage={t('영문 이름을 확인해주세요.')}
        />
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('성별')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.gender === 0}
              onClick={() => props.genderChange(0)}
              text={t('남성')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.gender === 1}
              onClick={() => props.genderChange(1)}
              text={t('여성')}
              padding={true}
            />
          </div>
          {
            props.genderValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('성별을 선택해주세요.')}</div> : null
          }
        </div>
        <TextInput
          theme={props.theme}
          label={t('생년월일')}
          inputValue={props.birth}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.birthChange(e)}
          placeholder={t('생년월일 8자리 입력...')}
          icon={false}
          validation={props.birthValid !== '' ? false : true}
          validMessage={t('8자리 생년월일을 입력해주세요.')}
          inputType='tel'
          maxLength={8}
        />
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('흡연여부')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.smoking === 0}
              onClick={() => props.smokingChange(0)}
              text={t('흡연자')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.smoking === 1}
              onClick={() => props.smokingChange(1)}
              text={t('비흡연자')}
              padding={true}
            />
          </div>
          {
            props.smokingValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('흡연 여부를 선택해주세요.')}</div> : null
          }
        </div>
        <TextInput
          theme={props.theme}
          label={t('소개자 전화번호')}
          inputValue={props.introducerPN}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.setIntroducerPN(e.target.value)}
          placeholder={t('필수사항 아닙니다')}
          icon={false}
          validation={props.nameValid !== '' ? false : true}
          validMessage={t('영문 이름을 확인해주세요.')}
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('다음')}
        onClick={() => props.nextClick()}
      />
    </div>
  );
};

const PlanRequestPageB:React.FC<PropsB> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='col-stretch gap36'>
      {
        props.modal === 'paymentOverflow' ?
        <ModalOk
          theme={props.theme}
          text={t('연납 보험료는 최대 5개까지 추가 가능합니다.')}
          btn={{name: t('확인'), onClick: () => props.setModal('')}}
        /> : null
      }
      <div className='col-stretch gap24'>
        <MultiTextInput
          theme={props.theme}
          label={t('연납 보험료(USD)')}
          subLabel={`: ${t('최소')} USD ${props.minForYear}`}
          info={t('다수의 보험료 기준 설계서를 받고 싶으신 경우 ‘연납 보험료 추가’를 눌러 연납 보험료를 추가로 입력하세요.')}
          inputValue={props.payment}
          onChange={props.paymentChange}
          placeholder={t('연납 보험료 입력...')}
          btn={{type: 'secondary', text: t('연납 보험료 추가'), disabled: false, icon: props.theme === 'dark' ? addCircleDark : addCircleLight, onClick: props.addPayment}}
          validation={props.paymentValid !== '' ? false : true}
          validMessage={props.paymentValid === 'formatError' ? t('연납 보험료를 확인해주세요.') : props.paymentValid === 'minError' ? t('최소금액을 확인해주세요.') : ''}
          removeInput={props.removePayment}
          focus={props.paymentFocus}
          setFocus={props.setPaymentFocus}
          inputType='tel'
        />
        {
          props.product && props.product.allowMonthlyPaid ? (
            <div className={`col-start gap8 w100 ${props.theme}`}>
              <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('납입방식')}</div>
              <div className={`col-stretch border w100 radius8 ${props.theme}`}>
                <RadioInput
                  theme={props.theme}
                  isActive={!props.useMonthlyPayment}
                  onClick={() => props.setUseMonthlyPayment(false)}
                  text={t('연납')}
                  padding={true}
                />
                <RadioInput
                  theme={props.theme}
                  isActive={props.useMonthlyPayment}
                  onClick={() => props.setUseMonthlyPayment(true)}
                  text={t('월납')}
                  padding={true}
                />
              </div>
            </div>
          ) : null
        }
        <div className={`col-start gap12 w100 ${props.theme}`}>
          <div className='col-start gap6'>
            <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('납입 연수')}</div>
          </div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            {
              props.product.durations.map((d: any, i: number) => {
                return <RadioInput
                  key={String(i)}
                  theme={props.theme}
                  isActive={props.paymentDuration === d}
                  onClick={() => props.setPaymentDuration(d)}
                  text={`${d}${t('년')}`}
                  padding={true}
                />
              })
            }
          </div>
        </div>
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('인출 시작 일자')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.startType === 0}
              onClick={() => props.startTypeChange(0)}
              text={t('나이 기준으로 입력')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.startType === 1}
              onClick={() => props.startTypeChange(1)}
              text={t('플랜년도(기간) 기준으로 입력')}
              padding={true}
            />
          </div>
          {
            props.startTypeValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('인출 시작 일자를 선택해주세요.')}</div> : null
          }
        </div>
        {
          props.startType === 0 ?
          <>
            <TextInput
              theme={props.theme}
              label={t('인출 시작 시점의 연령')}
              // subLabel={t(': 선택항목')}
              inputValue={props.startAge}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.startAgeChange(e)}
              placeholder={t('ex) 60세, 70세, ...')}
              icon={false}
              validation={props.startAgeValid !== '' ? false: true}
              validMessage={t('인출 시작 시점의 연령을 확인해주세요.')}
              inputType='tel'
            />
            <TextInput
              theme={props.theme}
              label={t('인출 종료 시점의 연령')}
              // subLabel={t(': 선택항목')}
              inputValue={props.endAge}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.endAgeChange(e)}
              placeholder={t('ex) 80세, 계속 인출, ...')}
              icon={false}
              validation={props.endAgeValid !== '' ? false: true}
              validMessage={t('인출 종료 시점의 연령을 확인해주세요.')}
              inputType='tel'
            />
          </> :
          props.startType === 1 ?
          <>
            <TextInput
              theme={props.theme}
              label={t('인출 시작 시기(플랜 연도)')}
              // subLabel={t(': 선택항목')}
              inputValue={props.startYear}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.startYearChange(e)}
              placeholder={t('ex) 10년차, 20년차, ...')}
              icon={false}
              validation={props.startYearValid !== '' ? false: true}
              validMessage={t('인출 시작 시기를 확인해주세요.')}
              inputType='tel'
            />
            <TextInput
              theme={props.theme}
              label={t('인출 종료 시기(플랜 연도)')}
              // subLabel={t(': 선택항목')}
              inputValue={props.endYear}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.endYearChange(e)}
              placeholder={t('ex) 30년차, 계속 인출, ...')}
              icon={false}
              validation={props.endYearValid !== '' ? false: true}
              validMessage={t('인출 종료 시기를 확인해주세요.')}
              inputType='tel'
            />
          </> : null
        }
        <TextInput
          theme={props.theme}
          label={t('연간 인출금액(USD)')}
          // subLabel={t(': 선택항목')}
          inputValue={props.withdrawal}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.withdrawalChange(e)}
          placeholder={t('연간 인출금액 입력...')}
          icon={false}
          validation={props.withdrawalValid !== '' ? false: true}
          validMessage={t('연간 인출금액을 확인해주세요.')}
          inputType='tel'
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('제출')}
        onClick={() => props.submitClick()}
      />
    </div>
  );
};

export default PlanRequestPage;