import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import RowItem from 'components/common/row_item';
import ModalYN from 'components/modal/modal_yn';
import { arrowBackDark, arrowBackLight, arrowRightItemDark, arrowRightItemLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { getApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  logOutClick?: () => void;
}

const SettingPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop, push, replace } = useFlow();
  const { t } = useTranslation();

  const [pushAlarm, setPushAlarm] = useState(false);

  const [modal, setModal] = useState('');
  const logOut = () => {
    pop();
    signOut(getAuth(getApp()));
    replace('LoadingPage', {});
  };


  return (
    <AppScreen>
      {
        modal === 'logOut' ?
        <ModalYN
          theme={type}
          text={t('로그아웃 하시겠습니까?')}
          btnY={{name: t('로그아웃'), onClick: () => logOut()}}
          btnN={{name: t('취소'), onClick: () => setModal('')}}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} gap8 t56`}>
          <Appbar
            theme={type}
            type='text'
            title={t('설정')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className={`col-stretch bgBG2 bg2Shadow ${type}`}>
            {/* <RowItem
              theme={type}
              label={t('푸시 알람')}
              isLastItem={false}
              switch={true}
              switchActive={pushAlarm}
              onClick={alarmClick}
            /> */}
            <RowItem
              theme={type}
              label={t('공지사항')}
              icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
              isLastItem={false}
              onClick={() => push("NoticeListPage", {})}
            />
            {/* {
              params.resetCompleteClick !== undefined ?
              <RowItem
                theme={type}
                label={t('비밀번호 변경')}
                icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
                isLastItem={true}
                onClick={() => push("ResetPasswordSettingPage", {resetCompleteClick: resetCompleteClick, isSetting: true})}
              /> : null
            } */}
            {/* <RowItem
              theme={type}
              label={t('언어 설정')}
              text={
                lang === 'kr' ? t('한국어') : ''
              }
              icon={type === 'dark' ? arrowRightItemDark : arrowRightItemLight}
              isLastItem={true}
              onClick={() => console.log('123')}
            /> */}
          </div>
          <div className={`setting-logout bgBG2 bg2Shadow b17 colorRed ${type}`} onClick={() => setModal('logOut')}>
            {t('로그아웃')}
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SettingPage;