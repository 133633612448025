import React from 'react';

interface Props{
  theme: string;
  label: string;
  text?: any;
  isLink?: string; // URL
  icon?: string;
  isLastItem?: boolean;
  subText?: string;
  onClick?: () => void;
  isCall?: boolean;
  noPadding?: boolean;
  switch?: boolean;
  switchActive?: boolean;
}

declare var mtw_open_url: any;

const RowItem:React.FC<Props> = (props) => {
  const linkClick = () => {
    if (props.isLink && props.isLink !== '') {    
      mtw_open_url.postMessage(JSON.stringify({
        url: props.isLink,
      }));
    }
  };

  return (
    <div
      onClick={props.onClick ? props.onClick : undefined}
      className={`row-item row-start ${props.theme} ${props.icon && props.icon !== '' ? 'icon' : ''} ${props.isLastItem ? '' : 'borderBottom05'}`}
      style={props.noPadding ? {paddingLeft: 0, paddingRight: 0} : {}}
    >
      <div className='row-item-label col-start gap4'>
        <div className={`${props.subText && props.subText !== '' ? 'b17 c1' : 'm17 c5'} ${props.theme}`}>{props.label}</div>
        {
          props.subText && props.subText !== '' ? <div className={`r15 c5 ${props.theme}`}>{props.subText}</div> : ''
        }
      </div>
      <div className='row-center gap8'>
        {
          props.switch ?
          <div className={`switch-wrap ${props.switchActive ? 'bgGreen active' : 'bg14'} ${props.theme}`}>
            <div className={`switch-ball ${props.switchActive ? 'bgWhite' : 'bg10'} ${props.theme}`} />
          </div> : null
        }
        {
          props.text ?
          <div
            className={`m17 ${props.theme} ${(props.isLink && props.isLink !== '') || props.isCall ? 'underline colorBlue' : 'c1'}`}
            onClick={() => linkClick()}
            style={{textAlign: 'right'}}
          >
            {props.text}
          </div> : null
        }
        {
          props.icon && props.icon !== '' ?
          <img className='row-item-icon' src={props.icon} alt={'icon'} /> : null
        }
      </div>
    </div>
  );
};

export default RowItem;