import { reviewFillStar } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import React from 'react';

interface Props{
  avatar: string;
  reviewer: string;
  star: number;
  productId: string;
  productDetails: Array<string>;
  qa: Array<{q: string, a: string}>;
}

const FullReviewCard:React.FC<Props> = (props) => {
  const {push} = useFlow();

  const product = products.filter((p: any) => p.code === props.productId)[0];

  return (
    <div className='full-review-card-wrapper gap12'>
      <div className='review-card-reviewer-wrapper'>
        <img alt="review-avatar" src={props.avatar} />
        <div>
          <div className='reviewer-label'>{props.reviewer}</div>
          <div style={{height: '12px', display: 'flex', flexDirection: 'row'}}>
            {
              new Array(props.star).fill(1).map((v, i) => {
                return <img alt="fill-star" src={reviewFillStar} className='star' key={String(i)} />
              })
            }
          </div>
        </div>
      </div>
      <div className='full-review-card-product-wrapper col-start gap8' onClick={() => push("ProductInfoPage", {
        userType: 'user',
        isSubscribed: false,
        productCode: product.code,
      })}>
        <div className='row-center gap6'>
          <img alt="logo" src={product.logo} style={{width: '19px'}} />
          <div className='m13'>{product.company}</div>
        </div>
        <div className='m13'>{product.name}</div>
        <div className='row-start gap6'>
          {
            props.productDetails.map((s: string, i: number) => {
              return (
                <div key={String(i)} className='m13 c5 light radius4' style={{padding: '2px 4px', backgroundColor: '#F2F3F7'}}>
                  {s}
                </div>
              );
            })
          }
        </div>
      </div>
      <div className='col-start gap20'>
        {
          props.qa.map((v: any, i: number) => {
            return (
              <div key={String(i)}>
                <div className='b15' style={{marginBottom: '6px'}}>{`Q: ${v.q}`}</div>
                <div className='r15'>{`A: ${v.a}`}</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default FullReviewCard;
