import React from 'react';

interface Props{
  text: string;
  theme: string;
  isLastItem: boolean;
  children?: any;
}

const BlockItem:React.FC<Props> = (props) => {
  return (
    <div className={`block-item c5 m15paragraph ${props.theme} ${props.isLastItem ? '' : 'borderBottom05'}`}>
      {props.children ?? props.text}
    </div>
  );
};

export default BlockItem;